import React, { Fragment } from 'react';
import moment from 'moment';
import { Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';
import InfoLarge from '../assets/T-warning.svg';
import './index.scss';

const ExistingBmDialog = ({
    existingBm,
    resetPreValidationCheck,
    getCustomBmDetail,
    setTab,
    duplicateCustomBmRequest,
}) => {
    const { name, created_by, created_on, id } = existingBm || {};
    const setView = () => {
        resetPreValidationCheck({});
        getCustomBmDetail(id);
        setTab(4);
    };

    const duplicateRequest = () => {
        resetPreValidationCheck({});
        duplicateCustomBmRequest(id);
    };

    return (
        <Dialog
            open
            disableEscapeKeyDown
            disableBackdropClick
            maxWidth="md"
            classes={{
                root: 'customBmDialogRoot',
            }}
        >
            <section className="infoContent">
                <div className="infoLarge">
                    <img src={InfoLarge} alt="" />
                </div>
                <h2>Created custom benchmark already exists in library.</h2>
                <div className="para">
                    <div>
                        A benchmark with the same organizations as this custom benchmark already exists in the database,
                        named “{name}” created by {created_by} on {moment(created_on).format('DD-MMM-YYYY')}.
                    </div>
                    <div>
                        <b>Please note :</b> You can create duplicate of the existing custom benchmark, to publish it is
                        mandatory for you to make edits in the selected survey list of the existing custom benchmark.
                    </div>
                </div>
            </section>
            <section className="btnContent clearfix activeButtons multiBtn">
                <Fragment>
                    <div data-testid="detailButton" className="detailedBtn aciveDetailBtn" onClick={duplicateRequest}>
                        Duplicate
                    </div>
                    <div
                        data-testid="compareButton"
                        className="compareBtn activeCompareBtn"
                        onClick={() => {
                            setView();
                        }}
                    >
                        View existing benchmark
                    </div>
                </Fragment>
            </section>
        </Dialog>
    );
};

ExistingBmDialog.propTypes = {
    resetPreValidationCheck: PropTypes.func.isRequired,
    existingBm: PropTypes.object.isRequired,
    getCustomBmDetail: PropTypes.func.isRequired,
    setTab: PropTypes.func.isRequired,
    duplicateCustomBmRequest: PropTypes.func.isRequired,
};

export default ExistingBmDialog;
