import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import RealtimeDatabase from './RealtimeDatabase';
import { getRealtimeApproval, getRealtimeReject, hideSecondPopup } from '../actions';

import { selectRealtimeApproveList, selectRealTimeRejectList, selectSecondPopupVisible } from '../selector';

const stateToProps = createStructuredSelector({
    realTimeApproveList: selectRealtimeApproveList(),
    realTimeRejectList: selectRealTimeRejectList(),
    secondPopupVisible: selectSecondPopupVisible(),
});

const dispatchToProps = {
    getRealtimeApproval,
    getRealtimeReject,
    hideSecondPopup,
};

export default connect(stateToProps, dispatchToProps)(RealtimeDatabase);
