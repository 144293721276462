import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Analytics from './Analytics';
import analytics from './reducer';
import analyticsSagas from './sagas';

import {
    selectAnalyticsApiCount,
    selectDataAtGlance,
    selectDataAtGlanceFetched,
    selectDefaultFilters,
    selectDefaultYear,
    selectFilterParam,
    selectFilters,
    selectFiltersApplied,
    selectFiltersFetched,
    selectFiltersMapping,
    selectFiltersMappingFetched,
    selectRange,
    selectDefaultRealTimeYear,
    selectRealtimeTotalData,
    selectRealtimeSurveyList,
    selectSecondPopupVisible,
    selectRealtimeTotalDataFetched,
    selectRealtimeSurveyListFetched,
    selectRealtimeFiltersApplied,
    selectRealtimeFiltersData,
    selectRealtimeDafaultFiltersData,
    selectRealtimeFiltersParams,
    selectRealtimeFilterDataFetched,
} from './selector';
import {
    getDataAtGlance,
    getFilterData,
    updateFilters,
    getFiltersMapping,
    getDefaultYear,
    setOrgSizeData,
    getRealtimeYear,
    getRealtimeAnalytics,
    getRealtimeSurveyList,
    getRealtimeApproval,
    // new
    getRealtimeFilterData,
    updateRealtimeFilters,
    getRealtimeFilters,
} from './actions';
import { getDefaultSettings } from '../Login/selector';
import { selectApiCount } from '../Diagnose/selector';

const stateToProps = createStructuredSelector({
    analyticsApiCount: selectAnalyticsApiCount(),
    dataAtGlance: selectDataAtGlance(),
    filters: selectFilters(),
    filtersApplied: selectFiltersApplied(),
    filtersFetched: selectFiltersFetched(),
    defaultSettings: getDefaultSettings(),
    filterParams: selectFilterParam(),
    dataAtGlanceFetched: selectDataAtGlanceFetched(),
    filtersMapping: selectFiltersMapping(),
    filtersMappingFetched: selectFiltersMappingFetched(),
    defaultFilters: selectDefaultFilters(),
    defaultYear: selectDefaultYear(),
    diagnoseApiCount: selectApiCount(),
    range: selectRange(),
    defaultRealTimeYear: selectDefaultRealTimeYear(),
    realtimeTotalData: selectRealtimeTotalData(),
    realtimeSurveyList: selectRealtimeSurveyList(),
    secondPopupVisible: selectSecondPopupVisible(),
    // filtersApplied: selectFiltersApplied(),
    realtimeTotalDataFetched: selectRealtimeTotalDataFetched(),
    realtimeSurveyListFetched: selectRealtimeSurveyListFetched(),
    realtimeFiltersApplied: selectRealtimeFiltersApplied(),
    realtimeFiltersData: selectRealtimeFiltersData(),
    realtimeDefaultFiltersData: selectRealtimeDafaultFiltersData(),
    realtimeFiltersParams: selectRealtimeFiltersParams(),
    realtimeFilterDataFetched: selectRealtimeFilterDataFetched(),
});

const dispatchToProps = {
    getDataAtGlance,
    getFilterData,
    updateFilters,
    getFiltersMapping,
    getDefaultYear,
    setOrgSizeData,
    getRealtimeYear,
    getRealtimeAnalytics,
    getRealtimeSurveyList,
    getRealtimeApproval,
    getRealtimeFilterData,
    updateRealtimeFilters,
    getRealtimeFilters,
};

export { analyticsSagas, analytics };

export default connect(stateToProps, dispatchToProps)(Analytics);
