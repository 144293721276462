import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem } from '@material-ui/core';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import c from 'classnames';

import { getStaticText } from '../../../utils/constants';
import { getRandomValues, quartileTitle, sortChildren } from '../../../utils/functions';
import Layout from '../../../components/Layout';
import Loader from '../../../components/Loader';
import './index.scss';

function DemographicScores({
    reportApiParams,
    getGapData,
    gapData,
    reportApiCount,
    gapDataFetched,
    filters,
    getDemographics,
    apiLoadingCount,
    defaultSettings,
    scoreColor,
    getInclusionData,
}) {
    const { lang } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const {
        SCORE_CLASSIFICATION_TEXT,
        DEMO_SCORE_SUBHEADING,
        SIZE_MAIN_TEXT,
        INC_SCORE_DEMO,
        DEMOGRAPHICS,
        INCLUSION_DEFAULT_BENCHMARK_STR,
        SELECT_DOWNLOAD,
        OVERALL,
        OUT_SCORE,
        PRC_SCORE,
        WORKPLACE_OUT_SCORE,
    } = SITE_TEXT || {};
    const { demographics = [] } = filters[0] || [];
    const { qbyq = {} } = gapData;
    const { ohid = '' } = reportApiParams;
    const [scoreData, setScoreData] = useState({});
    const [dropDownValue, setDropDownValue] = useState('');
    const { finalArr = [], headerArr = [], heading = [] } = scoreData;
    const { benchmarkStyles = [] } = scoreColor;
    const styleToUse = benchmarkStyles;

    const excludeOption = ['demo_StraightLiners', 'demo_StraightLinersIA'];

    useEffect(() => {
        if (!demographics.length) {
            getInclusionData({
                ohid,
                filters: [],
                inclusion_threshold: 10,
                with_benchmark: true,
                benchmarks: INCLUSION_DEFAULT_BENCHMARK_STR,
            });
            getDemographics({ ohid, lang: parseInt(lang, 10) }, 0);
        }
        // eslint-disable-next-line
    }, []);

    const callGapData = gap_on => {
        getGapData({
            ohid,
            gap_on,
            group_on: '',
            filters: [],
            lang: parseInt(lang, 10),
        });
    };

    const getInclusionStyle = (inclusionQuartile, quartile, benchmarkStyle = []) => {
        if (!inclusionQuartile && !quartile) return benchmarkStyle[5];
        const obj = benchmarkStyle.filter(
            ({ quartile: quartileNo = 0 }) => Number(quartileNo) === inclusionQuartile
        )[0];
        return obj;
    };

    useEffect(() => {
        if (!gapDataFetched && demographics.length) {
            const { code: gap_on = '' } = demographics[0] || {};
            setDropDownValue(gap_on);
            callGapData(gap_on);
        }
        // eslint-disable-next-line
    }, [demographics, gapDataFetched]);

    useEffect(() => {
        if (gapDataFetched) {
            const obj = {
                heading: [],
                finalArr: [],
                headerArr: [],
            };
            Object.keys(qbyq).forEach(key => {
                const { display_name = '', size = '', level_scores = {}, records = {} } = qbyq[key];
                const { overall = {} } = level_scores;
                const { quartile: ovQuart = -1, inclusion_quartile: ovIaQuart = -1 } = overall;
                obj.heading.push(display_name);
                const newObj = [];
                const headerArray = [SIZE_MAIN_TEXT];
                newObj.push({ score: size });
                newObj.push({ ...overall, quartile: ovQuart, inclusion_quartile: ovIaQuart });

                const filterWithoutDash = sortChildren(
                    Object.keys(records)
                        .filter(k => records[k].level !== '-')
                        .reduce((o, k) => {
                            o[k] = records[k];
                            return o;
                        }, {})
                );

                const filteredWithDash = sortChildren(
                    Object.keys(records)
                        .filter(k => records[k].level === '-')
                        .reduce((o, k) => {
                            o[k] = records[k];
                            return o;
                        }, {})
                );

                Object.keys(filterWithoutDash).forEach(val => {
                    const {
                        score = '',
                        display_name: record_display_name = '',
                        quartile = -1,
                        inclusion_quartile = -1,
                    } = filterWithoutDash[val];
                    newObj.push({ score, inclusion_quartile, quartile });
                    headerArray.push(record_display_name);
                });

                Object.keys(filterWithoutDash).forEach(val => {
                    const { children = {} } = filterWithoutDash[val];
                    const sortChildrenObj = sortChildren(children);
                    Object.keys(sortChildrenObj).forEach(childVal => {
                        const {
                            score: childScore = '',
                            display_name: sub_display_name = '',
                            quartile = -1,
                            inclusion_quartile = -1,
                        } = sortChildrenObj[childVal];
                        newObj.push({ score: childScore, inclusion_quartile, quartile });
                        headerArray.push(sub_display_name);
                    });
                });

                Object.keys(filteredWithDash).forEach(val => {
                    const {
                        score = '',
                        display_name: record_display_name = '',
                        quartile = -1,
                        inclusion_quartile = -1,
                    } = filteredWithDash[val];
                    newObj.push({ score, inclusion_quartile, quartile });
                    headerArray.push(record_display_name);
                });
                obj.headerArr = headerArray;
                obj.finalArr.push(newObj);
            });
            setScoreData(obj);
        }
        // eslint-disable-next-line
    }, [qbyq]);

    const getLabel = selectedValue => {
        const { label = '' } = demographics.filter(({ code }) => code === selectedValue)[0] || [];
        return label;
    };

    return (
        <Layout showNavigation={false} showHeader>
            <div className="scores clearfix">
                <h2>
                    {INC_SCORE_DEMO}
                    <div className="subInfo">{DEMO_SCORE_SUBHEADING}</div>
                </h2>

                <div className="dropdown">
                    <div>
                        <div>{DEMOGRAPHICS}</div>
                        <div>
                            <Select
                                data-testid="demographicOptions"
                                IconComponent={KeyboardArrowDownSharpIcon}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={dropDownValue}
                                displayEmpty
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                }}
                                // onOpen={() => setSelectedDownload('')}
                                onChange={({ target: { value: code } }) => {
                                    setDropDownValue(code);
                                    callGapData(code);
                                }}
                            >
                                <MenuItem classes={{ root: 'demographicOptions' }} value="">
                                    <em>{SELECT_DOWNLOAD}</em>
                                </MenuItem>
                                {demographics
                                    .filter(({ code }) => !excludeOption.includes(code))
                                    .map(({ code = '', label = '' }) => {
                                        return (
                                            <MenuItem classes={{ root: 'demographicOptions' }} value={code}>
                                                {label}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </div>
                    </div>
                    <div>
                        <ul className="classification clearfix">
                            <li>{SCORE_CLASSIFICATION_TEXT} </li>
                            {styleToUse
                                .filter(
                                    ({ quartile, style_for }) =>
                                        quartile !== '0' && quartile !== '999' && style_for !== 'blank_data'
                                )
                                .map(({ title, bgcolor: background = '' }) => {
                                    return (
                                        <li key={title}>
                                            <span className="circle" style={{ background }} />
                                            {quartileTitle(true, title)}
                                        </li>
                                    );
                                })}
                        </ul>
                    </div>
                </div>
                {finalArr.length ? (
                    <div className="menu">
                        <div className="leftMenu">
                            <div className="upperHeader">
                                <div>
                                    <div />
                                </div>
                                <div>
                                    <div>{getLabel(dropDownValue)}</div>
                                </div>
                            </div>
                            <div className="content">
                                {heading.map(val => (
                                    <div>
                                        <div>
                                            <div>{val}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="rightMenu">
                            <div className="uHeader">
                                <div className="divison">
                                    <div>
                                        <div />
                                    </div>
                                    <div className="noTopBorder">
                                        {headerArr.slice(0, 1).map((val, i) => (
                                            <div className={c('left', { shadow: i === 0 })} key={val}>
                                                <div> {val} </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className="divison">
                                    <div>
                                        <div />
                                    </div>
                                    <div className="noTopBorder">
                                        <div className="left">
                                            <div> {OVERALL} </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="divison">
                                    <div>
                                        <div>{OUT_SCORE}</div>
                                    </div>
                                    <div>
                                        {headerArr.slice(1, 7).map(val => (
                                            <div className="left" key={val}>
                                                <div> {val} </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className="divison">
                                    <div>
                                        <div>{PRC_SCORE}</div>
                                    </div>
                                    <div>
                                        {headerArr.slice(7, 23).map(val => (
                                            <div className="left" key={val}>
                                                <div> {val} </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className="divison">
                                    <div>
                                        <div>{WORKPLACE_OUT_SCORE}</div>
                                    </div>
                                    <div>
                                        {headerArr.slice(23).map(val => (
                                            <div className="left" key={val}>
                                                <div> {val} </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="content">
                                {finalArr.map(val => (
                                    <div key={`contentRow${getRandomValues()}`}>
                                        {val.map((v, i) => {
                                            const { score = '', inclusion_quartile = -1, quartile = -1 } = v;
                                            const { bgcolor = '', text_color = '' } = getInclusionStyle(
                                                inclusion_quartile,
                                                quartile,
                                                benchmarkStyles
                                            ) || { bgcolor: '#ffffff', text_color: '#000000' };
                                            return (
                                                <div style={{ background: i === 0 ? '' : bgcolor }}>
                                                    <div
                                                        style={{ color: i === 0 ? '' : text_color }}
                                                        className="rotate90"
                                                    >
                                                        {score}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
            {reportApiCount || apiLoadingCount ? (
                <Loader position="absolute" showBackground background="transparent" />
            ) : null}
            ;
        </Layout>
    );
}

DemographicScores.propTypes = {
    getGapData: PropTypes.func.isRequired,
    gapData: PropTypes.object.isRequired,
    reportApiCount: PropTypes.number.isRequired,
    reportApiParams: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    gapDataFetched: PropTypes.bool.isRequired,
    apiLoadingCount: PropTypes.bool.isRequired,
    getDemographics: PropTypes.func.isRequired,
    filters: PropTypes.array.isRequired,
    scoreColor: PropTypes.object.isRequired,
    getInclusionData: PropTypes.func.isRequired,
};

export default DemographicScores;
