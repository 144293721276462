import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Tooltip, Cell } from 'recharts';
import { Tooltip as MuiTooltip, Button } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { getRandomValues } from '../../../utils/functions';

function getRandomHexColor() {
    // Generate a random integer between 0 and 255
    const getRandomInt = () => Math.floor((getRandomValues() / 10000000) * 256);
    // Convert the integer to a hexadecimal string and pad with zeroes if necessary
    const toHex = num => num.toString(16).padStart(2, '0');
    // Combine the three color components to form a hex color code
    const red = getRandomInt();
    const green = getRandomInt();
    const blue = getRandomInt();

    return `#${toHex(red)}${toHex(green)}${toHex(blue)}`;
}

const COLORS = Array(100)
    .fill(0)
    .map(() => getRandomHexColor());

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="label">{`${payload[0].name} - ${payload[0].value.toFixed(2)}%`}</p>
            </div>
        );
    }

    return null;
};

const PaginatedLegend = ({ data, itemsPerPage, currentPage, setCurrentPage }) => {
    const totalPages = Math.ceil(data.length / itemsPerPage);

    const handlePrevPage = () => {
        setCurrentPage(prev => Math.max(prev - 1, 0));
    };

    const handleNextPage = () => {
        setCurrentPage(prev => Math.min(prev + 1, totalPages - 1));
    };

    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = data.slice(startIndex, endIndex);

    return (
        <div className="custom-legend">
            <div className="pagination-controls">
                <Button onClick={handlePrevPage} disabled={currentPage === 0} className="top">
                    <ChevronLeft />
                </Button>
                <ul>
                    {currentItems.map((entry, index) => (
                        <li
                            key={`item-${index}`}
                            style={{ color: COLORS[index % COLORS.length] }}
                            className="ellipsisPro"
                        >
                            <span className="circle" style={{ background: COLORS[index % COLORS.length] }} />
                            {entry.name} - {entry.value}%
                        </li>
                    ))}
                </ul>
                <Button onClick={handleNextPage} disabled={currentPage === totalPages - 1} className="bottom">
                    <ChevronRight />
                </Button>
                <div className="pageCount">
                    <strong>{`${currentPage + 1} / ${Math.ceil(data.length / itemsPerPage)}`}</strong>
                </div>
            </div>
        </div>
    );
};

const PieChartComponent = ({ data, mainResName }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10; // Customize the number of legend items per page
    return (
        <div>
            <MuiTooltip title={mainResName} arrow placement="top">
                <h2 className="ellipsisPro">{mainResName}</h2>
            </MuiTooltip>
            <div className="chartContent">
                <PieChart width={400} height={400}>
                    <Pie
                        data={data}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={100}
                        fill="#8884d8"
                        label={({ percent }) => `${(percent * 100).toFixed(1)}%`}
                        labelLine={false}
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Tooltip content={<CustomTooltip />} />
                </PieChart>
                <PaginatedLegend
                    data={data}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
            </div>
        </div>
    );
};

const PieCharts = ({ respondentData, mainKeys }) => (
    <section className="pieChartContent">
        {mainKeys.map(res => {
            const { children = [], display_name: mainResName = '' } = respondentData[res] || {};
            const data = children.map(({ display_name, _breakdown, n_size }) => ({
                name: `${display_name} (${n_size})`,
                value: _breakdown,
            }));
            return (
                <div key={res}>
                    <PieChartComponent data={data} mainResName={mainResName} />
                </div>
            );
        })}
    </section>
);

CustomTooltip.propTypes = {
    active: PropTypes.bool.isRequired,
    payload: PropTypes.array.isRequired,
};

PaginatedLegend.propTypes = {
    data: PropTypes.array.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    setCurrentPage: PropTypes.func.isRequired,
};

PieChartComponent.propTypes = {
    data: PropTypes.array.isRequired,
    mainResName: PropTypes.string.isRequired,
};

PieCharts.propTypes = {
    respondentData: PropTypes.object.isRequired,
    mainKeys: PropTypes.array.isRequired,
};

export default PieCharts;
