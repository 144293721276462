import React, { Fragment, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import { ExpandLess, ExpandMore, ArrowForward } from '@material-ui/icons';
import { cloneDeep } from 'lodash';
import { Tooltip } from '@material-ui/core';
import ActiveFilter from '../../Diagnose/assets/ActiveFilters1.svg';
import FilterSvg from '../../Diagnose/assets/Filter.svg';
import { getStaticText } from '../../../utils/constants';
import './index.scss';
import BlankPage from '../BlankPage/BlankPage';
import { getStaticTextAnalytics } from '../constants';
import { getTabName } from '../assets/functions';
import DisclaimerWarning from '../assets/DisclaimerWarning1.svg';
import WarningModal from '../Intro/WarningModal';
import { matchFilterRegex } from '../../../utils/functions';

function Overview({
    toggleFilterView,
    dataAtGlance,
    getAnalyticsPerCategory,
    defaultSettings,
    filterParams,
    filters,
    setAnalyticsPerCategory,
    analyticsPerCategory,
    tabs,
    setTabs,
    renderfilterApplied,
    filtersApplied,
    setWarningModal,
    warningModal,
}) {
    const { lang } = defaultSettings;
    const staticTextAnalytics = getStaticTextAnalytics(lang);
    const { OVERVIEW_HEADER } = staticTextAnalytics;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const {
        FILTERS,
        OVERVIEW_PAGE_TEXT,
        NUMBER_CAP_SURVEY_TEXT,
        NUMBER_TEXT,
        NOTE,
        GSOD_OVERVIEW_NOTE,
        OVERVIEW_NUMBER_NOTE,
        SCORECARD_NOTE,
        DISCLAIMER,
        LEARN_MORE,
        LEARN_MORE_LINK,
        VIEW_ONLINE,
    } = SITE_TEXT;
    const { [tabs]: chartData = [] } = analyticsPerCategory || [];
    const { total_survey: nSize = '' } = dataAtGlance;

    const keys = matchFilterRegex(filterParams);
    const keyCount = keys ? keys.length : 0;

    useEffect(() => {
        if (!(tabs in analyticsPerCategory)) {
            getAnalyticsPerCategory({ filters: filterParams, filter_id: tabs });
        }
        // eslint-disable-next-line
    }, [tabs, filterParams]);

    const sortData = (sortObject, sort_elem, sort_by) => {
        const sortObj = cloneDeep(sortObject);
        sortObj.sort(
            (
                { total_survey: a = '', survey_completion: x = '' },
                { total_survey: b = '', survey_completion: y = '' }
            ) => {
                if (sort_by === 'asc') {
                    return sort_elem === 'survey' ? a - b : x - y;
                }
                return sort_elem === 'survey' ? b - a : y - x;
            }
        );
        setAnalyticsPerCategory({ ...analyticsPerCategory, [tabs]: sortObj });
    };

    const handleWarningModal = () => {
        setWarningModal(true);
    };

    const chartDivision = 8;

    const maxValue = useMemo(() => {
        const chartDiv = 100 / chartDivision;
        if (chartData.length) {
            const allData = chartData.map(({ total_survey }) => total_survey);
            const maxDataPoint = Math.max(...allData);
            const maxDataPointRounded = Math.ceil(maxDataPoint / chartDiv) * chartDiv;
            return maxDataPointRounded;
        }
        return 0;
    }, [chartData]);

    const { filter_name: currentTabName = '' } = filters.filter(({ filter_id }) => filter_id === tabs)[0] || {};

    return (
        <>
            <div className="overviewWrapper">
                <div className="rightScorecardHeader">
                    <div className="scorecardHeading">
                        <div className="mainHeading">{OVERVIEW_PAGE_TEXT['1']}</div>
                        <div className="nRes">{`${NUMBER_CAP_SURVEY_TEXT} (${NUMBER_TEXT}) : ${nSize}`}</div>
                    </div>
                    <ul className="navScoreList">
                        <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                            <img src={!filtersApplied ? FilterSvg : ActiveFilter} alt="" />{' '}
                            <span className="filter">{FILTERS}</span>
                        </li>
                    </ul>
                </div>
                <div className="benchmarkInfo">{OVERVIEW_PAGE_TEXT['2']}</div>
                <div className="disclaimerContent">
                    <img src={DisclaimerWarning} alt="" />
                    <div className="note scoreCardNote">
                        <span>{DISCLAIMER} : </span>
                        <div>{SCORECARD_NOTE}</div>
                        <div style={{ cursor: 'pointer' }} className="warningContent" onClick={handleWarningModal}>
                            know more
                        </div>
                    </div>
                </div>
                {renderfilterApplied}
                {chartData.length ? (
                    <Fragment>
                        <ul className="headContent">
                            {Object.keys(dataAtGlance).map((dataPoints, index) => {
                                const score = dataAtGlance[dataPoints].toLocaleString('en-US');
                                const { label = '' } = OVERVIEW_HEADER[dataPoints];
                                return (
                                    <li data-testid={`headContent${index}`} key={index}>
                                        <div className="description">
                                            <span>{score}</span>
                                            <div className="ellipsisPro">{label}</div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                        <section className="sectionWrapper">
                            <div className="tabContainer">
                                <ul className="leftNav">
                                    {filters
                                        .filter(({ filter_id = '' }) => typeof filter_id === 'number')
                                        .map(({ filter_name, filter_id }) => {
                                            const tabName = getTabName(filter_name);
                                            return (
                                                <li
                                                    key={tabName}
                                                    data-testid={tabName}
                                                    className={c({ activeLink: tabs === filter_id })}
                                                    onClick={() => setTabs(filter_id)}
                                                >
                                                    {tabName}
                                                </li>
                                            );
                                        })}
                                </ul>
                            </div>
                        </section>
                        <section className="sectionWrapper">
                            <div className="cardsContainer">
                                <div className="middleSection">
                                    <div className="graphCard">
                                        {/* <div className="cardHeading">{OVERVIEW_PAGE_TEXT['2']}</div> */}
                                        <div className="cardBody">
                                            <div className="header">
                                                <div>
                                                    <div>{currentTabName}</div>
                                                    <div>{OVERVIEW_PAGE_TEXT['3']}</div>
                                                </div>
                                                <div>
                                                    <div>
                                                        {OVERVIEW_PAGE_TEXT['4']}{' '}
                                                        <span
                                                            className="expandLess"
                                                            onClick={() => sortData(chartData, 'survey', 'asc')}
                                                        >
                                                            <ExpandLess />
                                                        </span>
                                                        <span
                                                            className="expandMore"
                                                            onClick={() => sortData(chartData, 'survey', 'desc')}
                                                        >
                                                            <ExpandMore />
                                                        </span>
                                                    </div>
                                                    <div>
                                                        {OVERVIEW_PAGE_TEXT['5']}
                                                        <span
                                                            className="expandLess"
                                                            onClick={() => sortData(chartData, 'respondent', 'asc')}
                                                        >
                                                            <ExpandLess />
                                                        </span>
                                                        <span
                                                            className="expandMore"
                                                            onClick={() => sortData(chartData, 'respondent', 'desc')}
                                                        >
                                                            <ExpandMore />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="barChartContainer">
                                                    {chartData.map(({ name, total_survey }, i) => {
                                                        const width = (total_survey / maxValue) * 100;
                                                        return (
                                                            <div className="tableRow" key={name}>
                                                                <div className="ellipsisPro">{name}</div>
                                                                <div className={c({ adjustPadding: !i })}>
                                                                    <div className="chart">
                                                                        {Array(8)
                                                                            .fill(0)
                                                                            .map((e, i) => (
                                                                                <div key={e + i} className="grid" />
                                                                            ))}
                                                                        {total_survey < 10 ? (
                                                                            <Tooltip
                                                                                title={OVERVIEW_NUMBER_NOTE}
                                                                                placement="right"
                                                                                arrow
                                                                            >
                                                                                <div
                                                                                    className={c('bars', {
                                                                                        negative: total_survey < 10,
                                                                                        adjustPadding: !i,
                                                                                    })}
                                                                                    style={{ width: `${width}%` }}
                                                                                />
                                                                            </Tooltip>
                                                                        ) : (
                                                                            <div
                                                                                className={c('bars', {
                                                                                    adjustPadding: !i,
                                                                                })}
                                                                                style={{ width: `${width}%` }}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                                <div className="tableContainer">
                                                    {chartData.map(
                                                        ({
                                                            survey_completion,
                                                            survey_completion_perc,
                                                            total_survey,
                                                            total_survey_perc,
                                                        }) => {
                                                            return (
                                                                <div className="tableRow">
                                                                    <div className="percentageColumn">
                                                                        <span className="surveyString">
                                                                            {total_survey.toLocaleString('en-US')}{' '}
                                                                        </span>
                                                                        <span> {total_survey_perc}% </span>
                                                                    </div>

                                                                    <div className="percentageColumn">
                                                                        <span className="surveyString">
                                                                            {survey_completion.toLocaleString('en-US')}
                                                                        </span>
                                                                        <span>{survey_completion_perc}%</span>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                            <div className="chartFooter">
                                                <div>
                                                    <div />
                                                    <div>
                                                        <div>
                                                            <div className="chart">
                                                                {Array(8)
                                                                    .fill(0)
                                                                    .map((_, i) => {
                                                                        const unit = maxValue / chartDivision;
                                                                        return (
                                                                            <div
                                                                                key={i}
                                                                                style={{ left: `${unit * i}%` }}
                                                                            >
                                                                                {Math.round(unit * i)}
                                                                            </div>
                                                                        );
                                                                    })}
                                                                <div className="lastRangeItem">
                                                                    {Math.round(maxValue)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <div className="note">
                            <b>{NOTE}:</b>
                            {GSOD_OVERVIEW_NOTE}
                        </div>
                    </Fragment>
                ) : (
                    <section className="sectionWrapper">
                        <BlankPage count={keyCount} />
                    </section>
                )}
            </div>

            <div className="bottomCard bottomDisclaimer">
                <span>
                    {LEARN_MORE}
                    <a className="arrowIcon" href={LEARN_MORE_LINK}>
                        {VIEW_ONLINE}
                        <ArrowForward />
                    </a>
                </span>
            </div>

            {warningModal ? <WarningModal setWarningModal={setWarningModal} /> : null}
        </>
    );
}

Overview.propTypes = {
    toggleFilterView: PropTypes.bool.isRequired,
    dataAtGlance: PropTypes.object.isRequired,
    getAnalyticsPerCategory: PropTypes.func.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    filterParams: PropTypes.string.isRequired,
    filters: PropTypes.array.isRequired,
    setAnalyticsPerCategory: PropTypes.func.isRequired,
    analyticsPerCategory: PropTypes.object.isRequired,
    tabs: PropTypes.number.isRequired,
    setTabs: PropTypes.func.isRequired,
    renderfilterApplied: PropTypes.string.isRequired,
    filtersApplied: PropTypes.bool.isRequired,
    setWarningModal: PropTypes.func.isRequired,
    warningModal: PropTypes.bool.isRequired,
};

export default Overview;
