import React, { useEffect, useRef, useState } from 'react';
import c from 'classnames';
import PropTypes from 'prop-types';
import { cloneDeep, isEqual } from 'lodash';
import { Helmet } from 'react-helmet';

import './index.scss';
import { Tooltip } from '@material-ui/core';
import FilterSvg from '../../Diagnose/assets/Filter.svg';
import Loader from '../../../assets/Loader_Blue.svg';
import DownloadIcon from '../../Diagnose/assets/Download.svg';
import { getStaticText, IA_QBYQ_CRH_OHI14649, CRH_OHI14649 } from '../../../utils/constants';
import {
    IMAGE_PRACTICES,
    PPT_PARAMS,
    slidesArrayObject,
    getInnerChartArr,
    getMetaScore,
    CRH_CLIENT,
    PPT_PRAMS_CRH,
} from '../constants';
import {
    getColor,
    getGapColor,
    getInclusionStyle,
    sortChildren,
    excludeFromGapText,
    getBenchmarkColor,
    getPptParamsForLegend,
    getExcludeGapString,
    gapToDisplay,
    getFiltersAppliedText,
    getFiltersText,
    getIncBenchmarkStyles,
    getPptStyles,
} from '../../../utils/functions';
import ActiveFilter from '../../Diagnose/assets/ActiveFilter.svg';
import AuthStore from '../../../common/AuthStore';
import ErrorToast from '../../../components/InformationToast/ErrorToast';

function Questions({
    inclusionqbyq,
    toggleFilterView,
    activeFilter,
    getPptData,
    allGapData,
    filters,
    demographics,
    defaultSettings,
    scoreColor,
    iaApiParams,
    reportApiParams,
    benchmarkName,
    benchmarkVersions,
    isPulse,
    showAvgGap,
    allGapDataFetched,
}) {
    const staticText = getStaticText();
    const { SITE_TEXT } = staticText;
    const {
        QUESTIONS,
        // QUESTION_DISCLAIMER,
        DOWNLOAD,
        DISCLAIMER,
        SCORE_CLASSIFICATION_TEXT,
        GAP_CLASSIFICATION_TEXT,
        FILTERS,
        OUTCOME_QUESTIONS,
        WORKPLACE_QUESTIONS,
        PEER_TEXT,
        PEERS,
        INCL_HEAD_META,
        // AGGREMENT_LEVEL,
        WORKPLACE_OUT,
        NUMBER_TEXT,
        TOTAL_RES_TEXT,
        FREQUENCY_SCALE,
        HEADER_LEGENDS,
        // SUB_HEADER_LEGENDS,
        FILTER_HOVER_TXT,
        VIEW_BY,
        OUTCOME_SELECT,
        PRACTICES_SELECT,
        FILTERS_APPLIED,
        BENCHMARKS_APPLIED,
        NA_BG,
        NONE,
        INCLUSION_QUESTIONS_BOARD,
        IA_2_QUESTIONS_DISCLAIMER,
        SCORECARD_DISCLAIMER,
        AGGREMENT_INCLUSION_LEVEL,
        HEADER_LEGENDS_1,
        SUB_HEADER_LEGENDS_1,
    } = SITE_TEXT;
    const { qbyq = {}, message = '' } = inclusionqbyq;
    const {
        report_name_or_client_name: rName = '',
        exclude_inclusion_gap: defaultExGap = '',
        ohid = '',
        quartileColors = [],
    } = defaultSettings;
    const { exclude_inclusion_gap: exGap = '' } = reportApiParams;
    const { with_benchmark: withBenchmark = false, benchmarks = '' } = iaApiParams;
    const paramForLegend = getPptParamsForLegend(benchmarks);
    const { level_scores: levels_score = {}, records = {}, size = 0, inclusion_survey_version: surveyType = '' } = qbyq;
    const [qbyqData, setQbyqData] = useState({});
    const [practiceQuestionView, setPracticeQuestionView] = useState(false);
    const [practiceQuestionData, setPracticeQuestionData] = useState([]);
    const [hideAll, setHideAll] = useState('');
    const [viewType, setViewType] = useState(0);
    const [allGap, setAllGap] = useState([]);
    const refs = useRef([]);
    const { gap = {} } = allGapData;
    const checkGapLength = Object.keys(gap).length;
    const {
        score: scoreStyles = [],
        gap: gapStyles = [],
        styles = [],
        // benchmarkStyles = [],
        pptBenchmarkStyles = [],
    } = scoreColor;
    const benchmarkStyles = getIncBenchmarkStyles(quartileColors);
    const styleToUse = !withBenchmark ? scoreStyles : benchmarkStyles;
    const pptStyle = getPptStyles(withBenchmark, styles, pptBenchmarkStyles, quartileColors);
    const isCRHClient = ohid === CRH_CLIENT;
    const isSurvey2 = surveyType === '2.0';

    const executeSelect = displayName => {
        if (displayName === hideAll) {
            setHideAll('');
        } else {
            setHideAll(displayName);
        }
    };

    const analyseData = (obj, key, recordData, children) => {
        obj[key] = recordData[key];
        Object.keys(children).forEach(val => {
            const { level: subLevel = '' } = children[val];
            children[val].key = val;
            if (obj[key].hirearchy) {
                if (obj[key].hirearchy[subLevel] && obj[key].hirearchy[subLevel].indexOf(children[val]) === -1) {
                    obj[key].hirearchy[subLevel].push(children[val]);
                } else {
                    obj[key].hirearchy[subLevel] = [];
                    obj[key].hirearchy[subLevel].push(children[val]);
                }
            } else {
                obj[key].hirearchy = {};
                obj[key].hirearchy[subLevel] = [];
                obj[key].hirearchy[subLevel].push(children[val]);
            }
        });
        return obj;
    };

    const filteredDataView = (type, practiceView) => {
        if (type === viewType) {
            setViewType(0);
            setPracticeQuestionView(false);
        } else {
            setViewType(type);
            setPracticeQuestionView(practiceView);
        }
        setHideAll('');
    };

    const setQbyqPracticeData = () => {
        const obj = {
            0: {
                hirearchy: { Peer: [] },
                display_name: 'Peers/ Teammates',
                score: '',
                meta_scores: [],
                gapV: '',
                keyName: '',
            },
            1: {
                hirearchy: { Leader: [] },
                display_name: 'Leader',
                score: '',
                meta_scores: [],
                gapV: '',
                keyName: '',
            },
            2: {
                hirearchy: { Systems: [] },
                display_name: 'Systems',
                score: '',
                meta_scores: [],
                gapV: '',
                keyName: '',
            },
        };
        Object.keys(qbyqData)
            .filter(item => item !== 'workplace')
            .forEach(item => {
                if (qbyqData[item].hirearchy.Peer) {
                    const arr = qbyqData[item].hirearchy.Peer;
                    obj[0].hirearchy.Peer = [...obj[0].hirearchy.Peer, ...arr];
                }
                if (qbyqData[item].hirearchy.Leader) {
                    const arr = qbyqData[item].hirearchy.Leader;
                    obj[1].hirearchy.Leader = [...obj[1].hirearchy.Leader, ...arr];
                }
                if (qbyqData[item].hirearchy.Systems) {
                    const arr = qbyqData[item].hirearchy.Systems;
                    obj[2].hirearchy.Systems = [...obj[2].hirearchy.Systems, ...arr];
                }
            });
        setPracticeQuestionData(obj);
    };

    useEffect(() => {
        if (qbyqData) {
            setQbyqPracticeData();
        }
        // eslint-disable-next-line
    }, [qbyqData]);

    useEffect(() => {
        if (allGapDataFetched) {
            const allGapArr = [];
            Object.keys(records).forEach(k => {
                const { display_name, sort_order, level, children = {} } = records[k];
                gap[k] = { display_name, sort_order, level, ...gap[k] };
                const gapchildrenObj = children;
                Object.keys(children).forEach(childrenK => {
                    const { display_name: gapDisplayName, sort_order: gapSortOrder } = gapchildrenObj[childrenK];
                    const { children: allGapChildren = {} } = gap[k];
                    allGapChildren[childrenK] = {
                        display_name: gapDisplayName,
                        sort_order: gapSortOrder,
                        ...allGapChildren[childrenK],
                    };
                });
            });
            const filteredGaps = sortChildren(
                Object.keys(gap)
                    .filter(k => gap[k].level !== '-')
                    .reduce((o, k) => {
                        o[k] = gap[k];
                        return o;
                    }, {})
            );
            Object.keys(filteredGaps).forEach(val => {
                const {
                    gap: pGap = '',
                    key: pkey,
                    avg_gap: pAvgGap = '',
                    demographic: pDemographics = '',
                } = filteredGaps[val];
                allGapArr.push({ gap: showAvgGap ? pAvgGap : pGap, key: pkey, demographic: pDemographics });
            });
            Object.keys(filteredGaps).forEach(val => {
                const { children = {} } = filteredGaps[val];
                const sortGapChildrenObj = sortChildren(children);
                Object.keys(sortGapChildrenObj).forEach(childrenVal => {
                    const {
                        gap: cGap = '',
                        key: ckey,
                        avg_gap: cAvgGap = '',
                        demographic: cDemographics,
                    } = sortGapChildrenObj[childrenVal];
                    allGapArr.push({ gap: showAvgGap ? cAvgGap : cGap, key: ckey, demographic: cDemographics });
                });
            });
            setAllGap(allGapArr);
        }
        // eslint-disable-next-line
    }, [allGapDataFetched]);

    const setRecords = recordData => {
        const obj = {};
        const newObj = {};
        const sortRecords = sortChildren(recordData);
        Object.keys(sortRecords).forEach(key => {
            const { level = '', children = '' } = sortRecords[key];
            const sortChildRecords = sortChildren(children);
            if (level && level !== '-') {
                analyseData(obj, key, sortRecords, sortChildRecords);
            } else {
                obj.workplace = {};
                obj.workplace = analyseData(newObj, key, sortRecords, sortChildRecords);
                obj.workplace.display_name = WORKPLACE_OUT;
            }
        });
        setQbyqData(obj);
    };

    useEffect(() => {
        if (!message && !isEqual(records, qbyqData)) {
            const localRecord = cloneDeep(records);
            setRecords(localRecord);
        }
        // eslint-disable-next-line
    }, [levels_score, message]);

    const getStyles = (score, incQuartile, scoreQuartile) => {
        return score !== '-' ? getInclusionStyle(incQuartile, scoreQuartile, benchmarkStyles, styles) : NA_BG;
    };

    const renderRowBox = (
        display_name,
        gapV,
        score,
        meta_scores,
        subKey,
        keyName,
        inclusion_quartile,
        quartile,
        displayScore
    ) => {
        // const displayScore = scoreToDisplay(score, meta_scores, isPulse);
        const { bgcolor: bgColor = '', text_color: textColor = '', border_color: borderColor } = getStyles(
            displayScore,
            inclusion_quartile,
            quartile
        );
        const { gap: gapMain = '', avg_gap: gapMainAvg = '' } = gap[keyName] || {};
        const displayGap = showAvgGap ? gapMainAvg : gapMain;
        const {
            bgcolor: gapBgColor = '',
            text_color: textBgColor = '',
            border_color: gapBorderColor = '',
        } = getGapColor(displayGap, gapStyles);
        return (
            <div key={subKey} className="rowBox">
                <div>
                    <div className="leftSection">
                        <div className="secBody clearfix">
                            <div>
                                <div className="categoryHeading">{display_name}</div>
                                {allGapDataFetched ? (
                                    <div
                                        style={{ background: gapBgColor, border: `0.5px solid ${gapBorderColor}` }}
                                        className="rectangle"
                                    >
                                        <div style={{ color: textBgColor }}>{displayGap}</div>
                                    </div>
                                ) : (
                                    <div
                                        style={{ background: gapBgColor, border: `0.5px solid ${gapBorderColor}` }}
                                        className="rectangle"
                                    >
                                        <div style={{ color: textBgColor }}>
                                            <div className="spinLoader">
                                                <img src={Loader} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div
                                    style={{ background: bgColor, border: `0.5px solid ${borderColor}` }}
                                    className="circle"
                                >
                                    <div style={{ color: textColor }}>{displayScore}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="middleSection" />
                    <div className="rightSection" />
                </div>
                {meta_scores.map((val, meta_index) => {
                    const {
                        score: meta_score = '',
                        qtext = '',
                        neutral_score = '',
                        bottom_2_score = '',
                        na_score = '',
                        display_score = '',
                        meta = '',
                    } = val;
                    const qTextToUse =
                        ohid === CRH_OHI14649 && IA_QBYQ_CRH_OHI14649[meta] ? IA_QBYQ_CRH_OHI14649[meta] : qtext;
                    return (
                        <div key={meta_index}>
                            <div className="leftSection">
                                <div className="margin10">{qTextToUse}</div>
                            </div>
                            <div className="middleSection">
                                <div className="margin10">{display_score}</div>
                            </div>
                            <div className="rightSection">
                                <div
                                    className={c('column', {
                                        marginBottom3: meta_scores.length - 1 === meta_index,
                                    })}
                                >
                                    <div className="lineGraph margin10">
                                        <div style={{ width: `${bottom_2_score}%` }}>
                                            <div>{bottom_2_score}</div>
                                        </div>
                                        <div style={{ width: `${neutral_score}%` }}>
                                            <div>{neutral_score}</div>
                                        </div>
                                        <div style={{ width: `${meta_score}%` }}>
                                            <div>{meta_score}</div>
                                        </div>
                                    </div>
                                    <div className="naValue margin10">
                                        <div>{na_score}%</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderWorplaceOutcomes = data => {
        return Object.keys(data).map(subKey => {
            const {
                display_name = '',
                score = '',
                meta_scores = [],
                gapV = '',
                keyName,
                inclusion_quartile = -1,
                quartile = -1,
                display_score,
            } = data[subKey];
            return data[subKey] === 'Workplace Outcome'
                ? null
                : renderRowBox(
                      display_name,
                      gapV,
                      score,
                      meta_scores,
                      subKey,
                      keyName,
                      inclusion_quartile,
                      quartile,
                      display_score
                  );
        });
    };

    const getFilterSlideObj = (filterText, reportName, Nsize) => {
        return {
            identify_shape_name: 'checkme',
            identify_shape_value: 'filters_applied',
            is_copy: true,
            is_delete: true,
            data: [
                {
                    name: 'filtertext',
                    value: filterText,
                },
                {
                    name: 'exclude_inclusion_gap_text',
                    value: `${excludeFromGapText(getExcludeGapString(defaultExGap, exGap), demographics)}`,
                },
                {
                    name: 'Source',
                    value: `${reportName} (${NUMBER_TEXT} = ${Nsize})`,
                },
                {
                    name: 'checkme',
                    is_delete: true,
                },
            ],
        };
    };

    const getPpt = (slideArr = []) => {
        const { pptAccessToken = '' } = AuthStore;
        const filterText = getFiltersText(filters, demographics);
        const filterObj = getFilterSlideObj(filterText, rName, size);
        const pptParams = isCRHClient ? PPT_PRAMS_CRH : PPT_PARAMS;
        const output = {
            ...pptParams,
            slides: [...slideArr, filterObj],
        };
        getPptData(output, pptAccessToken, 'Inclusion_Question');
    };

    const sourceArr = [
        {
            name: 'Source',
            value: `(${NUMBER_TEXT} = ${size})`,
        },
        {
            name: 'checkme',
            is_delete: true,
        },
        ...paramForLegend,
    ];

    const renderWorplaceOutcomesView = (displayName, qbyqDataObj) => {
        if (displayName === WORKPLACE_OUT) {
            return renderWorplaceOutcomes(qbyqDataObj);
        }
        const {
            display_name,
            gapV,
            score,
            meta_scores,
            keyName,
            inclusion_quartile,
            quartile,
            display_score,
        } = qbyqDataObj;
        return renderRowBox(
            display_name,
            gapV,
            score,
            meta_scores,
            '',
            keyName,
            inclusion_quartile,
            quartile,
            display_score
        );
    };

    const getDataForPpt = () => {
        const slideArr = [];
        const styleArr = withBenchmark ? pptStyle : styles;
        Object.keys(qbyqData).forEach((qbyqObj, qbyqIndex) => {
            const chartArr = [];
            const labelArr = [];
            let innerArr = [];
            let lastValue = '';
            let metaScoreArr = [];
            if (qbyqIndex === Object.keys(qbyqData).length - 1) {
                Object.keys(qbyqData[qbyqObj]).forEach(v => {
                    const { meta_scores = [], keyName = '' } = qbyqData[qbyqObj][v];
                    labelArr.push(keyName);
                    metaScoreArr = [...metaScoreArr, ...meta_scores];
                });
                getMetaScore(metaScoreArr, innerArr, chartArr);
                innerArr.push({
                    name: `workplace_outcomes_Chart`,
                    meta_scores: labelArr.filter(v => v),
                    chart_data: chartArr,
                });
                innerArr = [...innerArr, ...sourceArr];
                slideArr.push(slidesArrayObject(innerArr, 'Questions_workplace_outcomes', styleArr));
            } else {
                const {
                    display_score: outcome_score = '',
                    key = '',
                    meta_scores = [],
                    hirearchy = {},
                    quartile = -1,
                } = qbyqData[qbyqObj];
                innerArr.push({
                    name: `${key}_score`,
                    value: outcome_score,
                    style: withBenchmark
                        ? getBenchmarkColor(quartile)
                        : getColor(outcome_score, scoreStyles, false, true),
                });
                getMetaScore(meta_scores, innerArr, chartArr);
                innerArr.push({
                    name: `${key}_Chart`,
                    meta_scores: [key],
                    chart_data: chartArr,
                });
                let metaArr = [];
                Object.keys(hirearchy).forEach(val => {
                    const hirearchyArr = hirearchy[val];
                    hirearchyArr.forEach((item, itemIndex) => {
                        const {
                            display_score: score = '',
                            keyName = '',
                            meta_scores: inner_meta_scores = [],
                            quartile: incQuartile = -1,
                        } = item;
                        if (key === 'fairness' && itemIndex === hirearchyArr.length - 1) {
                            lastValue = score;
                        }
                        innerArr.push({
                            name: `${keyName}_score`,
                            value: score,
                            style: withBenchmark
                                ? getBenchmarkColor(incQuartile)
                                : getColor(score, scoreStyles, false, true),
                        });

                        labelArr.push(keyName);
                        metaArr = [...metaArr, ...inner_meta_scores];
                    });
                });
                innerArr.push({
                    name: `${key}_practices_Chart`,
                    meta_scores: key === 'fairness' ? labelArr.slice(0, 3) : labelArr,
                    chart_data: getInnerChartArr(true, metaArr, key, innerArr),
                });
                metaArr.forEach(({ qtext, meta }) => {
                    const qTextToUse =
                        ohid === CRH_OHI14649 && IA_QBYQ_CRH_OHI14649[meta] ? IA_QBYQ_CRH_OHI14649[meta] : qtext;
                    innerArr.push({
                        name: `${meta}-qtext`,
                        value: qTextToUse,
                    });
                });
                innerArr = [...innerArr, ...sourceArr];
                slideArr.push(
                    slidesArrayObject(innerArr, key === 'fairness' ? `questions_${key}1` : `questions_${key}`, styleArr)
                );
                if (key === 'fairness') {
                    innerArr = [];
                    innerArr.push(
                        {
                            name: `${labelArr[labelArr.length - 1]}_score`,
                            value: lastValue,
                            style: withBenchmark
                                ? getBenchmarkColor(quartile)
                                : getColor(lastValue, scoreStyles, false, true),
                        },
                        {
                            name: `${key}2_practices_Chart`,
                            meta_scores: labelArr.slice(labelArr.length - 1),
                            chart_data: getInnerChartArr(false, metaArr, key, innerArr),
                        }
                    );
                    innerArr = [...innerArr, ...sourceArr];
                    slideArr.push(slidesArrayObject(innerArr, `questions_${key}2`, styleArr));
                }
            }
        });
        getPpt(slideArr);
    };

    const checkLength = Object.keys(qbyqData).length;
    const data = practiceQuestionView ? practiceQuestionData : qbyqData;

    return (
        <div className="questionBoard clearfix">
            <Helmet>
                <title>{INCLUSION_QUESTIONS_BOARD}</title>
            </Helmet>
            <div className="underline">
                <div className="optionWrapper">
                    <div>
                        <h2>{QUESTIONS}</h2>
                    </div>
                    <div>
                        <ul className="rightNav">
                            <li>
                                {checkLength ? (
                                    <div className="respondents">
                                        {TOTAL_RES_TEXT}
                                        {size}
                                    </div>
                                ) : null}
                            </li>
                            <li
                                data-testid="getPptBtn"
                                className={c({ pptLoading: !checkGapLength }, 'separator')}
                                onClick={getDataForPpt}
                            >
                                <img src={DownloadIcon} alt="" /> <span>{DOWNLOAD}</span>
                            </li>
                            <Tooltip arrow title={FILTER_HOVER_TXT}>
                                <li
                                    className="separator noBorder"
                                    data-testid="filterButton"
                                    onClick={() => toggleFilterView(true)}
                                >
                                    <img src={activeFilter ? ActiveFilter : FilterSvg} alt="" /> <span>{FILTERS}</span>
                                </li>
                            </Tooltip>
                        </ul>
                    </div>
                </div>
            </div>
            {message ? <ErrorToast message={message} /> : null}

            <div className="content">
                <div className="buttonWrapper">
                    <span>{`${VIEW_BY} :`}</span>
                    <div className="buttonContent">
                        <button
                            type="button"
                            className={c('separateButton', { isActive: viewType === 1 })}
                            onClick={() => filteredDataView(1, false)}
                        >
                            {OUTCOME_SELECT}
                        </button>
                        <button
                            type="button"
                            className={c('separateButton', { isActive: viewType === 2 })}
                            onClick={() => filteredDataView(2, true)}
                        >
                            {PRACTICES_SELECT}
                        </button>
                    </div>
                </div>
                <div className="category">
                    {viewType === 1 && (
                        <div>
                            {Object.keys(qbyqData).map((key, i) => {
                                const { display_name = '' } = qbyqData[key];
                                return (
                                    <div
                                        onClick={() => executeSelect(display_name)}
                                        key={i}
                                        className={c('greyButton', { isActive: hideAll === display_name })}
                                    >
                                        {display_name}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    {viewType === 2 && (
                        <div>
                            {Object.keys(practiceQuestionData).map((key, i) => {
                                const { display_name = '' } = practiceQuestionData[key];
                                return (
                                    <div
                                        onClick={() => executeSelect(display_name)}
                                        key={i}
                                        className={c('greyButton', { isActive: hideAll === display_name })}
                                    >
                                        {display_name}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
                <div className="tile">
                    {checkLength ? (
                        <div>
                            <div>
                                <ul className="classification">
                                    <li>{SCORE_CLASSIFICATION_TEXT}</li>
                                    {styleToUse
                                        .filter(
                                            ({ quartile, style_for }) =>
                                                quartile !== '0' && quartile !== '999' && style_for !== 'blank_data'
                                        )
                                        .map(({ title, bgcolor }) => (
                                            <li key={title}>
                                                <span className="circle" style={{ background: bgcolor }} />
                                                {title}
                                            </li>
                                        ))}
                                </ul>
                            </div>
                            <div>
                                <ul className="classification">
                                    <li>{GAP_CLASSIFICATION_TEXT} </li>
                                    {gapStyles.map(({ title, bgcolor }) => (
                                        <li key={title}>
                                            <span style={{ background: bgcolor }} />
                                            {title}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className="box">
                    {Object.keys(data).map((key, i) => {
                        const objToUse = !practiceQuestionView ? qbyqData[key] : practiceQuestionData[key];
                        const { display_name = '', hirearchy = {} } = objToUse;
                        return (
                            <div
                                key={i}
                                ref={element => {
                                    refs.current[i] = element;
                                }}
                                className={c('boxSection', {
                                    hideBox: hideAll !== display_name && !!hideAll,
                                })}
                            >
                                {!practiceQuestionView && (
                                    <div className="columnBox">
                                        <div className="leftSection">
                                            <h3>{i === checkLength - 1 ? WORKPLACE_QUESTIONS : OUTCOME_QUESTIONS}</h3>
                                        </div>
                                        <div className="middleSection">
                                            <div className="heading">{INCL_HEAD_META}</div>
                                        </div>
                                        <div className="rightSection">
                                            <div className="heading">{AGGREMENT_INCLUSION_LEVEL}</div>
                                            <div className="legends">
                                                {HEADER_LEGENDS_1.map(({ title = '', background = '' }, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <div
                                                                style={{
                                                                    border:
                                                                        index === HEADER_LEGENDS.length - 1
                                                                            ? '1px solid'
                                                                            : '',
                                                                    backgroundColor: background,
                                                                }}
                                                            />
                                                            <div>{title}</div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {!practiceQuestionView ? renderWorplaceOutcomesView(display_name, objToUse) : null}
                                {Object.keys(hirearchy).map((hirearchyKey, hirIndex) => {
                                    const innerKeys = hirearchy[hirearchyKey];
                                    const hirearchyKeyName = hirearchyKey === PEER_TEXT ? PEERS : hirearchyKey;
                                    return (
                                        <div key={hirIndex}>
                                            <div className="columnBox">
                                                {!hirIndex ? (
                                                    <div className="leftSection">
                                                        <h3>
                                                            {practiceQuestionView
                                                                ? hirearchyKeyName
                                                                : 'Practice Questions'}
                                                        </h3>
                                                    </div>
                                                ) : null}

                                                <div className="middleSection" />
                                                {!hirIndex ? (
                                                    <div className="rightSection">
                                                        <div className="heading">{FREQUENCY_SCALE}</div>
                                                        <div className="legends">
                                                            {SUB_HEADER_LEGENDS_1.map(
                                                                ({ title = '', background = '' }, index) => {
                                                                    return (
                                                                        <div key={index}>
                                                                            <div
                                                                                style={{
                                                                                    border:
                                                                                        index ===
                                                                                        HEADER_LEGENDS.length - 1
                                                                                            ? '1px solid'
                                                                                            : '',
                                                                                    backgroundColor: background,
                                                                                }}
                                                                            />
                                                                            <div>{title}</div>
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="rightSection" />
                                                )}
                                            </div>
                                            {innerKeys.map((innerKey, innerIndex) => {
                                                const {
                                                    display_name: practice_display_name = '',
                                                    meta_scores: practice_meta_scores = [],
                                                    score: practice_score = '',
                                                    display_score: displayScore = '',
                                                    keyName: practiceKey = '',
                                                    inclusion_quartile: incQuartile = -1,
                                                    quartile: scoreQuartile = -1,
                                                } = innerKey;
                                                const gapC = allGap.length
                                                    ? allGap.filter(({ key: k }) => k === practiceKey)[0]
                                                    : {};
                                                const { gap: practiceGap = '', avg_gap: practiceGapAvg = '' } =
                                                    gapC || {};
                                                // const displayScore = scoreToDisplay(
                                                //     practice_score,
                                                //     practice_meta_scores,
                                                //     isPulse
                                                // );
                                                const displayGap = gapToDisplay(
                                                    showAvgGap ? practiceGapAvg : practiceGap,
                                                    practice_score,
                                                    isPulse
                                                );
                                                const {
                                                    bgcolor: bgColor = '',
                                                    text_color: textColor = '',
                                                    border_color: borderColor = '',
                                                } = getStyles(displayScore, incQuartile, scoreQuartile);

                                                const {
                                                    bgcolor: gapBgColor = '',
                                                    text_color: textBgColor = '',
                                                    border_color: gapBorderColor = '',
                                                } = getGapColor(displayGap, gapStyles);

                                                return (
                                                    <div key={innerIndex} className="rowBox borderBottom">
                                                        <div>
                                                            <div className="leftSection">
                                                                <div className="secBody clearfix">
                                                                    <div>
                                                                        <div className="categoryHeading">
                                                                            {practice_display_name}
                                                                        </div>
                                                                        {allGapDataFetched ? (
                                                                            <div
                                                                                style={{
                                                                                    background: gapBgColor,
                                                                                    border: `0.5px solid ${gapBorderColor}`,
                                                                                }}
                                                                                className="rectangle"
                                                                            >
                                                                                <div style={{ color: textBgColor }}>
                                                                                    {displayGap}
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <div
                                                                                style={{
                                                                                    background: gapBgColor,
                                                                                    border: `0.5px solid ${gapBorderColor}`,
                                                                                }}
                                                                                className="rectangle"
                                                                            >
                                                                                <div style={{ color: textBgColor }}>
                                                                                    <div className="spinLoader">
                                                                                        <img src={Loader} alt="" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        <div
                                                                            style={{
                                                                                background: bgColor,
                                                                                border: `0.5px solid ${borderColor}`,
                                                                            }}
                                                                            className="circle"
                                                                        >
                                                                            <div style={{ color: textColor }}>
                                                                                {displayScore}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <section className="tags">
                                                                        <img
                                                                            src={IMAGE_PRACTICES[hirearchyKey]}
                                                                            alt=""
                                                                        />
                                                                        {hirearchyKeyName}
                                                                    </section>
                                                                </div>
                                                            </div>
                                                            <div className="middleSection" />
                                                            <div className="rightSection" />
                                                        </div>
                                                        {practice_meta_scores.map((val, practiceIndex) => {
                                                            const {
                                                                score: meta_score = '',
                                                                qtext = '',
                                                                neutral_score = '',
                                                                bottom_2_score = '',
                                                                na_score = '',
                                                                display_score = '',
                                                                meta = '',
                                                            } = val;
                                                            const qTextToUse =
                                                                ohid === CRH_OHI14649 && IA_QBYQ_CRH_OHI14649[meta]
                                                                    ? IA_QBYQ_CRH_OHI14649[meta]
                                                                    : qtext;
                                                            return (
                                                                <div key={practiceIndex}>
                                                                    <div className="leftSection">
                                                                        <div className="margin10">{qTextToUse}</div>
                                                                    </div>
                                                                    <div className="middleSection">
                                                                        <div className="margin10">{display_score}</div>
                                                                    </div>

                                                                    <div className="rightSection">
                                                                        <div
                                                                            className={c('column', {
                                                                                marginBottom3:
                                                                                    practice_meta_scores.length - 1 ===
                                                                                    practiceIndex,
                                                                            })}
                                                                        >
                                                                            <div className="lineGraph margin10">
                                                                                <div
                                                                                    style={{
                                                                                        width: `${bottom_2_score}%`,
                                                                                    }}
                                                                                >
                                                                                    <div>{bottom_2_score}</div>
                                                                                </div>
                                                                                <div
                                                                                    style={{
                                                                                        width: `${neutral_score}%`,
                                                                                    }}
                                                                                >
                                                                                    <div>{neutral_score}</div>
                                                                                </div>
                                                                                <div
                                                                                    style={{ width: `${meta_score}%` }}
                                                                                >
                                                                                    <div>{meta_score}</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="naValue margin10">
                                                                                <div>{na_score}%</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
                <div className="filterInfoSection">
                    <div>
                        <b>{FILTERS_APPLIED + ' : '}</b>
                        {getFiltersAppliedText(filters, demographics)
                            ? getFiltersAppliedText(filters, demographics)
                            : NONE}{' '}
                        | n = {size}
                    </div>
                    <div>
                        <b>{BENCHMARKS_APPLIED + ' : '}</b> {`${benchmarkName} | ${benchmarkVersions[0]}`}
                    </div>
                </div>
            </div>
            {checkLength ? (
                <div className="disclaimer">
                    <div>{DISCLAIMER}</div>
                    {/* <div>{QUESTION_DISCLAIMER}</div> */}
                    {SCORECARD_DISCLAIMER.map((text, i) => (
                        <div key={`res${i}`} className="demoDisclaimerNote">
                            <div>{i + 1}.</div>
                            <div>{text}</div>
                        </div>
                    ))}
                    {isSurvey2 && <div>{IA_2_QUESTIONS_DISCLAIMER}</div>}
                </div>
            ) : null}
        </div>
    );
}

Questions.defaultProps = {
    benchmarkName: '',
    isPulse: false,
    showAvgGap: false,
};

Questions.propTypes = {
    inclusionqbyq: PropTypes.object.isRequired,
    allGapData: PropTypes.object.isRequired,
    activeFilter: PropTypes.bool.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    getPptData: PropTypes.func.isRequired,
    demographics: PropTypes.array.isRequired,
    filters: PropTypes.array.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    scoreColor: PropTypes.object.isRequired,
    iaApiParams: PropTypes.object.isRequired,
    reportApiParams: PropTypes.object.isRequired,
    benchmarkName: PropTypes.string,
    benchmarkVersions: PropTypes.array.isRequired,
    isPulse: PropTypes.bool,
    showAvgGap: PropTypes.bool,
    allGapDataFetched: PropTypes.bool.isRequired,
};

export default Questions;
