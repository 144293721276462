import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import { Button, TextField, Tooltip } from '@material-ui/core';
import { KeyboardArrowRight, ArrowBack } from '@material-ui/icons';
import { cloneDeep } from 'lodash';

import AuthStore from '../../common/AuthStore';
import Demographics from './Demographics';
import Benchmarks from './Benchmarks';
import { getStaticText } from '../../utils/constants';
import { addZeroToNUmber, getIaBenchmarkLabel } from '../../utils/functions';
// import CloseIcon from '../../assets/Close.svg';
import FilterSvg from '../../containers/Diagnose/assets/Filter.svg';
import Remove from '../../assets/Remove.svg';
import './index.scss';

function FilterContent({
    size,
    surveyRespondents,
    surveys,
    indexToUpdate,
    filters,
    activeApplyButton,
    activeResetFilter,
    setBenchMark,
    setDemoActive,
    updateFilterState,
    updateCurrentFilter,
    closeFilters,
    showHeader,
    resetFilters,
    showBenchmarks,
    currentView,
    activeProject,
    defaultSettings,
    isInclusion,
    updateReportApiParams,
    reportApiParams,
    demographicList,
    isIaResurvey,
    benchmarkVersions,
    setBmVersionInUse,
    bmVersionInUse,
    isRR,
    isEEpage,
    showResurvey,
    showRes,
    year,
    setIsOpen,
}) {
    const {
        threshold = 10,
        inclusion_threshold = 10,
        rr_threshold = 10,
        lang,
        exclude_inclusion_gap: excludeGap = '',
        ee_threshold,
    } = defaultSettings;
    const { exclude_inclusion_gap: reportExcludeGap = '' } = reportApiParams;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const {
        APPLY_FILTERS,
        RESET_FILTERS,
        DEMOGRAPHIC,
        BENCHMARK_HEAD,
        FILTERS,
        TOTAL_RESPONDENTS,
        // ENTER_REPORT_NAME,
        THRESHOLD_TXT,
        EXCLUDE_DEMO_GROUP_HEADING,
        TOTAL_RES_TEXT,
        PPT_VALUES,
        LONG_TEXT,
        REPORT_FILTER,
        COMPARISON_FILTER,
    } = SITE_TEXT || {};

    const thresholdUsed = isInclusion ? inclusion_threshold : threshold;
    const thresholdToUse = isRR ? rr_threshold : thresholdUsed;
    const { thresholdTip } = PPT_VALUES({ threshold: thresholdToUse });
    const [activeFilter, updateView] = useState(null);
    const [reportName, updateReportName] = useState('Bu');
    const { benchmarks = [], demographics = [] } = filters;
    const [demoGraphicState, setDemoState] = useState(demographicList);
    let excludeArr = [];
    if (excludeGap) {
        excludeArr = excludeGap.includes(',') ? excludeGap.split(',') : [excludeGap];
    }
    const [practiceReportName, updatePracticeReportName] = useState('Organization');
    const [resurveyReportName, updateResurveyReportName] = useState('Organization');
    const [resurveyReportName2, updateResurveyReportName2] = useState('Comparison survey');
    const showViewFilterBu = showRes[0] === REPORT_FILTER && showRes[1] === COMPARISON_FILTER;

    useEffect(() => {
        const { practiceBU, practiceReportBU, resurveyFilter1, resurveyFilter2 } = AuthStore;
        const reportNameInUse = currentView < 3 ? AuthStore[`resurveyName${activeProject}`] : practiceBU;
        const reportNameInUseBu = currentView < 3 ? AuthStore[`resurveyName${activeProject}`] : practiceReportBU;
        const isReportPracticeBU = currentView === 6 ? practiceBU : reportNameInUse;
        const ispracticeReportBu = currentView === 6 ? practiceReportBU : reportNameInUseBu;
        updatePracticeReportName(ispracticeReportBu);
        updateReportName(isReportPracticeBU);

        const resurveyFilter = currentView < 3 && resurveyFilter1;
        updateResurveyReportName(resurveyFilter);

        const resurveyComp = currentView < 3 && resurveyFilter2;
        updateResurveyReportName2(resurveyComp);

        const newDemo = cloneDeep(demoGraphicState);
        let excludeGapArr = '';
        if (reportExcludeGap) {
            excludeGapArr = reportExcludeGap.includes(',') ? reportExcludeGap.split(',') : [reportExcludeGap];
            excludeGapArr.forEach(element => {
                newDemo.forEach(item => {
                    const { code, options = [] } = item;
                    if (code === element) {
                        // eslint-disable-next-line no-return-assign
                        options.forEach(elem => (elem.isSelected = true));
                    }
                });
            });
        }
        if (excludeGap) {
            excludeGapArr = excludeGap.includes(',') ? excludeGap.split(',') : [excludeGap];
            excludeGapArr.forEach(element => {
                newDemo.forEach(item => {
                    const { code, options = [] } = item;
                    if (code === element) {
                        // eslint-disable-next-line no-return-assign
                        options.forEach(elem => (elem.isSelected = true));
                    }
                });
            });
        }
        setDemoState(newDemo);
        // eslint-disable-next-line
    }, []);

    const selectedDemos = () => {
        return demoGraphicState
            .filter(({ options }) => options.filter(({ isSelected }) => isSelected).length === options.length)
            .map(({ code }) => code);
    };

    const updateExcludeGapParams = () => {
        const arr = selectedDemos();
        let excludeGapText = '';
        if (arr.length) {
            excludeGapText = arr.length > 1 ? arr.join(',') : arr[0];
        }
        updateReportApiParams({
            ...reportApiParams,
            exclude_inclusion_gap: excludeGapText,
        });
        updateView(false);
    };

    const setBackOldView = (updateRedux = false) => {
        if (activeFilter === 3) {
            updateExcludeGapParams();
        }
        const updatedDemographics = cloneDeep(demographics);
        const openItem = updatedDemographics.findIndex(({ isOpen: oldOpen }) => oldOpen);
        if (openItem !== -1) {
            updatedDemographics[openItem].isOpen = false;
        }
        const updatedBenchmarks = cloneDeep(benchmarks);
        const openedBenchmark = updatedBenchmarks.findIndex(({ isOpen: oldOpen }) => oldOpen);
        if (openedBenchmark !== -1) {
            updatedBenchmarks[openedBenchmark].isOpen = false;
        }
        updateFilterState(
            indexToUpdate,
            { demographics: updatedDemographics, benchmarks: updatedBenchmarks },
            updateRedux,
            activeFilter
        );
        updateView(null);
    };

    const updateReportText = () => {
        if (currentView < 3) {
            AuthStore[`resurveyName${activeProject}`] = reportName;
        } else {
            AuthStore.practiceBU = reportName || 'Bu';
            AuthStore.practiceReportBU = practiceReportName || 'Organization';
        }
    };

    const updateResurveyReport = () => {
        if (currentView < 3) {
            AuthStore.resurveyFilter1 = resurveyReportName || 'Organization';
        }
    };

    const updateResurveyReportComp = () => {
        if (currentView < 3) {
            AuthStore.resurveyFilter2 = resurveyReportName2 || 'Comparison survey';
        }
    };

    const setReportName = ({ value }) => value.length <= 18 && updateReportName(value);
    let { label: benchmarksLabel = '' } = benchmarks.filter(({ selectedOption }) => selectedOption)[0] || {};
    benchmarksLabel = getIaBenchmarkLabel(benchmarksLabel);
    const selectedDemographicsText = demoListToUse => {
        let demographicsSelected = 0;
        demoListToUse.forEach(({ options = [] }) => {
            const selectedLength = options.filter(({ isSelected }) => isSelected).length;
            if (selectedLength) {
                demographicsSelected += 1;
            }
        });
        if (demographicsSelected > 0) {
            return `${addZeroToNUmber(demographicsSelected)}/${addZeroToNUmber(demoListToUse.length)}`;
        }
        return '';
    };

    const setReport = ({ value }) => {
        if (value.length <= 18) {
            updatePracticeReportName(value);
        }
    };

    const setResurveyReportName = ({ value }) => {
        if (value.length <= 18) {
            updateResurveyReportName(value);
        }
    };

    const setResurveyReportName2 = ({ value }) => {
        if (value.length <= 18) {
            updateResurveyReportName2(value);
        }
    };

    const handleClose = () => {
        setTimeout(() => {
            closeFilters(false);
        }, 200);
        setIsOpen(false);
    };

    return (
        <Fragment>
            <div className="closeContent">
                <img src={Remove} alt="" onClick={handleClose} data-testid="closeFilter" />
            </div>
            <div className="topContent clearfix">
                <h2>
                    {isInclusion && (
                        <span>
                            <img src={FilterSvg} alt="" />
                        </span>
                    )}
                    {FILTERS}
                </h2>
            </div>

            <div className="sizeInfo thresholdInfo">
                {isInclusion ? TOTAL_RES_TEXT : TOTAL_RESPONDENTS} {size.toLocaleString('en-US')}
                <Tooltip title={<span style={{ fontSize: '1.2vh' }}>{thresholdTip}</span>}>
                    <span style={{ cursor: 'pointer' }}>
                        {THRESHOLD_TXT}: {isEEpage ? ee_threshold : thresholdToUse}
                    </span>
                </Tooltip>
            </div>

            {showHeader && !activeFilter && showViewFilterBu && (
                <div className="projectTextContent">
                    <h3 className="projectName" onClick={() => updateCurrentFilter(null)}>
                        <ArrowBack />
                        {showHeader}
                    </h3>
                    {showHeader === COMPARISON_FILTER ? (
                        <div className="resurveyName">
                            <h1 className="headerContent">Header</h1>
                            <TextField
                                fullWidth
                                className="reportTextField"
                                value={reportName}
                                onChange={({ target }) => setReportName(target)}
                                onBlur={updateReportText}
                                style={{ marginTop: '0' }}
                                maxLength={18}
                            />
                            <div className="longText">{LONG_TEXT}</div>
                        </div>
                    ) : null}
                    {showHeader === REPORT_FILTER ? (
                        <div className="resurveyName">
                            <h1 className="headerContent">Header</h1>
                            <TextField
                                fullWidth
                                className="reportTextField"
                                value={practiceReportName}
                                onChange={({ target }) => setReport(target)}
                                onBlur={updateReportText}
                                style={{ marginTop: '0' }}
                                maxLength={18}
                            />
                            <div className="longText">{LONG_TEXT}</div>
                        </div>
                    ) : null}
                </div>
            )}

            {showResurvey && !activeFilter && !showViewFilterBu && (
                <div className="projectTextContent">
                    <h3 className="projectName" onClick={() => updateCurrentFilter(null)}>
                        <ArrowBack />
                        {showHeader}
                    </h3>
                    {showResurvey === showRes[0] ? (
                        <div className="resurveyName">
                            <h1 className="headerContent">Header</h1>
                            <TextField
                                fullWidth
                                className="reportTextField"
                                value={resurveyReportName}
                                onChange={({ target }) => setResurveyReportName(target)}
                                onBlur={updateResurveyReport}
                                style={{ marginTop: '0' }}
                                maxLength={18}
                            />
                            <div className="longText">{LONG_TEXT}</div>
                        </div>
                    ) : null}
                    {showResurvey === showRes[1] ? (
                        <div className="resurveyName">
                            <h1 className="headerContent">Header</h1>
                            <TextField
                                fullWidth
                                className="reportTextField"
                                value={resurveyReportName2}
                                onChange={({ target }) => setResurveyReportName2(target)}
                                onBlur={updateResurveyReportComp}
                                style={{ marginTop: '0' }}
                                maxLength={18}
                            />
                            <div className="longText">{LONG_TEXT}</div>
                        </div>
                    ) : null}
                </div>
            )}

            {!activeFilter && (
                <ul className="selectList">
                    <li onClick={() => updateView(1)} data-testid="demoButton" className="filtered">
                        {DEMOGRAPHIC}
                        {selectedDemographicsText(demographics) ? (
                            <span className="selectedLabel">{selectedDemographicsText(demographics)}</span>
                        ) : null}
                        <KeyboardArrowRight />
                    </li>
                    {(!indexToUpdate && showHeader) || (showBenchmarks && benchmarks.length) ? (
                        <li onClick={() => updateView(2)} data-testid="benchButton" className="filtered">
                            {BENCHMARK_HEAD}
                            {benchmarksLabel && benchmarks.length && (
                                <span className="selectedLabel">{benchmarksLabel}</span>
                            )}
                            <KeyboardArrowRight />
                        </li>
                    ) : null}
                    {isInclusion && !isIaResurvey ? (
                        <li onClick={() => updateView(3)} data-testid="benchButton" className="filtered">
                            {EXCLUDE_DEMO_GROUP_HEADING}
                            {selectedDemographicsText(demoGraphicState) ? (
                                <span className="selectedLabel">{selectedDemographicsText(demoGraphicState)}</span>
                            ) : null}
                            <KeyboardArrowRight />
                        </li>
                    ) : null}
                </ul>
            )}
            {activeFilter === 1 && (
                <Demographics
                    defaultSettings={defaultSettings}
                    demographics={demographics}
                    setDemoActive={setDemoActive}
                    setBackOldView={setBackOldView}
                    updateDemographics={newD => updateFilterState(indexToUpdate, { demographics: newD, benchmarks })}
                />
            )}
            {activeFilter === 2 && (
                <Benchmarks
                    defaultSettings={defaultSettings}
                    surveyRespondents={surveyRespondents}
                    surveys={surveys}
                    benchmarks={benchmarks}
                    setBenchMark={setBenchMark}
                    setBackOldView={setBackOldView}
                    updateBenchmarks={newB => updateFilterState(indexToUpdate, { demographics, benchmarks: newB })}
                    isInclusion={isInclusion}
                    benchmarkVersions={benchmarkVersions[activeProject]}
                    setBmVersionInUse={setBmVersionInUse}
                    bmVersionInUse={bmVersionInUse}
                    year={year}
                />
            )}
            {isInclusion && activeFilter === 3 && (
                <Demographics
                    defaultSettings={defaultSettings}
                    demographics={demoGraphicState}
                    setDemoActive={setDemoActive}
                    setBackOldView={updateExcludeGapParams}
                    showHeader={false}
                    noChild
                    updateDemographics={newD => setDemoState(newD)}
                    hiddenOptions={excludeArr}
                    excludeGapText={isInclusion && activeFilter === 3}
                />
            )}
            {activeFilter && (
                <div className="buttonContent clearfix">
                    <Button classes={{ root: c({ active: activeResetFilter }) }} onClick={resetFilters}>
                        {RESET_FILTERS}
                    </Button>
                    <Button classes={{ root: c({ active: activeApplyButton }) }} onClick={() => setBackOldView(true)}>
                        {APPLY_FILTERS}
                    </Button>
                </div>
            )}
        </Fragment>
    );
}

FilterContent.defaultProps = {
    indexToUpdate: null,
    isInclusion: false,
    reportApiParams: {},
    updateReportApiParams: () => {},
    demographicList: [],
    isIaResurvey: false,
    benchmarkVersions: [],
    bmVersionInUse: 2,
    setBmVersionInUse: () => {},
    isRR: false,
    isEEpage: false,
};

FilterContent.propTypes = {
    indexToUpdate: PropTypes.number,
    currentView: PropTypes.number.isRequired,
    activeProject: PropTypes.number.isRequired,
    showHeader: PropTypes.string.isRequired,
    showBenchmarks: PropTypes.bool.isRequired,
    activeApplyButton: PropTypes.bool.isRequired,
    activeResetFilter: PropTypes.bool.isRequired,
    size: PropTypes.number.isRequired,
    surveyRespondents: PropTypes.number.isRequired,
    surveys: PropTypes.number.isRequired,
    filters: PropTypes.object.isRequired,
    closeFilters: PropTypes.func.isRequired,
    updateFilterState: PropTypes.func.isRequired,
    setBenchMark: PropTypes.func.isRequired,
    setDemoActive: PropTypes.func.isRequired,
    resetFilters: PropTypes.func.isRequired,
    updateCurrentFilter: PropTypes.func.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    isInclusion: PropTypes.bool,
    reportApiParams: PropTypes.object,
    updateReportApiParams: PropTypes.func,
    demographicList: PropTypes.array,
    isIaResurvey: PropTypes.bool,
    benchmarkVersions: PropTypes.array,
    bmVersionInUse: PropTypes.number,
    setBmVersionInUse: PropTypes.func,
    isRR: PropTypes.bool,
    isEEpage: PropTypes.bool,
    showResurvey: PropTypes.string.isRequired,
    showRes: PropTypes.array.isRequired,
    year: PropTypes.number.isRequired,
    setIsOpen: PropTypes.func.isRequired,
};

export default FilterContent;
