import { fromJS } from 'immutable';
import { REMOVE_SAVED_TOKEN, GET_DEFAULT_SETTINGS } from '../Login/constants';
import {
    GET_INCLUSION_DATA,
    GET_GAP_DATA,
    GET_GRAPH_GAP_DATA,
    GET_GAP_DATA_ALL,
    GET_RESPONDENTS_DATA,
    GET_INCLUSION_DATA_RES,
    GET_RES_GAP_DATA_ALL,
    DELETE_RESURVEY_MAPPING,
    GET_RES_GAP_DATA,
    GET_MAPPING_EXCEL,
    GET_IA_MAPPED_DEMOGRAPHICS,
    SET_BM_VERSION_IN_USE,
    DELETE_SCORE_COMPARISON,
    GET_QBYQ_NSIZE,
} from '../Reports/constants';
import getStaticTextDiagnose from '../Diagnose/constants';
import {
    SET_CURRENT_OPTION,
    SET_GAP_DATA_FETCHED,
    SET_DROPDOWN_VALUE,
    SET_SCORE_COLOR,
    UPDATE_INCLUSION_FILTERS,
    GET_ALL_GAP_INCLUSION_DATA,
    SET_PAGE_DATA_FETCHED,
    GET_MAPPED_PROJECTS,
    UPDATE_INCLUSION_RES_FILTERS,
    SET_PREVIOUS_SURVEY,
    SET_DEMO_COMPARISON_SURVEY,
    SET_RESURVEY_DROPDOWN_VALUE,
    GET_RES_ALL_GAP_INCLUSION_DATA,
} from './constants';
import { getStaticText } from '../../utils/constants';
import { UPDATE_SETTINGS } from '../Settings/constants';

const staticTextDiagnose = getStaticTextDiagnose();
const { GET_BENCHMARK_VERSION, GET_DEMOGRAPHICS, GET_IA_BENCHMARK, UPDATE_FILTERS } = staticTextDiagnose;
const staticText = getStaticText();
const { INCLUSION_BENCHMARK, INC_DUMMY_PARAMS, INCLUSION_DEFAULT_BENCHMARK } = staticText;

export const INITIAL_STATE = fromJS({
    count: 0,
    error: '',
    settings: fromJS({}),
    settingsFetched: false,
    inclusionqbyq: fromJS({}),
    allGapData: fromJS({}),
    allGapDataFetched: false,
    gapDataFetched: false,
    inclusionDataFetched: false,
    gapArr: fromJS([]),
    resurveyGapArr: fromJS([]),
    resurveyDemoGapFetched: false,
    demographicList: [],
    demograhicDataFetched: false,
    currentOption: 0,
    respondentsData: fromJS({}),
    resDataFetched: false,
    dropDownValue: '',
    scoreColors: {},
    allGapInclusionData: {},
    allGapInclusionDataLoading: false,
    resAllGapInclusionDataFetched: false,
    resAllGapInclusionData: {},
    resAllGapInclusionDataLoading: false,
    resurveyData: {},
    resurveyGapData: {},
    resurveyDataFetched: false,
    resurveyAllGapData: {},
    resurveyAllGapDataFetched: false,
    resurveyGapDataFetched: false,
    demographicMappedData: fromJS([]),
    benchmarkVersions: fromJS([]),
    benchmarkVersionInUse: 2,
    pagesLoaded: {
        pages: [],
    },
    allGapInclusionDataFetched: false,
    filters: [
        {
            benchmarks: INCLUSION_BENCHMARK,
            demographics: [],
        },
    ],
    apiParams: fromJS(INC_DUMMY_PARAMS),
    filtersApplied: false,
    projects: [],
    previousSurvey: '',
    prevSurveyYear: '',
    demoComparisonSurvey: '',
    demoComparisonYear: '',
    demoNSize: {},
    resurveyDropdown: '',
    resurveyDemographicFetched: false,
});

const reducer = (state = INITIAL_STATE, { type = '', payload = {} }) => {
    const excludeOption = ['demo_StraightLiners', 'demo_StraightLinersIA'];
    switch (type) {
        case `${GET_RESPONDENTS_DATA}_PENDING`:
        case `${GET_GAP_DATA}_PENDING`:
        case `${GET_RES_GAP_DATA}_PENDING`:
        case `${GET_INCLUSION_DATA}_PENDING`:
        case `${GET_INCLUSION_DATA_RES}_PENDING`:
        case `${GET_MAPPING_EXCEL}_PENDING`:
        case `${GET_MAPPED_PROJECTS}_PENDING`:
        case `${GET_BENCHMARK_VERSION}_PENDING`:
        case `${GET_QBYQ_NSIZE}_PENDING`:
            return state.set('error', '').update('count', count => count + 1);
        case REMOVE_SAVED_TOKEN: {
            if (!payload) {
                return INITIAL_STATE;
            }
            return state;
        }
        case `${GET_INCLUSION_DATA}_SUCCESS`: {
            const { styles = [], benchmark_styles = [], delta_styles = [] } = payload;
            const scoreStyles = styles.filter(({ style_for }) => style_for === 'score');
            const gapStyles = styles.filter(({ style_for }) => style_for === 'gap');
            const blankData = styles.filter(({ style_for }) => style_for === 'blank_data')[0] || {};
            const deltaStyles = styles.filter(({ style_for }) => style_for === 'score_delta');
            const gapDeltaStyles = styles.filter(({ style_for }) => style_for === 'gap_delta');
            // if (styles.length) {
            //     styles[5] = {
            //         bgcolor: '#FFFFFF',
            //         border_color: '#000000',
            //         quartile: '999',
            //         style_for: '999',
            //         text_color: '#000000',
            //         title: 'quartile_999',
            //     };
            // }
            const pptBenchmarkStyles = benchmark_styles;
            if (pptBenchmarkStyles.length) {
                pptBenchmarkStyles[5] = {
                    ...pptBenchmarkStyles[5],
                    border_color: '',
                };
            }
            for (let i = benchmark_styles.length; i < 12; i += 1) {
                if (i === 7) {
                    pptBenchmarkStyles.push({
                        bgcolor: '',
                        border_color: '',
                        quartile: '',
                        style_for: 'blank_data',
                        text_color: '#000000',
                        title: '',
                    });
                } else {
                    pptBenchmarkStyles.push(blankData);
                }
            }
            return state
                .update('count', count => count - 1)
                .set('inclusionDataFetched', true)
                .set('inclusionqbyq', payload)
                .set(
                    'scoreColors',
                    fromJS({
                        score: scoreStyles,
                        gap: gapStyles,
                        benchmarkStyles: benchmark_styles,
                        pptBenchmarkStyles: [...pptBenchmarkStyles, ...gapStyles, ...delta_styles, blankData],
                        styles: [...styles, ...delta_styles, blankData],
                        deltaStyles,
                        gapDeltaStyles,
                    })
                );
        }
        case `${GET_INCLUSION_DATA_RES}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('resurveyData', payload)
                .set('inclusionDataFetched', true)
                .set('resurveyDataFetched', true);
        }
        case `${GET_QBYQ_NSIZE}_SUCCESS`: {
            return state.update('count', count => count - 1).set('demoNSize', payload);
        }
        case `${GET_IA_MAPPED_DEMOGRAPHICS}_SUCCESS`: {
            return state.set('demographicMappedData', payload);
        }
        case `${GET_BENCHMARK_VERSION}_SUCCESS`: {
            return state.update('count', count => count - 1).set('benchmarkVersions', payload.benchmarks);
        }
        case `${GET_BENCHMARK_VERSION}_FAILED`: {
            return state.update('count', count => count - 1).set('benchmarkVersions', []);
        }
        case `${GET_IA_MAPPED_DEMOGRAPHICS}_FAILED`: {
            return state.set('demographicMappedData', []);
        }
        case `${GET_QBYQ_NSIZE}_FAILED`: {
            return state.update('count', count => count - 1).set('demoNSize', '');
        }
        case `${GET_INCLUSION_DATA}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('inclusionDataFetched', true)
                .set('inclusionqbyq', fromJS({}));
        }
        case `${GET_INCLUSION_DATA_RES}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('inclusionDataFetched', true)
                .set('resurveyData', payload)
                .set('resurveyDataFetched', true);
        }
        case `${GET_RESPONDENTS_DATA}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('resDataFetched', true)
                .set('respondentsData', payload);
        }
        case `${GET_RESPONDENTS_DATA}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('respondentsData', fromJS({}))
                .set('resDataFetched', true);
        }
        case DELETE_RESURVEY_MAPPING: {
            return state
                .set('resurveyGapDataFetched', false)
                .set('demoComparisonSurvey', '')
                .set('resurveyDemographicFetched', false)
                .set('demoComparisonYear', '');
        }
        case DELETE_SCORE_COMPARISON: {
            return state
                .set('resurveyDataFetched', false)
                .set('resurveyDemographicFetched', false)
                .set('previousSurvey', '')
                .set('prevSurveyYear', '');
        }
        case `${GET_DEMOGRAPHICS}_SUCCESS`: {
            if (payload.indexToUpdate) {
                const filters = state.get('filters').toJS() || {};
                const { benchmarks = [] } = filters[0] || {};
                return state
                    .set('demograhicDataFetched', true)
                    .setIn(['filters', payload.indexToUpdate, 'demographics'], payload.demographics)
                    .setIn(['filters', payload.indexToUpdate, 'benchmarks'], benchmarks);
            }
            return state
                .set(
                    'demographicList',
                    fromJS(payload.demographics).filter(o => !excludeOption.includes(o.get('code')))
                )
                .set('demograhicDataFetched', true)
                .setIn(['filters', payload.indexToUpdate, 'demographics'], payload.demographics);
        }
        case `${GET_GRAPH_GAP_DATA}_SUCCESS`: {
            return state.update('count', count => count - 1);
        }
        case `${GET_MAPPING_EXCEL}_SUCCESS`: {
            return state.update('count', count => count - 1);
        }
        case `${GET_GAP_DATA}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .updateIn(['gapArr'], gapArr => gapArr.concat([payload.gap]))
                .set('gapDataFetched', true);
        }
        case `${GET_RES_GAP_DATA}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('resurveyGapData', payload)
                .set('resurveyGapDataFetched', true);
        }
        case `${GET_RES_GAP_DATA}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('resurveyGapData', fromJS({}))
                .set('resurveyGapDataFetched', true);
        }
        case `${GET_MAPPED_PROJECTS}_SUCCESS`: {
            return state.update('count', count => count - 1).set('projects', payload);
        }
        case `${GET_MAPPED_PROJECTS}_FAILED`:
            return state.update('count', count => count - 1);
        case `${GET_MAPPING_EXCEL}_FAILED`: {
            return state.update('count', count => count - 1);
        }
        case `${GET_GAP_DATA}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('error', payload.message)
                .set('gapArr', fromJS([]))
                .set('gapDataFetched', true);
        }

        case `${GET_ALL_GAP_INCLUSION_DATA}_PENDING`: {
            return state.set('allGapInclusionDataFetched', false).set('allGapInclusionDataLoading', true);
        }
        case `${GET_ALL_GAP_INCLUSION_DATA}_SUCCESS`: {
            const { allGapRes } = payload;
            return state
                .set('allGapInclusionData', allGapRes)
                .set('allGapInclusionDataLoading', false)
                .set('allGapInclusionDataFetched', true);
        }
        case SET_PAGE_DATA_FETCHED: {
            return state.set('allGapInclusionDataFetched', false);
        }
        case `${GET_ALL_GAP_INCLUSION_DATA}_FAILED`: {
            return state
                .set('error', payload.message)
                .set('allGapInclusionDataFetched', true)
                .set('allGapInclusionDataLoading', false);
        }
        case `${GET_RES_ALL_GAP_INCLUSION_DATA}_PENDING`: {
            return state.set('resAllGapInclusionDataFetched', false).set('resAllGapInclusionDataLoading', true);
        }
        case `${GET_RES_ALL_GAP_INCLUSION_DATA}_SUCCESS`: {
            const { allGapRes } = payload;
            return state
                .set('resAllGapInclusionData', allGapRes)
                .set('resAllGapInclusionDataLoading', false)
                .set('resAllGapInclusionDataFetched', true);
        }
        case `${GET_RES_ALL_GAP_INCLUSION_DATA}_FAILED`: {
            return state
                .set('error', payload.message)
                .set('resAllGapInclusionDataFetched', true)
                .set('resAllGapInclusionDataLoading', false);
        }
        case `${GET_GAP_DATA_ALL}_SUCCESS`: {
            return state.set('allGapData', payload).set('allGapDataFetched', true);
        }
        case `${GET_GAP_DATA_ALL}_FAILED`: {
            return state
                .set('allGapData', fromJS({}))
                .set('allGapDataFetched', true)
                .set('error', payload.message);
        }
        case `${GET_RES_GAP_DATA_ALL}_SUCCESS`: {
            return state.set('resurveyAllGapData', payload).set('resurveyAllGapDataFetched', true);
        }
        case `${GET_RES_GAP_DATA_ALL}_FAILED`: {
            return state
                .set('resurveyAllGapData', fromJS({}))
                .set('resurveyAllGapDataFetched', true)
                .set('error', payload.message);
        }
        case `${SET_CURRENT_OPTION}`: {
            return state.set('currentOption', payload);
        }
        case `${SET_GAP_DATA_FETCHED}`: {
            return state.set('gapDataFetched', payload);
        }
        case UPDATE_FILTERS: {
            const apiParams = state.get('apiParams').toJS() || {};
            const { with_benchmark, benchmarks } = apiParams;
            return state
                .setIn(['filters', 0, 'demographics'], payload.filters[0].demographics)
                .set('filtersApplied', !!payload.apiParams.filters.length)
                .set('apiParams', fromJS({ ...payload.apiParams, with_benchmark, benchmarks }))
                .set('inclusionDataFetched', false);
        }
        case UPDATE_INCLUSION_FILTERS: {
            return state
                .setIn(['filters'], payload.filters)
                .set('filtersApplied', payload.filtersApplied)
                .set('apiParams', fromJS(payload.apiParams))
                .set('allGapInclusionDataFetched', false)
                .set('resDataFetched', false)
                .set('allGapDataFetched', false)
                .set('gapDataFetched', false)
                .set('resurveyGapDataFetched', false)
                .set('inclusionDataFetched', false);
        }
        case UPDATE_INCLUSION_RES_FILTERS: {
            return state
                .set('resurveyGapDataFetched', false)
                .set('resurveyDemographicFetched', true)
                .set('resurveyDataFetched', false)
                .set('resAllGapInclusionDataFetched', false);
        }
        case `${SET_SCORE_COLOR}`: {
            return state.set('scoreColors', payload);
        }
        case `${SET_BM_VERSION_IN_USE}`: {
            return state.set('benchmarkVersionInUse', payload);
        }
        case `${GET_DEFAULT_SETTINGS}_SUCCESS`: {
            return INITIAL_STATE;
        }
        case `${UPDATE_SETTINGS}_SUCCESS`: {
            return INITIAL_STATE;
        }
        case SET_DROPDOWN_VALUE: {
            return state.set('dropDownValue', payload);
        }
        case SET_RESURVEY_DROPDOWN_VALUE: {
            return state.set('resurveyDropdown', payload);
        }
        case SET_PREVIOUS_SURVEY: {
            return state
                .set('previousSurvey', payload.prevSurvey)
                .set('prevSurveyYear', payload.prevYear)
                .set('resurveyDemographicFetched', false);
        }
        case SET_DEMO_COMPARISON_SURVEY: {
            return state
                .set('demoComparisonSurvey', payload.prevSurvey)
                .set('demoComparisonYear', payload.prevYear)
                .set('resurveyDropdown', payload.resurveyDropdown)
                .set('resurveyDemographicFetched', false)
                .set('previousSurvey', '')
                .set('prevSurveyYear', '');
        }
        case `${GET_IA_BENCHMARK}_SUCCESS`: {
            const filters = state.get('filters').toJS() || [];
            const { benchmarks: incBench = [] } = filters[0];
            const benchToset = [...payload.benchmarks];
            if (benchToset.length) {
                benchToset[0].selectedOption = INCLUSION_DEFAULT_BENCHMARK;
            }
            if (incBench.length > 1) {
                return state.setIn(['filters', 0, 'benchmarks'], incBench);
            }
            return state.setIn(
                ['filters', payload.indexToUpdate, 'benchmarks'],
                [...INCLUSION_BENCHMARK, ...payload.benchmarks]
            );
        }
        case `${GET_IA_BENCHMARK}_FAILED`: {
            return state.set('error', payload.error).setIn(['filters', payload.indexToUpdate, 'benchmarks'], []);
        }
        default:
            return state;
    }
};

export default reducer;
