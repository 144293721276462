import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import { ExpandLess, ExpandMore, ArrowForward } from '@material-ui/icons';
import { cloneDeep } from 'lodash';
import { Tooltip, withStyles } from '@material-ui/core';
import { getStaticText } from '../../../utils/constants';
import BlankPage from '../BlankPage/BlankPage';
import QuickHelp from '../QuickHelp/QuickHelp';
import '../../Diagnose/PracticeBoard/index.scss';
import { matchFilterRegex } from '../../../utils/functions';

const MedianSplit = ({
    getMedian,
    medianData,
    medianDataFetched,
    filterParams,
    defaultSettings,
    error,
    pracRankingTab,
    // defaultYear,
    setMedianData,
    showNewData,
    showMedianBtn,
    getDifferentialMedian,
}) => {
    const { lang } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const {
        PRACTICES,
        PRACTICE_RANK_PAGE_TEXT,
        GSOD_SIG,
        TOP_RANKING,
        BOTTOM_RANKING,
        GSOD_DIFFERENCE,
        GSOD_RANKING,
        NOTE,
        GSOD_PRAC_ANALYSIS,
        GSOD_PRAC_NOTE,
        TOOLTIP_PAGE_TEXT,
        LEARN_MORE,
        LEARN_MORE_LINK,
        VIEW_ONLINE,
    } = SITE_TEXT || {};
    const [helpView, helpMode] = useState(false);
    const { records: mainData = [], n_size_tq = '', n_size_bq = '' } = medianData || {};
    const [sortMainData, setSortMainData] = useState([]);

    useEffect(() => {
        if (!medianDataFetched) {
            getMedian({ filters: filterParams, remove_threshold: showNewData });
        }
        // eslint-disable-next-line
    }, [medianDataFetched]);
    const keys = matchFilterRegex(filterParams);
    const keyCount = keys ? keys.length : 0;

    const LightTooltip = withStyles(theme => ({
        arrow: {
            color: theme.palette.common.black,
        },
        tooltip: {
            backgroundColor: '#333333',
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 400,
        },
    }))(Tooltip);

    useEffect(() => {
        if (showNewData && !showMedianBtn) {
            getDifferentialMedian({ filters: filterParams, remove_threshold: showNewData });
        }
        // eslint-disable-next-line
    }, [showNewData]);

    useEffect(() => {
        if (medianDataFetched) {
            if (mainData.length > 0) {
                const newItem = mainData.sort((a, b) => a.rank_tq - b.rank_tq);
                setSortMainData(newItem);
            }
        }
        // eslint-disable-next-line
    }, [medianDataFetched, sortMainData]);

    const getFilterYear = filters => {
        if (filters) {
            const regex = /year:(\d+)/;
            const match = filters.match(regex);
            return match ? +match[1] : '';
        }
        return '';
    };

    const sortData = (sort_by, sort_type) => {
        const sortObj = cloneDeep(sortMainData);
        sortObj.sort(
            (
                { rank_tq: a = '', rank_bq: x = '', diff_tq_bq: m = '' },
                { rank_tq: b = '', rank_bq: y = '', diff_tq_bq: n = '' }
            ) => {
                if (sort_by === 'tq') {
                    return sort_type === 'asc' ? a - b : b - a;
                }
                if (sort_by === 'bq') {
                    return sort_type === 'asc' ? x - y : y - x;
                }
                return sort_type === 'asc' ? m - n : n - m;
            }
        );
        setMedianData({ ...medianData, records: sortObj });
    };
    return mainData.length ? (
        <Fragment>
            <div className="graphContent buGraphContent diffAnalysisContent">
                <div className="groupSection">
                    <div className="rankingBuSection">
                        <div className="headerNav">
                            <div style={{ width: '25%' }} className="headingContent headingBuComparison analytics">
                                <div className="analysisHead">{PRACTICES}</div>
                            </div>
                            <LightTooltip title={TOOLTIP_PAGE_TEXT['3']} placement="bottom" arrow>
                                <div style={{ width: '20%', textAlign: 'left' }} className="headingContent diffHead">
                                    <div className="analysisHead">
                                        {' '}
                                        {PRACTICE_RANK_PAGE_TEXT['1']}
                                        <span className="expandLess" onClick={() => sortData('tq', 'asc')}>
                                            <ExpandLess />
                                        </span>
                                        <span className="expandMore" onClick={() => sortData('tq', 'desc')}>
                                            <ExpandMore />
                                        </span>
                                    </div>

                                    <div className="bottomHeading">
                                        {PRACTICE_RANK_PAGE_TEXT['8']}
                                        {n_size_tq}
                                    </div>
                                </div>
                            </LightTooltip>
                            <LightTooltip title={TOOLTIP_PAGE_TEXT['4']} placement="bottom" arrow>
                                <div style={{ width: '20%', textAlign: 'left' }} className="headingContent diffHead">
                                    <div className="analysisHead">
                                        {' '}
                                        {PRACTICE_RANK_PAGE_TEXT['2']}
                                        <span className="expandLess" onClick={() => sortData('bq', 'asc')}>
                                            <ExpandLess />
                                        </span>
                                        <span className="expandMore" onClick={() => sortData('bq', 'desc')}>
                                            <ExpandMore />
                                        </span>
                                    </div>
                                    <div className="bottomHeading">
                                        {PRACTICE_RANK_PAGE_TEXT['9']} {n_size_bq}
                                    </div>
                                </div>
                            </LightTooltip>

                            <div style={{ width: '35%', textAlign: 'left' }} className="headingContent">
                                <div className="analysisHead">
                                    {PRACTICE_RANK_PAGE_TEXT['3']}
                                    <span className="expandLess" onClick={() => sortData('highlight', 'asc')}>
                                        <ExpandLess />
                                    </span>
                                    <span className="expandMore" onClick={() => sortData('highlight', 'desc')}>
                                        <ExpandMore />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="mainContent">
                            {mainData.map(({ display_name, rank_tq, rank_bq, diff_tq_bq, sig_diff_tq_bq }) => {
                                const { bg: background } = GSOD_SIG[sig_diff_tq_bq];
                                const { practice_color: pracColor } = GSOD_SIG[sig_diff_tq_bq];
                                const { text: pracText } = GSOD_SIG[sig_diff_tq_bq];
                                return (
                                    <div className="practiceContent">
                                        <div
                                            style={{ width: '25%', color: pracColor }}
                                            className={c('practiceText', {
                                                pracChange: pracText === 'stronger' || pracText === 'weaker',
                                            })}
                                        >
                                            {display_name}
                                        </div>

                                        <div style={{ width: '20%', textAlign: 'center' }} className="practiceIndex">
                                            <div className="orgRanks">
                                                <span className="compRound">{rank_tq}</span>
                                            </div>
                                        </div>
                                        <div style={{ width: '20%', textAlign: 'center' }} className="practiceIndex">
                                            <div className="orgRanks">
                                                <span className="compRound">{rank_bq}</span>
                                            </div>
                                        </div>
                                        <div
                                            className="clearfix newRank"
                                            style={{ width: '35%', display: 'flex', alignItems: 'center' }}
                                        >
                                            <div
                                                className={c('rank rankContent', { negative: diff_tq_bq < 0 })}
                                                style={{ top: 0, height: '0.9vh' }}
                                            >
                                                <div
                                                    className={c('textBorder', { newEmphasised: diff_tq_bq < -10 })}
                                                    style={{
                                                        width: `${2 * Math.abs(diff_tq_bq)}%`,
                                                        background,
                                                    }}
                                                >
                                                    <div className="textRank">
                                                        {' '}
                                                        {diff_tq_bq > 0 ? `+${diff_tq_bq}` : diff_tq_bq}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            <div className="graphHighlight">
                                <div className="highLight" />
                            </div>
                        </div>
                    </div>
                </div>
                {pracRankingTab === 0 && (
                    <div className="rankingInfo">
                        <div className="sub-head">{GSOD_RANKING}:</div>
                        <div className="topRank" /> {TOP_RANKING}
                        <div className="bottomRank" /> {BOTTOM_RANKING}
                    </div>
                )}
            </div>
            <QuickHelp helpMode={helpMode} helpView={helpView} activePage="5" />
            {Object.keys(medianData).length > 0 && (
                <div className="note practiceNote">
                    <b>{NOTE}:</b>
                    <div>
                        {getFilterYear(filterParams) === 2024 ? (
                            <div>
                                <div>1. {GSOD_PRAC_NOTE[0]}</div>
                                <div>2. {GSOD_PRAC_ANALYSIS[0]}</div>
                            </div>
                        ) : (
                            GSOD_PRAC_NOTE[1]
                        )}
                    </div>
                    <div className="bottomCard quartileCard">
                        <span>
                            {LEARN_MORE}
                            <a className="arrowIcon" href={LEARN_MORE_LINK}>
                                {VIEW_ONLINE}
                                <ArrowForward />
                            </a>
                        </span>
                    </div>
                </div>
            )}
            <ul className="bottomList clearfix footNoteSticky">
                <li>{GSOD_DIFFERENCE} : </li>
                {GSOD_SIG.map(({ bg: background, title, toolTipText }) => {
                    return (
                        <>
                            {toolTipText !== '' ? (
                                <LightTooltip title={toolTipText} placement="top" arrow>
                                    <li key={title} className="bottomNote">
                                        <span style={{ background }} />
                                        {title}
                                    </li>
                                </LightTooltip>
                            ) : (
                                <li key={title}>
                                    <span style={{ background }} />
                                    {title}
                                </li>
                            )}
                        </>
                    );
                })}
            </ul>
        </Fragment>
    ) : (
        <section className="sectionWrapper">
            <BlankPage error={error} pracTab={pracRankingTab} count={keyCount} differentiateText="median" />
        </section>
    );
};

MedianSplit.propTypes = {
    filterParams: PropTypes.string.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    error: PropTypes.array.isRequired,
    pracRankingTab: PropTypes.number.isRequired,
    // defaultYear: PropTypes.number.isRequired,
    medianData: PropTypes.object.isRequired,
    medianDataFetched: PropTypes.bool.isRequired,
    getMedian: PropTypes.func.isRequired,
    setMedianData: PropTypes.func.isRequired,
    showNewData: PropTypes.bool.isRequired,
    showMedianBtn: PropTypes.bool.isRequired,
    getDifferentialMedian: PropTypes.func.isRequired,
};

export default MedianSplit;
