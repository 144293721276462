import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ArrowBack } from '@material-ui/icons';
import c from 'classnames';
import { Helmet } from 'react-helmet';

import AuthStore from '../../../common/AuthStore';
import FilterBar from '../../../components/FilterBar';
import FilterSvg from '../assets/Filter.svg';
import CheckSvg from '../assets/Check.svg';
import ActiveFilters1 from '../assets/ActiveFilters1.svg';
import Down from '../assets/Down.svg';
import colors from '../../../sass/colors';
import { getStaticText } from '../../../utils/constants';
import { getBenchmarkName, getBenchmarkString, updateFilterParams, getPptValues } from '../../../utils/functions';
import './index.scss';
import ErrorToast from '../../../components/InformationToast/ErrorToast';
import DwnldModal from '../../../components/InformationToast/DwnldModal';
import WarningDialog from './WarningDialog';
import SelectBenchmark from '../../../components/SelectBenchmark/SelectBenchmark';

function RecipeBoard({
    practiceData,
    practiceDataFetched,
    getPracticeData,
    toggleFilterView,
    apiParams,
    apiLoadingCount,
    activeFilter,
    errorInfo,
    filters,
    getPptData,
    practiceToDisplay,
    setPracticeDisplay,
    defaultSettings,
    scoreData,
    scoreDataFetched,
    getTargetScoreData,
    practiceFilters,
    getPracticeBuData,
    practiceBuData,
    practiceBuDataFetched,
    updateFilters,
    // updatePracticeBuFilters,
    resetPracticeBuDataFetched,
    appliedFiltersInfo,
    getReport,
    getRawPptData,
    reportError,
    getSinglePastReport,
    singlePastReport,
    targetBenchmarks,
    targetBm,
    updateTargetSettingBm,
}) {
    const { $white } = colors;
    const {
        // benchmark = [],
        report_name_or_client_name: rName = '',
        report_name = '',
        lang,
        quartileColors = [],
        survey_version = '',
        report_id = '',
        checkme_mapping = {},
        report_type = '',
    } = defaultSettings;
    const staticText = getStaticText(lang);
    const {
        SITE_TEXT,
        BPP_LIST,
        PRACTICE_RANKING_FILTER,
        SINGLE_PAGE_EXPORT_PARAMS,
        PRACTICE_RANKING_DESCRIPTION,
    } = staticText;
    const {
        RECIPE_HEAD,
        FILTERS,
        COMPARE_TO_RECIPE,
        RECIPE_TOP,
        UNDER_EMPHASIZED,
        HIGH_PRACTICES,
        DOWNLOAD,
        BU_HEAD,
        SOURCE_TEXT,
        BENCHMARK_HEAD,
        NUMBER_TEXT,
        NUMBER_SURVEY_TEXT,
        ALL_PPT_TEXT,
        PPT_VALUES,
        NOTE,
        PRACTICE_RANKING_NOTE,
        HEALTH_PRACTICE_RANKING,
        RECIPE_TEXT,
        SCORE_TXT,
        POINT_INCREASE_RREQUIRED_TO_ACHIVE_TOP_QUARTILE,
        NUMBER_OF_RESPONDENTS_N,
        PERCENT_FAVORABLE,
        PERCENTILES,
    } = SITE_TEXT || {};
    const [activeIndex, updateIndex] = useState(0);
    const [showBu, setBuView] = useState(false);
    const [reloadData, updateReloadState] = useState(true);
    const [reSurveyFilter, updateFilterView] = useState(false);
    const [itemsToIterate, setItemsToIterate] = useState([]);
    const [dwnld, setdwnld] = useState(false);
    const { ranking = [], nSize, n_respondents, n_survey } = practiceData;
    const { title = '', items: mainPracticeData = [] } = ranking[activeIndex] || {};
    const mainData = showBu ? practiceBuData : mainPracticeData;
    const topMainData = mainData.slice(0, 10);
    const { qbyq = {} } = scoreData;
    const { records = {} } = qbyq;
    const { ohi_score, ...dataToIterate } = records;
    const { ohid: oldId, year: oldYear, threshold } = apiParams;
    const { pptAccessToken = '' } = AuthStore;
    const { Recipe_checkme_1, Recipe_checkme_second } = PPT_VALUES({ rName });
    const isV4 = survey_version === '4';
    const [QbyqArr, setQbyqArr] = useState([]);
    const filterCheck = apiParams.filters || [];
    const [warning, setWarning] = useState(true);
    const [optionsToUse, setOptionsToUse] = useState(targetBenchmarks);
    let count = -1;
    useEffect(() => {
        const data = [];
        Object.keys(dataToIterate).forEach(innerContent => {
            const { children = {} } = records[innerContent];
            Object.keys(children).forEach(childVal => {
                const { display_name, range_start, range_end, score, quartile } = children[childVal];
                data.push({
                    key: childVal,
                    display_name,
                    range_start,
                    range_end,
                    score,
                    quartileFlag: !!(quartile === '0' || quartile === '1'),
                    quartile,
                });
            });
        });
        setQbyqArr(data.sort((a, b) => (a.display_name < b.display_name ? -1 : 1)));
        // eslint-disable-next-line
    }, [scoreData]);

    useEffect(() => {
        if (!practiceDataFetched) {
            getPracticeData({ ...apiParams, benchmarks: targetBm });
            updateReloadState(true);
        }
        // eslint-disable-next-line
    }, [practiceDataFetched]);

    useEffect(() => {
        const { ranking: oldRanking = [] } = practiceData;
        if (!scoreDataFetched && !apiLoadingCount) {
            getTargetScoreData({ ...apiParams, benchmarks: targetBm, target_setting_required: 1 });
        }
        if (oldRanking.length && reloadData && practiceDataFetched && !showBu) {
            let newActiveIndex = -1;
            if (practiceToDisplay) {
                newActiveIndex = oldRanking.findIndex(({ title: oldTitle }) => oldTitle.includes(practiceToDisplay));
            }
            const indexToUse = newActiveIndex !== -1 ? newActiveIndex : activeIndex;
            const { items = [] } = oldRanking[indexToUse];
            setItemsToIterate(items);
            updateIndex(indexToUse);
            updateReloadState(false);
        }
        if (showBu) {
            setItemsToIterate(practiceBuData);
        }
        // eslint-disable-next-line
    }, [practiceDataFetched, scoreDataFetched, apiParams, practiceData, showBu, practiceBuData]);

    const fetchBuData = newFilters => {
        const filtersArray = [];
        const { demographics = [], benchmarks: oldBench = [] } = newFilters[0];
        const { demographics: buDemo = [] } = newFilters[1] || {};
        demographics.forEach(({ options = [], code: parentCode = '' }, indexInDemo) => {
            const optionsCode = options
                .filter(({ isSelected }) => isSelected)
                .reduce((pre, { code }) => [...pre, code], []);
            const { options: buOptions = [] } = buDemo[indexInDemo] || {};
            const buOptionsCode = buOptions
                .filter(({ isSelected }) => isSelected)
                .reduce((pre, { code }) => [...pre, code], []);
            const preStr = optionsCode.length ? `${parentCode}:${optionsCode.join(',')}` : '';
            const endStr = buOptionsCode.length ? `${parentCode}:${buOptionsCode.join(',')}` : '';
            if (preStr || endStr) {
                filtersArray.push(`${preStr}||${endStr}`);
            }
        });
        getPracticeBuData({
            ohid: `${oldId},${oldId}`,
            lang: parseInt(lang, 10),
            year: oldYear,
            threshold,
            filters: filtersArray,
            benchmark_str: getBenchmarkString(oldBench),
        });
    };

    useEffect(() => {
        if (!practiceBuDataFetched && !apiLoadingCount) {
            resetPracticeBuDataFetched(true);
            fetchBuData([filters[0], { demographics: practiceFilters }]);
        }
        // eslint-disable-next-line
    }, [practiceBuDataFetched, practiceFilters, filters, apiLoadingCount]);

    // const setFilters = (newFilters, filtersApplied) => {
    //     updateFilterView(false);
    //     const { demographics = [], benchmarks: oldBench = [] } = newFilters[0];
    //     const { demographics: newBuFilters = [] } = newFilters[1];
    //     const updatedParams = updateFilterParams(demographics, oldBench);
    //     updateFilters({ filters: [newFilters[0]], filtersApplied, apiParams: { ...apiParams, ...updatedParams } });
    //     updatePracticeBuFilters(newBuFilters);
    //     setItemsToIterate([]);
    //     resetPracticeBuDataFetched(false);
    // };

    const setFiltersForTargetSetting = (newFilters, filtersApplied) => {
        updateFilterView(false);
        const { demographics = [], benchmarks: oldBench = [] } = newFilters[0];
        const updatedParams = updateFilterParams(demographics, oldBench);
        updateFilters({ filters: newFilters, filtersApplied, apiParams: { ...apiParams, ...updatedParams } });
    };

    const getRankingTitle = str => str.split('(')[1].split(')')[0];
    // Recipe Board error

    useEffect(() => {
        return () => {
            setPracticeDisplay('');
        };
        // eslint-disable-next-line
    }, []);

    const setNewData = ({ target }) => {
        const { value } = target;
        const { items = [] } = ranking[value];
        updateIndex(value);
        setItemsToIterate(items);
    };

    const renderRow = ({
        index,
        display_name,
        background,
        color,
        styleBlack,
        quartile,
        score,
        range_start,
        range_end,
        quartileFlag,
    }) => (
        <div className="tableRow" key={index}>
            <span className="recipe">{display_name}</span>
            <span className="score scoreTab">
                <span
                    className="scoreCount"
                    style={{
                        background,
                        color,
                        ...styleBlack,
                    }}
                >
                    <div className={`${quartile === '0' ? 'triangle' : ''}`} />
                    <span>{score}</span>
                </span>
            </span>
            <span className="range">
                {quartileFlag ? <img className="range-img" src={CheckSvg} alt="" /> : `${range_start}-${range_end}`}
            </span>
        </div>
    );

    const getDataForPpt = () => {
        const mTable = [];
        const BPPTable = [];
        const gTable = mainData.filter(({ value }) => value < -10).map(({ text2 }) => text2);
        const mTableList = [];
        const gTableList = [];
        const BPPList = [];

        topMainData.forEach(({ text1 }) => {
            if (!topMainData.some(({ text2: mainText }) => mainText === text1)) {
                mTable.push(text1);
            }
        });
        Object.keys(dataToIterate).forEach(innerContent => {
            const { children = {} } = records[innerContent];
            Object.keys(children).forEach(childVal => {
                const { display_name, quartile } = children[childVal];
                if (BPP_LIST[childVal] && quartile === '4') {
                    BPPTable.push(display_name);
                }
            });
        });

        mTable.forEach((mTableEle, row_index) => {
            const is_bold = gTable.includes(mTableEle);
            mTableList.push(
                {
                    row_index,
                    column_index: 0,
                    is_bold,
                    value: '*',
                },
                {
                    row_index,
                    column_index: 1,
                    is_bold,
                    value: mTableEle,
                }
            );
        });
        gTable.forEach((gTableEle, row_index) => {
            const is_bold = mTable.includes(gTableEle);
            gTableList.push(
                {
                    row_index,
                    column_index: 0,
                    is_bold,
                    value: '*',
                },
                {
                    row_index,
                    column_index: 1,
                    is_bold,
                    value: gTableEle,
                }
            );
        });
        BPPTable.forEach((BPPEle, row_index) => {
            BPPList.push(
                {
                    row_index,
                    column_index: 0,
                    is_bold: true,
                    value: '*',
                },
                {
                    row_index,
                    column_index: 1,
                    is_bold: true,
                    value: BPPEle,
                }
            );
        });
        const mTableListLen = mTable.length;
        const gTableListLen = gTable.length;
        const BPPTListLen = BPPTable.length;
        const { benchmarks = [] } = filters[0];
        const label = getBenchmarkName(benchmarks);
        const commonEle = [
            {
                name: 'source',
                value: `${SOURCE_TEXT} ${rName} (${NUMBER_TEXT}=${nSize}); ${BENCHMARK_HEAD}: ${label} (${NUMBER_TEXT}=${n_respondents}, ${NUMBER_SURVEY_TEXT}=${n_survey})`,
            },
            {
                name: 'Checkme',
                is_delete: 'true',
            },
        ];
        const pptArray = [
            {
                name: 'Title_text',
                value: title,
            },
            {
                name: 'companyshape1',
                value: rName,
            },
            {
                name: 'txttopc',
                value: HIGH_PRACTICES,
            },
            {
                name: 'txttopa',
                value: RECIPE_TOP,
            },
            {
                name: 'txttopb',
                value: COMPARE_TO_RECIPE,
            },
            {
                name: 'txt3rd',
                value: UNDER_EMPHASIZED,
            },
            ...getPptValues(Recipe_checkme_1),
        ];
        const recipeTopPratice = [];
        const clientTopPratice = [];
        const chart_data = [];

        mainData.forEach(({ text1 }, row_index) => {
            const is_bold = row_index < 10 ? 'true' : 'false';
            recipeTopPratice.push(
                {
                    row_index,
                    column_index: 0,
                    is_bold,
                    value: row_index + 1,
                },
                {
                    row_index,
                    column_index: 1,
                    is_bold,
                    value: text1,
                }
            );
        });
        itemsToIterate.forEach(({ text2, index, value }, row_index) => {
            const is_bold = topMainData.some(({ text1: mainText }) => mainText === text2) ? 'true' : 'false';
            const bgcolor = value < -10 ? colors.$red300 : colors.$grey700;
            clientTopPratice.push(
                {
                    row_index,
                    column_index: 0,
                    is_bold,
                    value: index,
                },
                {
                    row_index,
                    column_index: 1,
                    is_bold,
                    value: text2,
                }
            );
            chart_data.push({
                index: row_index,
                value,
                bgcolor,
            });
        });
        const output = {
            template_file_name: 'Template_All.pptx',
            output_file_name: 'practice.pptx',
            slides: [
                {
                    identify_shape_name: 'Checkme',
                    identify_shape_value: 'Recipe_checkme_1',
                    is_copy: true,
                    is_delete: false,
                    data: [
                        ...commonEle,
                        ...pptArray,
                        {
                            name: 'recipeTopPratice_table',
                            is_delete: 'false',
                            table_data: recipeTopPratice,
                        },
                        {
                            name: 'ClientTopPratice_table',
                            is_delete: 'false',
                            table_data: clientTopPratice,
                        },
                        {
                            name: 'DifferenceCompanyRecipe_chart',
                            is_delete: 'false',
                            chart_data,
                        },
                    ],
                },
                {
                    identify_shape_name: 'Checkme',
                    identify_shape_value: 'Recipe_checkme_1',
                    is_delete: true,
                },
                {
                    identify_shape_name: 'Checkme',
                    identify_shape_value: 'Recipe_checkme_second',
                    is_copy: true,
                    is_delete: false,
                    data: [
                        ...commonEle,
                        ...getPptValues(Recipe_checkme_second),
                        {
                            name: 'Title_text',
                            value: `${ALL_PPT_TEXT[0]} ${title.substring(
                                title.lastIndexOf('(') + 1,
                                title.lastIndexOf(')')
                            )}`,
                        },
                        {
                            name: 'M_List',
                            is_delete: !!mTableListLen,
                            value: mTableListLen ? '' : ALL_PPT_TEXT[1],
                        },
                        {
                            name: 'M_Table',
                            is_delete: !mTableListLen,
                            table_data: mTableList,
                        },
                        {
                            name: 'BPP_List',
                            is_delete: !!BPPTListLen,
                            value: BPPTListLen ? '' : ALL_PPT_TEXT[2],
                        },
                        {
                            name: 'BPP_Table',
                            is_delete: !BPPTListLen,
                            table_data: BPPList,
                        },
                        {
                            name: 'G_List',
                            is_delete: !!gTableListLen,
                            value: gTableListLen ? '' : ALL_PPT_TEXT[3],
                        },
                        {
                            name: 'G_Table',
                            is_delete: !gTableListLen,
                            table_data: gTableList,
                        },
                    ],
                },
                {
                    identify_shape_name: 'Checkme',
                    identify_shape_value: 'Recipe_checkme_second',
                    is_delete: true,
                },
            ],
        };
        getPptData(output, pptAccessToken, `${report_name} Practice_Recipe_${activeIndex + 1}`);
    };

    const v4PptDownload = () => {
        const { benchmarks = [] } = filters[0];
        const label = getBenchmarkName(benchmarks);
        const valueToSend = {
            ...SINGLE_PAGE_EXPORT_PARAMS({ BENCHMARK_NAME: label }),
            report_name: rName,
            ...apiParams,
            benchmarks: targetBm,
            report_id,
            single_page_meta: {
                checkme: [checkme_mapping.practice_ranking[1]],
                name: 'practice_ranking',
            },
        };
        setdwnld(true);
        getReport(valueToSend);
    };

    return (
        <>
            <div className="recipeBoard clearfix">
                <Helmet>
                    <title>{HEALTH_PRACTICE_RANKING}</title>
                </Helmet>
                {showBu && (
                    <div className="breadCrumb">
                        <span
                            data-testid="setScoreFromBuBtn"
                            onClick={() => {
                                setBuView(false);
                                updateReloadState(true);
                            }}
                        >
                            {RECIPE_HEAD}
                        </span>
                        / {BU_HEAD}
                    </div>
                )}
                <div className="rightScorecardHeader">
                    {showBu ? (
                        <span
                            data-testid="setBackFromBuBtn"
                            onClick={() => {
                                setBuView(false);
                                updateReloadState(true);
                            }}
                        >
                            <ArrowBack />
                            {BU_HEAD}
                        </span>
                    ) : (
                        <div className="scorecardHeading">
                            <div className="mainHeading">{RECIPE_HEAD}</div>
                            <span>
                                <img src={WarningDialog} alt="" />
                            </span>
                            <div className="nRes">
                                {NUMBER_OF_RESPONDENTS_N} : <span>{nSize ? nSize.toLocaleString('en-US') : ''}</span>
                            </div>
                        </div>
                    )}
                    <ul className="navScoreList">
                        <SelectBenchmark
                            benchmarks={targetBenchmarks}
                            updateTargetSettingBm={updateTargetSettingBm}
                            targetBm={targetBm}
                            optionsToUse={optionsToUse}
                            setOptionsToUse={setOptionsToUse}
                            updateOnSelect
                        />
                        {report_type !== 'percentile' ? (
                            <li
                                data-testid="getPptBtn"
                                onClick={isV4 ? v4PptDownload : getDataForPpt}
                                className="resurveyImg"
                            >
                                <img src={Down} alt="" />
                                <span className="lineHead upperHead">{DOWNLOAD}</span>
                            </li>
                        ) : null}
                        <li
                            onClick={() => {
                                toggleFilterView(true);
                                // if (showBu) {
                                //     updateFilterView(true);
                                // } else {
                                //     toggleFilterView(true);
                                // }
                            }}
                            data-testid="filterButton"
                            className="filterBtn"
                        >
                            <img
                                className="imgView"
                                src={activeFilter && filterCheck.length > 0 ? ActiveFilters1 : FilterSvg}
                                alt=""
                            />
                            <span className="filter">{FILTERS}</span>
                        </li>
                    </ul>
                </div>
                {appliedFiltersInfo('', [], true)}
                {errorInfo && <ErrorToast message={errorInfo} />}
                {dwnld && !reportError && (
                    <DwnldModal
                        getRawPptData={getRawPptData}
                        reportId={report_id}
                        open={dwnld}
                        setOpen={setdwnld}
                        getSinglePastReport={getSinglePastReport}
                        singlePastReport={singlePastReport}
                        defaultSettings={defaultSettings}
                    />
                )}

                {mainData.length && isV4 ? (
                    <section className="isV4">
                        <section className="topBoard">
                            <div className="leftOptions">
                                <div style={{ display: 'flex' }}>
                                    <div className="active" />
                                    {ranking.map(({ title: selectOption }, menuIndex) => (
                                        <div
                                            className={`tab ${activeIndex === menuIndex ? 'active' : ''}`}
                                            key={selectOption}
                                            onClick={() => {
                                                setNewData({ target: { value: menuIndex } });
                                            }}
                                        >
                                            {getRankingTitle(selectOption)}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="description">{PRACTICE_RANKING_DESCRIPTION[title]}</div>
                        </section>
                        <div
                            style={{ display: 'block', justifyContent: 'space-between' }}
                            className="leftHead leftSection"
                        >
                            <div
                                style={{ width: '100%' }}
                                className={c({ percentileReport: report_type === 'percentile' }, 'PracticeRankTable')}
                            >
                                <div className="tableHeading">
                                    <span className="recipe"> {RECIPE_TEXT}</span>
                                    <div className="score">{SCORE_TXT}</div>
                                    <span className="range">{POINT_INCREASE_RREQUIRED_TO_ACHIVE_TOP_QUARTILE}</span>
                                </div>

                                {QbyqArr.map(
                                    ({ display_name, key, score, range_end, range_start, quartileFlag, quartile }) => {
                                        const { color, background = $white } = quartileColors[quartile] || {};
                                        const styleBlack =
                                            quartile === ('' || 999) ? { border: '2px solid #000000' } : {};
                                        const arr = PRACTICE_RANKING_FILTER[activeIndex];
                                        if (arr.includes(key)) {
                                            count += 1;
                                            return renderRow({
                                                index: count,
                                                display_name,
                                                background,
                                                color,
                                                styleBlack,
                                                quartile,
                                                score,
                                                range_start,
                                                range_end,
                                                quartileFlag,
                                            });
                                        }

                                        return null;
                                    }
                                )}
                            </div>
                        </div>

                        <div className="noteInfo">
                            <span className="sub-heading">{NOTE}:</span>
                            <br />
                            1. {PRACTICE_RANKING_NOTE}
                            <br />
                            {report_type === 'percent favorable' && <div>2. {PERCENT_FAVORABLE}</div>}
                            {report_type === 'percentile' && <div>2. {PERCENTILES}</div>}
                        </div>

                        {/* <ul className="benchmark clearfix fixedScoreCard">
                            <li>{BENCHMARK_HEAD} : </li>
                            {quartileColors
                                .slice()
                                .reverse()
                                .map(({ title: label = '', background, border: borderColor }) => {
                                    const bottomDecileVar = label === 'Bottom decile';
                                    const BenchmarkTitle = label === 'No benchmarks';
                                    return (
                                        <li
                                            key={label}
                                            style={{ color: background }}
                                            className={c({
                                                hide: bottomDecileVar || BenchmarkTitle,
                                            })}
                                        >
                                            <span
                                                className="benchmarkTriangle"
                                                style={{
                                                    background,
                                                    borderWidth: '13px 13px 0 0',
                                                    borderColor: bottomDecileVar
                                                        ? `transparent ${borderColor} transparent transparent`
                                                        : `${borderColor} transparent transparent transparent`,
                                                    borderStyle: 'solid',
                                                }}
                                            />
                                            <div style={{ color: 'black' }}>{label}</div>
                                        </li>
                                    );
                                })}
                        </ul> */}
                    </section>
                ) : null}

                {warning && mainData.length ? (
                    <WarningDialog
                        defaultSettings={defaultSettings}
                        setView={setWarning}
                        benchmarks={targetBenchmarks}
                        updateTargetSettingBm={updateTargetSettingBm}
                        targetBm={targetBm}
                        optionsToUse={optionsToUse}
                        setOptionsToUse={setOptionsToUse}
                    />
                ) : null}

                {/* {reSurveyFilter && (
                <FilterBar
                    activeResetFilter={activeFilter}
                    size={[nSize, 0]}
                    surveyRespondents={[n_respondents, 0]}
                    surveys={[n_survey, 0]}
                    filters={[filters[0], { demographics: practiceFilters }]}
                    projectInFilter={BU_PROJECTS}
                    currentView={6}
                    showBenchmarks={false}
                    closeFilters={updateFilterView}
                    setFilters={setFilters}
                    defaultBenchmark={benchmark}
                    defaultSettings={defaultSettings}
                />
            )} */}
                {reSurveyFilter && (
                    <FilterBar
                        activeResetFilter={activeFilter}
                        size={[nSize, 0]}
                        surveyRespondents={[n_respondents, 0]}
                        surveys={[n_survey, 0]}
                        filters={filters}
                        showBenchmarks
                        closeFilters={updateFilterView}
                        setFilters={setFiltersForTargetSetting}
                        defaultBenchmark={[targetBm]}
                        defaultSettings={defaultSettings}
                        isEEpage={false}
                        targetBenchmarks={targetBenchmarks}
                        updateTargetSettingBm={updateTargetSettingBm}
                    />
                )}
            </div>

            <ul className="benchmark clearfix fixedScoreCard">
                <li>{BENCHMARK_HEAD} : </li>
                {quartileColors
                    .slice()
                    .reverse()
                    .map(({ title: label = '', background, border: borderColor }) => {
                        const bottomDecileVar = label === 'Bottom decile';
                        const BenchmarkTitle = label === 'No benchmarks';
                        return (
                            <li
                                key={label}
                                style={{ color: background }}
                                className={c({
                                    hide: bottomDecileVar || BenchmarkTitle,
                                })}
                            >
                                <span
                                    className="benchmarkTriangle"
                                    style={{
                                        background,
                                        borderWidth: '13px 13px 0 0',
                                        borderColor: bottomDecileVar
                                            ? `transparent ${borderColor} transparent transparent`
                                            : `${borderColor} transparent transparent transparent`,
                                        borderStyle: 'solid',
                                    }}
                                />
                                <div style={{ color: 'black' }}>{label}</div>
                            </li>
                        );
                    })}
            </ul>
        </>
    );
}

RecipeBoard.propTypes = {
    filters: PropTypes.array.isRequired,
    errorInfo: PropTypes.string.isRequired,
    practiceToDisplay: PropTypes.string.isRequired,
    apiParams: PropTypes.object.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    practiceData: PropTypes.object.isRequired,
    activeFilter: PropTypes.bool.isRequired,
    practiceDataFetched: PropTypes.bool.isRequired,
    practiceBuDataFetched: PropTypes.bool.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    scoreDataFetched: PropTypes.bool.isRequired,
    practiceFilters: PropTypes.array.isRequired,
    practiceBuData: PropTypes.array.isRequired,
    scoreData: PropTypes.object.isRequired,
    getTargetScoreData: PropTypes.func.isRequired,
    getPracticeData: PropTypes.func.isRequired,
    getPracticeBuData: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    getPptData: PropTypes.func.isRequired,
    updateFilters: PropTypes.func.isRequired,
    // updatePracticeBuFilters: PropTypes.func.isRequired,
    setPracticeDisplay: PropTypes.func.isRequired,
    resetPracticeBuDataFetched: PropTypes.func.isRequired,
    appliedFiltersInfo: PropTypes.func.isRequired,
    getRawPptData: PropTypes.func.isRequired,
    getReport: PropTypes.func.isRequired,
    reportError: PropTypes.string.isRequired,
    getSinglePastReport: PropTypes.func.isRequired,
    singlePastReport: PropTypes.object.isRequired,
    targetBenchmarks: PropTypes.array.isRequired,
    targetBm: PropTypes.string.isRequired,
    updateTargetSettingBm: PropTypes.func.isRequired,
};

export default RecipeBoard;
