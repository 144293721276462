import React, { Fragment, useEffect, useState } from 'react';
import c from 'classnames';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { Helmet } from 'react-helmet';

import { Tooltip } from '@material-ui/core';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';
import CompareView from '../../Diagnose/assets/CompareView.svg';
import Close from '../../../assets/Close.svg';
import Loader from '../../../assets/Loader_Blue.svg';
import ClickThrusModal from '../ClickThrusModal';
import {
    PPT_PARAMS,
    PPT_RES_PARAMS,
    DEFAULT_STYLE,
    CRH_CLIENT_OHI12922,
    PPT_PARAMS_CRH_OHI14649,
    PPT_RES_PARAMS_CRH_OHI14649,
} from '../constants';
import { getStaticText, IA_NON_COMPARABLE_PRACTICES } from '../../../utils/constants';
import {
    getColor,
    getGapColor,
    sortChildren,
    getInclusionStyle,
    excludeFromGapText,
    getBenchmarkColor,
    getPptParamsForLegend,
    getExcludeGapString,
    gapToDisplay,
    getFiltersAppliedText,
    deltaToDisplay,
    getFiltersText,
    quartileDelta,
    getDeltaColor,
    getIncBenchmarkStyles,
    getPptStyles,
} from '../../../utils/functions';
import AuthStore from '../../../common/AuthStore';
import DownloadIcon from '../../Diagnose/assets/Download.svg';
import FilterSvg from '../../Diagnose/assets/Filter.svg';
import ActiveFilter from '../../Diagnose/assets/ActiveFilter.svg';
import Peers from '../assets/Peers.svg';
import Leaders from '../assets/Leaders.svg';
import Systems from '../assets/Systems.svg';
import './index.scss';
import ScorecardAlt from './ScorecardAlt';
import ErrorToast from '../../../components/InformationToast/ErrorToast';

function Scorecard({
    inclusionqbyq,
    toggleFilterView,
    activeFilter,
    getPptData,
    allGapData,
    defaultSettings,
    currentOhid,
    lang,
    inclusion_threshold,
    demographics,
    filters,
    scoreColor,
    iaApiParams,
    reportApiParams,
    resurveyData,
    resurveyFilters,
    resurveyDemographic,
    resurveyAllGapDataFetched,
    resurveyAllGapData,
    project,
    isPulse,
    benchmarkName,
    resurveyDataFetched,
    allGapDataFetched,
    bmVersionInUse,
    benchmarkVersions,
    prevYear,
    showGapToggle,
    setShowGapToggle,
    showResurvey,
    setResurvey,
    setModalType,
    prevSurvey,
    deleteResurveyMapping,
    getInclusionDataResurvey,
    getResurveyAllGapData,
    resurveyDemoFetched,
    getDemographics,
    resurveyInactive,
    isDiffSurveyV,
    isDiffSurveyV2,
}) {
    const staticText = getStaticText();
    const { SITE_TEXT, INCLUSION_DEFAULT_BENCHMARK_STR } = staticText;
    const {
        SCORECARD_DISCLAIMER,
        DISCLAIMER,
        INCLUSION_SCORECARD,
        SCORE_CLASSIFICATION_TEXT,
        GAP_CLASSIFICATION_TEXT,
        OUTCOME_SCORE_GAPS,
        PRACTICE_SCORE_GAPS,
        FILTERS,
        PEERS,
        LEADERS,
        DOWNLOAD,
        SYSTEMS,
        NUMBER_TEXT,
        FILTER_HOVER_TXT,
        SCORECARD_TABS,
        // CHANGE_CLASSIFICATION,
        FILTERS_APPLIED,
        BENCHMARKS_APPLIED,
        // NUMBER_CAP_SURVEY_TEXT,
        // NUMBER_CAP_TEXT,
        VIEW_BY,
        AVG_GAP,
        LARGEST_GAP,
        OVERALL_INCLUSION,
        RESURVEY_TEXT,
        RESURVEY_INACTIVE_TEXT,
        NONE,
        SIGNIFICANCE,
        QUARTILE_MOVE,
        SHOW_OVERALL,
        QUARTILE_DIFFERENCE,
        VALUE_BASED_DIFFERENCE,
        GAP_BASED_DIFFERENCE,
        QUARTILE_MOVE_LEGEND_TXT_IA,
        GAP_LEGEND_TEXT,
        // NA_BG,
        // SCORECARD_RES_DISCLAIMER,
        // VALUES_BASED_LEGEND_TEXT,
        SCORECARD_RESURVEY_VALUE_BASED,
        SCORECARD_RESURVEY_QUARTILE_BASED,
        SCORECARD_RESURVEY_VALUE_BASED_V2,
        SCORECARD_RESURVEY_QUARTILE_BASED_V2,
        RES_FOOT_ARR,
        IA_OLD_DISCLAIMER,
    } = SITE_TEXT;
    const [clickThrusMeta, updateModal] = useState(null);
    const {
        report_name_or_client_name: rName = '',
        exclude_inclusion_gap: defaultExGap = '',
        year = '',
        inclusion_resurvey_settings = '',
        ohid = '',
        quartileColors = [],
        inclusion_old_overall_calc = false,
    } = defaultSettings || {};
    const isCRHClientOHI12922 = currentOhid === CRH_CLIENT_OHI12922;
    const isCRH_OHI14649 = currentOhid === 'OHI14649';
    const { exclude_inclusion_gap: exGap = '' } = reportApiParams;
    const { with_benchmark: withBenchmark = false, benchmarks = '' } = iaApiParams;
    const [selectedTab, setSelectedTab] = useState(0);
    const [qbyqData, setQbyqData] = useState({});
    const [resQbyqData, setResQbyqData] = useState({});
    const { qbyq = {}, message = '' } = inclusionqbyq;
    const { qbyq: resurveyQbyq = {} } = resurveyData;
    const { records: prevRecords = {}, size: prev_size = 0, level_scores: resurvey_level_score } = resurveyQbyq || {};
    const { level_scores: levels_score = {}, records = {}, size = 0, inclusion_survey_version: surveyV = '' } = qbyq;
    const { enterprise = {}, individual = {}, overall = {} } = levels_score;
    const { enterprise: res_enterprise = {}, individual: res_individual = {}, overall: res_overall = {} } =
        resurvey_level_score || {};
    const checkLength = Object.keys(qbyq).length;
    const { gap = {}, levels_gap = {} } = allGapData;
    const { gap: resurveyGap = {}, levels_gap: prev_levels_gap = {} } = resurveyAllGapData || {};
    const checkGapLength = Object.keys(gap).length;
    const [listStyle, setListStyle] = useState(-1);
    const [parentGap, setParentGap] = useState('');
    const [overallView, setOverallView] = useState(true);
    // const selectedBm = benchmarkVersions.filter(
    //     ({ BenchmarkPreCalculationSubVersionId }) => BenchmarkPreCalculationSubVersionId === bmVersionInUse
    // )[0];
    // const { BenchmarkPreCalculationSubVersionName: bmName = '', SurveyCount = '', RespondentCount = '' } =
    //     selectedBm || {};
    // const benchmarkText = `${NUMBER_CAP_TEXT} = ${RespondentCount} | ${NUMBER_CAP_SURVEY_TEXT} : ${SurveyCount} | ${bmName}`;
    const initialValue = {
        lowScore: 0,
        highScore: 100,
        lowGap: 0,
        highGap: 100,
        gapHighlight: {},
        styleFade: {},
    };
    const [classification, setClassification] = useState(initialValue);
    const [benchmarkColor, setBenchmarkColor] = useState('');
    const {
        score: scoreStyles = [],
        gap: gapStyles = [],
        styles: styleColors = [],
        // benchmarkStyles = [],
        pptBenchmarkStyles = [],
        deltaStyles = [],
        gapDeltaStyles = [],
    } = scoreColor;
    const benchmarkStyles = getIncBenchmarkStyles(quartileColors);
    const styleToUse = !withBenchmark ? scoreStyles : benchmarkStyles;
    const pptStyle = getPptStyles(withBenchmark, styleColors, pptBenchmarkStyles, quartileColors);
    const pptParamsCRH = selectedTab === 1 ? PPT_RES_PARAMS_CRH_OHI14649 : PPT_PARAMS_CRH_OHI14649;
    const commonParamsToUse = selectedTab === 1 ? PPT_RES_PARAMS : PPT_PARAMS;
    const pptParams = isCRH_OHI14649 ? pptParamsCRH : commonParamsToUse;
    const isQuartileMovement = inclusion_resurvey_settings.toLowerCase() === 'quartile movement';
    const isValueBased = inclusion_resurvey_settings.toLowerCase() === 'value based';
    const getCounter = child => {
        const newObj = {};
        Object.keys(child).forEach(val => {
            const { level: subLevel = '' } = child[val];
            if (newObj[subLevel]) {
                newObj[subLevel] += 1;
            } else {
                newObj[subLevel] = 1;
            }
        });
        return Math.max(...Object.values(newObj));
    };

    const resurveyNote = ohid === 'OHI12922';
    useEffect(() => {
        if (isCRHClientOHI12922 === true) {
            setOverallView(false);
        }
    }, [isCRHClientOHI12922]);

    const renderData = (data, setData) => {
        const obj = {};
        sortChildren(data).forEach(mainInfo => {
            const { level = '', children = {}, key } = mainInfo;
            if (level && level !== '-') {
                const objToAdd = {
                    ...mainInfo,
                    count: Object.keys(children).length === 1 ? 1 : getCounter(children),
                    children: sortChildren(children),
                    key,
                };
                if (obj[level]) {
                    obj[level].push(objToAdd);
                } else {
                    obj[level] = [objToAdd];
                }
            }
        });
        setData(obj);
    };

    const showDelta = selectedTab === 1 && resurveyDataFetched;

    useEffect(() => {
        if (!isEqual(records, qbyqData)) {
            renderData(records, setQbyqData);
        }
        if (resurveyAllGapDataFetched) {
            renderData(prevRecords, setResQbyqData);
        }
        if (resurveyDataFetched) {
            renderData(prevRecords, setResQbyqData);
            setModalType(2);
            setSelectedTab(1);
        } else {
            setModalType(1);
        }
        // eslint-disable-next-line
    }, [levels_score, resurveyAllGapDataFetched]);

    useEffect(() => {
        if (prevSurvey) {
            if (!resurveyDataFetched) {
                if (!resurveyDemoFetched) {
                    getDemographics({ ohid: prevSurvey, lang }, 1);
                }
                deleteResurveyMapping({});
                getInclusionDataResurvey({
                    ohid: prevSurvey,
                    filters: resurveyFilters,
                    inclusion_threshold,
                    with_benchmark: benchmarks !== '-1:-1',
                    benchmarks: INCLUSION_DEFAULT_BENCHMARK_STR,
                    pid: '',
                    // benchmark_sub_version_id: bmVersionInUse,
                });
                getResurveyAllGapData({
                    ohid: prevSurvey,
                    filters: resurveyFilters,
                    lang,
                    inclusion_threshold,
                    exclude_from_gap: '',
                });
                setResurvey(false);
                setModalType(2);
            }
        }
        // eslint-disable-next-line
    }, [resurveyDataFetched, prevSurvey]);

    const selectionView = (higherLimit, lowerLimit, type) => {
        const greyBg = { backgroundColor: 'rgb(235, 235, 235)' };
        if (type === 'score') {
            setClassification({
                ...initialValue,
                lowScore: lowerLimit,
                highScore: higherLimit,
                styleFade: greyBg,
            });
        } else {
            setBenchmarkColor('');
            setClassification({
                ...initialValue,
                lowGap: lowerLimit,
                highGap: higherLimit,
                gapHighlight: greyBg,
            });
        }
    };

    const setViewOption = (value, type) => {
        setClassification(initialValue);
        const { lowScore, highScore, lowGap, highGap } = classification;
        switch (value) {
            case scoreStyles[0].title: {
                if (!(lowScore === 80 && highScore === 100)) selectionView(100, 80, type);
                break;
            }
            case scoreStyles[1].title: {
                if (!(lowScore === 70 && highScore === 79)) selectionView(79, 70, type);
                break;
            }
            case scoreStyles[2].title: {
                if (!(lowScore === 60 && highScore === 69)) selectionView(69, 60, type);
                break;
            }
            case scoreStyles[3].title: {
                if (!(lowScore === 50 && highScore === 59)) selectionView(59, 50, type);
                break;
            }
            case scoreStyles[4].title: {
                if (!(lowScore === 0 && highScore === 50)) selectionView(50, 0, type);
                break;
            }
            case gapStyles[0].title: {
                if (!(lowGap === 0 && highGap === 10)) selectionView(10, 0, type);
                break;
            }
            case gapStyles[1].title: {
                if (!(lowGap === 10 && highGap === 15)) selectionView(15, 10, type);
                break;
            }
            case gapStyles[2].title: {
                if (!(lowGap === 15 && highGap === 100)) selectionView(100, 15, type);
                break;
            }
            default:
                setClassification(initialValue);
        }
    };

    const setModalData = (
        isLevel,
        parentKey,
        childKey = '',
        score = '',
        { gap: gapModal = '' },
        meta_scores = [],
        data = {}
    ) => {
        updateModal({ isLevel, parentKey, childKey, score, gap: gapModal, meta_scores, data });
    };

    const getDeltaIndex = val => {
        const i = val > 0 ? 0 : 1;
        const index = val === 0 ? 2 : i;
        return index;
    };

    const getDeltaElement = (value, type, quartileDel, noBg) => {
        const valueToUse = quartileDel !== -100 ? quartileDel : value;
        const styleArr = type === 'gap' ? gapDeltaStyles : deltaStyles;
        const isNoComparison = isQuartileMovement && quartileDel === -100;
        if (value === '-' || noBg || isNoComparison) {
            return (
                <div
                    className="delta quartileBased"
                    style={{
                        color: '#000000',
                        background: '#ffffff',
                        border: isNoComparison ? '1px solid #000' : 'none',
                    }}
                >
                    <div>{value}</div>
                </div>
            );
        }

        if (type === 'gap') {
            const index = getDeltaIndex(value);
            const { text_color } = styleArr[index];
            return (
                <div className="gap delta" style={{ color: text_color }}>
                    <div>
                        {value > 0 ? (
                            <div>
                                <span>{value}</span>
                                <span>
                                    <ArrowUpward />
                                </span>
                            </div>
                        ) : (
                            <div>
                                <span>{value}</span>
                                {value < 0 ? (
                                    <span>
                                        <ArrowDownward />
                                    </span>
                                ) : null}
                            </div>
                        )}
                    </div>
                </div>
            );
        }

        const index = getDeltaIndex(valueToUse);
        const { bgcolor } = styleArr[index];

        return (
            <div className="delta quartileBased" style={{ background: bgcolor, color: '#ffffff' }}>
                <div>
                    <div>
                        <span>{value}</span>
                    </div>
                </div>
            </div>
        );
    };

    // const getDeltaElement = (value, type, quartileDel, noBg) => {
    //     const valueToUse = quartileDel !== -100 ? quartileDel : value;
    //     let textColor = valueToUse > 0 ? SIGNIFICANCE[1].bg : SIGNIFICANCE[0].bg;
    //     if (type === 'gap') {
    //         textColor = value < 0 ? SIGNIFICANCE[1].bg : SIGNIFICANCE[0].bg;
    //         if (!resurveyAllGapDataFetched) {
    //             return (
    //                 <div className="gap delta">
    //                     <span>
    //                         <div className="spinLoader">
    //                             <img src={Loader} alt="" />
    //                         </div>
    //                     </span>
    //                 </div>
    //             );
    //         }
    //     }
    //     const valueBasedStyle = type === 'gap';
    //     const neutralStyle = { color: value === 0 ? SIGNIFICANCE[2].bg : '#000000' };
    //     let deltaStyle = valueToUse > 0 ? SIGNIFICANCE[1] : SIGNIFICANCE[0];
    //     deltaStyle = valueToUse === 0 ? SIGNIFICANCE[2] : deltaStyle;
    //     deltaStyle = noBg ? NA_BG : deltaStyle;
    //     const { bg, text_color, border = '' } = deltaStyle;
    //     const style =
    //         value !== '-' ? { background: bg, color: text_color, border: noBg ? `1px solid ${border}` : 'none' } : null;
    //     if (value === 0 || value === '-' || noBg) {
    //         return (
    //             <div
    //                 className={c({ gap: type === 'gap', quartileBased: !valueBasedStyle }, 'delta')}
    //                 style={!valueBasedStyle ? style : null}
    //             >
    //                 <div style={valueBasedStyle ? neutralStyle : style}>{value}</div>
    //             </div>
    //         );
    //     }

    //     return (
    //         <div
    //             className={c({ gap: type === 'gap', quartileBased: !valueBasedStyle }, 'delta')}
    //             style={valueBasedStyle ? { color: textColor } : style}
    //         >
    //             <div>
    //                 {value > 0 ? (
    //                     <div>
    //                         <span>{value}</span>
    //                         {valueBasedStyle && (
    //                             <span>
    //                                 <ArrowUpward />
    //                             </span>
    //                         )}
    //                     </div>
    //                 ) : (
    //                     <div>
    //                         <span>{value}</span>
    //                         {valueBasedStyle && (
    //                             <span>
    //                                 <ArrowDownward />
    //                             </span>
    //                         )}
    //                     </div>
    //                 )}
    //             </div>
    //         </div>
    //     );
    // };

    const renderGap = (gapDelta, delta) => {
        if (showDelta) {
            if (!allGapDataFetched) {
                return (
                    <div className="gap delta">
                        <span>
                            <div className="spinLoader">
                                <img src={Loader} alt="" />
                            </div>
                        </span>
                    </div>
                );
            }
            return getDeltaElement(gapDelta, 'gap', delta === '-');
        }
        return null;
    };

    const renderOverall = (overallGaps, overallScore) => {
        const { display_score: allScore = '', inclusion_quartile = -1, quartile = -1 } = overallScore;

        const { bgcolor: scorebgColor = '', text_color: scoretextColor = '', border_color: scoreborderColor = '' } =
            allScore !== '-'
                ? getInclusionStyle(inclusion_quartile, quartile, benchmarkStyles, scoreStyles)
                : DEFAULT_STYLE;

        const { overall: overallGap = '' } = overallGaps || {};
        const { overall: prevOverallGap = '' } = prev_levels_gap || {};
        const { display_score: allScorePrev = '', inclusion_quartile: resIncQuart = '', quartile: resQuart = '' } =
            res_overall || {};
        const { gap: scoreGap = '', avg_gap: averageGap = '', demographic: demographicOverall = '' } = overallGap;
        const { gap: scoreGapRes = '', avg_gap: averageGapRes = '' } = prevOverallGap || {};
        const { label: overallLabel = '' } = demographics.filter(({ code }) => code === demographicOverall)[0] || {};

        const delta = deltaToDisplay(allScore, allScorePrev, allScore);
        const gapDelta = deltaToDisplay(
            showGapToggle ? averageGap : scoreGap,
            showGapToggle ? averageGapRes : scoreGapRes,
            allScorePrev,
            true
        );
        const quartDelta = isQuartileMovement
            ? quartileDelta(inclusion_quartile, quartile, resIncQuart, resQuart)
            : -100;
        const overallAllGap = showGapToggle ? averageGap : scoreGap;

        const {
            bgcolor: overallbgColor = '',
            text_color: overallGapColor = '',
            border_color: overallBorderColor = '',
        } =
            overallAllGap !== '-'
                ? getGapColor(gapToDisplay(overallAllGap, allScore, isPulse), gapStyles)
                : DEFAULT_STYLE;
        return (
            <div className={c({ resurvey: showDelta })}>
                <div className="overallHeading">
                    <div>{OVERALL_INCLUSION}</div>
                </div>
                {showDelta ? getDeltaElement(delta, 'score', quartDelta) : null}
                <div
                    style={{ background: scorebgColor, border: `0.5px solid ${scoreborderColor}` }}
                    className="allScoreCircle"
                >
                    <div style={{ color: scoretextColor }}>{allScore}</div>
                </div>

                {allGapDataFetched ? (
                    <div
                        className="allGapOverall"
                        style={{
                            borderRadius: '20%',
                            background: overallbgColor,
                            border: `0.5px solid ${overallBorderColor}`,
                        }}
                    >
                        {showGapToggle ? (
                            <div style={{ color: overallGapColor }}>{averageGap}</div>
                        ) : (
                            <Tooltip title={overallLabel}>
                                <div style={{ color: overallGapColor }}>{scoreGap}</div>
                            </Tooltip>
                        )}
                    </div>
                ) : (
                    <Tooltip title="Gap Scores are Loading. Please wait.">
                        <div
                            className="allGapOverall"
                            style={{
                                borderRadius: '20%',
                                background: overallbgColor,
                                color: overallGapColor,
                                border: `0.5 px solid ${overallBorderColor}`,
                            }}
                        >
                            <div className="spinLoader">
                                <img src={Loader} alt="" />
                            </div>
                        </div>
                    </Tooltip>
                )}
                {renderGap(gapDelta, delta)}
            </div>
        );
    };

    const getFadeStyle = (value, gapV, bgColor) => {
        const { lowScore, highScore, lowGap, highGap } = classification;
        const fadeStyle =
            !(value >= lowScore && value <= highScore) || !(Number(gapV) < highGap && Number(gapV) >= lowGap)
                ? { opacity: 0 }
                : null;
        const fadeStyleBm = !(bgColor === benchmarkColor) && benchmarkColor ? { opacity: 0 } : null;
        if (gapV !== '-' && value !== '-' && !benchmarkColor) {
            return null;
        }
        return withBenchmark && benchmarkColor ? fadeStyleBm : fadeStyle;
    };

    const renderInnerSection = (data, styleValue = '', parentKey = '', resData) => {
        const {
            display_name = '',
            display_score: score = '',
            key = '',
            meta_scores,
            inclusion_quartile = -1,
            quartile = -1,
        } = data;
        const { display_score: resScore = '', inclusion_quartile: resIncQuart = -1, quartile: resQuart = -1 } =
            resData || {};
        let gapValue = '';
        let resurveyGapValue = '';
        let demographicParent = '';
        let demographicParentCode = '';
        let avgGap = '';
        if (parentKey) {
            const { children = {} } = gap[parentKey] || {};
            const { gap: gapV = '', demographic: demoParent = '' } = children[key] || {};
            const { avg_gap: AverageGap = '' } = children[key] || {};
            avgGap = AverageGap;
            gapValue = gapV;
            const parent = demographics.filter(({ code }) => {
                return code === demoParent;
            })[0];
            if (parent) {
                const { label = '' } = parent;
                demographicParent = label;
                demographicParentCode = parent.code;
            }
        } else {
            const { gap: gapV = '', demographic: demoParent = '' } = gap[key] || {};
            const { avg_gap: AverageGap = '' } = gap[key] || {};
            avgGap = AverageGap;
            gapValue = gapV;
            const parent = demographics.filter(({ code }) => {
                return code === demoParent;
            })[0];
            if (parent) {
                const { label = '' } = parent;
                demographicParent = label;
                demographicParentCode = parent.code;
            }
        }

        if (parentKey) {
            const { children = {} } = resurveyGap[parentKey] || {};
            const { gap: resurveyGapV = '', avg_gap: avgResurveyGapV } = children[key] || {};
            resurveyGapValue = showGapToggle ? avgResurveyGapV : resurveyGapV;
        } else {
            const { gap: resurveyGapV = '', avg_gap: avgResurveyGapV } = resurveyGap[key] || {};
            resurveyGapValue = showGapToggle ? avgResurveyGapV : resurveyGapV;
        }
        const quartDelta = isQuartileMovement
            ? quartileDelta(inclusion_quartile, quartile, resIncQuart, resQuart)
            : -100;
        const delta = deltaToDisplay(score, resScore);
        const displayGap = showGapToggle ? avgGap : gapValue;
        const displayScore = score;
        const displayAvgGap = avgGap;
        const gapDelta = deltaToDisplay(displayGap, resurveyGapValue, resScore, true);

        const { bgcolor: bgColor = '', text_color: textColor = '', border_color: borderColor = '' } =
            displayScore !== '-'
                ? getInclusionStyle(inclusion_quartile, quartile, benchmarkStyles, scoreStyles)
                : DEFAULT_STYLE;

        const { bgcolor: gapBgColor = '', text_color: textBgColor = '', border_color: gapBorderColor = '' } =
            displayGap !== '-' ? getGapColor(gapToDisplay(Number(gapValue), score, isPulse), gapStyles) : DEFAULT_STYLE;
        const fadeStyle = getFadeStyle(score, gapValue, bgColor);
        const { bgcolor: gapAvgBgColor = '', text_color: textAvgBgColor = '', border_color: gapAvgBorderColor = '' } =
            displayAvgGap !== '-' ? getGapColor(gapToDisplay(avgGap), gapStyles) : DEFAULT_STYLE;
        const noBg = IA_NON_COMPARABLE_PRACTICES.includes(key) && isDiffSurveyV;
        return (
            <div
                data-testid={key}
                key={display_name}
                style={gapValue !== '-' && score !== '-' ? fadeStyle : null}
                className={c({ resurvey: showDelta })}
                onClick={() => {
                    setParentGap(demographicParentCode);
                    if (parentKey) setModalData(false, parentKey, key, score, { gap: gapValue }, meta_scores, data);
                }}
            >
                <div className="categoryHeading">
                    <div>{display_name}</div>
                </div>
                {showDelta ? getDeltaElement(delta, 'score', quartDelta, noBg) : null}
                {allGapDataFetched ? (
                    <Fragment>
                        {showGapToggle ? (
                            <div
                                style={{ background: gapAvgBgColor, border: `0.5px solid ${gapAvgBorderColor}` }}
                                className={c({ smallText: displayAvgGap === '-' }, 'rectangle', styleValue)}
                            >
                                <div style={{ color: textAvgBgColor }}>{displayAvgGap}</div>
                            </div>
                        ) : (
                            <Tooltip title={demographicParent}>
                                <div
                                    style={{ background: gapBgColor, border: `0.5px solid ${gapBorderColor}` }}
                                    className={c({ smallText: displayGap === '-' }, 'rectangle', styleValue)}
                                    onClick={() => {
                                        setParentGap(demographicParentCode);
                                        if (parentKey)
                                            setModalData(
                                                false,
                                                parentKey,
                                                key,
                                                score,
                                                { gap: gapValue },
                                                meta_scores,
                                                data
                                            );
                                    }}
                                >
                                    <div style={{ color: textBgColor }}>{displayGap}</div>
                                </div>
                            </Tooltip>
                        )}
                    </Fragment>
                ) : (
                    <Tooltip title="Gap Scores are Loading. Please wait.">
                        <div
                            style={{ background: gapBgColor, border: `0.5 px solid ${gapBorderColor}` }}
                            className="rectangle"
                        >
                            <div style={{ color: textBgColor, border: `0.5 px solid ${borderColor}` }}>
                                <div className="spinLoader">
                                    <img src={Loader} alt="" />
                                </div>
                            </div>
                        </div>
                    </Tooltip>
                )}

                <div
                    style={{ background: bgColor, border: `0.5px solid ${borderColor}` }}
                    className={c({ smallText: displayScore === '-' }, 'circle', styleValue)}
                >
                    <div style={{ color: textColor }}>{displayScore}</div>
                </div>
                {renderGap(gapDelta, delta)}
            </div>
        );
    };

    const renderSection = (data, index, minHeight, styleValue, resData) => {
        const { key = '', score = '', meta_scores = [] } = data;
        const { gap: gapV = '' } = gap[key] || {};
        return (
            <div
                key={index}
                style={{
                    minHeight,
                }}
                className="secBody parentHead"
                onClick={() => {
                    if (showGapToggle === false) {
                        setModalData(false, key, '', score, { gap: gapV }, meta_scores, data);
                    }
                }}
            >
                {renderInnerSection(data, styleValue, '', resData)}
            </div>
        );
    };

    const renderQbyqUpperSection = (peerArr, children, resChildren, i, mKey) => {
        if (!showDelta) {
            return peerArr.map(val => {
                const { level = '' } = children[val];
                return level === 'Peer' ? renderInnerSection(children[val], '', mKey) : null;
            });
        }
        return peerArr.map(val => {
            const { level = '' } = children[val];
            return level === 'Peer' ? renderInnerSection(children[val], '', mKey, resChildren[val]) : null;
        });
    };

    const renderSectionBody = (children, mainKey, resData) => {
        return Object.keys(children).map(val => {
            const { level = '' } = children[val];
            if (level === 'Systems') {
                return renderInnerSection(children[val], '', mainKey, showDelta ? resData[val] : '');
            }
            return null;
        });
    };

    const slidesArrayObject = (inclusionData = []) => {
        const shape_value = showDelta ? 'scorecard_comparison' : 'scorecard_with_gaps';
        const shape_value_old = showDelta ? 'scorecard_comparison_old' : 'scorecard_with_gaps_old';
        const identify_shape_value = !overallView ? shape_value_old : shape_value;
        const sigColors =
            isQuartileMovement && showDelta ? SIGNIFICANCE.map(item => ({ ...item, bgcolor: item.bg })) : [];
        return {
            identify_shape_name: 'checkme',
            identify_shape_value,
            styles: [...pptStyle, ...sigColors],
            data: inclusionData,
        };
    };

    const getFilterSlideObj = (filterText, reportName, Nsize) => {
        const oldFilters = showDelta
            ? { name: 'filtertext_old', value: getFiltersText(resurveyFilters, resurveyDemographic) }
            : {};
        return {
            identify_shape_name: 'checkme',
            identify_shape_value: 'filters_applied',
            is_copy: true,
            is_delete: true,
            data: [
                {
                    name: 'filtertext',
                    value: filterText,
                },
                {
                    name: 'exclude_inclusion_gap_text',
                    value: `${excludeFromGapText(getExcludeGapString(defaultExGap, exGap), demographics)}`,
                },
                oldFilters,
                {
                    name: 'Source',
                    value: `${currentOhid} (${NUMBER_TEXT} = ${Nsize})${
                        showDelta ? ` | ${prevSurvey}  (${NUMBER_TEXT} = ${prev_size})` : ''
                    }`,
                },
                {
                    name: 'checkme',
                    is_delete: true,
                },
            ],
        };
    };

    const getPpt = (slideArr = []) => {
        const { pptAccessToken = '' } = AuthStore;
        const filtersUsed = getFiltersText(filters, demographics);
        const filterSlideObj = getFilterSlideObj(filtersUsed, rName, size);
        const output = {
            ...pptParams,
            slides: [...slideArr, filterSlideObj],
        };
        getPptData(output, pptAccessToken, 'Inclusion_ScoreCard');
    };

    const getGapDataPpt = () => {
        const { individual: indG = {}, enterprise: endG = {}, overall: overallGap = {} } = levels_gap;
        const { individual: res_indG = {}, enterprise: res_endG = {}, overall: prevOverallGap = '' } =
            prev_levels_gap || {};
        const { gap: gapI = '' } = indG;
        const { gap: gapE = '' } = endG;
        const { gap: resGapI = '' } = res_indG || {};
        const { gap: resGapE = '' } = res_endG || {};
        const allGapOverall = showGapToggle ? overallGap.avg_gap : overallGap.gap;
        const allGapOverallStyle = getGapColor(allGapOverall, gapStyles, true) + 12;
        const overallGapRes = showGapToggle ? prevOverallGap.avg_gap : prevOverallGap.gap;
        const inclusionGapDelta = deltaToDisplay(allGapOverall, overallGapRes, allGapOverall, true);
        // const inclusionGapDeltaStyle = getDeltaColorSc(inclusionGapDelta, pptStyle, true, 0, 'gap') + 15;
        const gapDeltaInd = deltaToDisplay(gapI, resGapI, gapI, true);
        const gapDeltaEnt = deltaToDisplay(gapE, resGapE, gapE, true);
        const slideArr = [];
        slideArr.push(
            {
                name: 'individual_gap',
                value: gapI,
                style: getGapColor(gapI, gapStyles, true) + 12,
            },
            {
                name: 'enterprise_gap',
                value: gapE,
                style: getGapColor(gapE, gapStyles, true) + 12,
            }
        );
        slideArr.push({
            name: 'demo_gap',
            value: allGapOverall,
            style: allGapOverallStyle,
        });
        if (showGapToggle) {
            slideArr.push({
                name: 'largest_gap',
                is_delete: true,
            });
        } else {
            slideArr.push({
                name: 'average_gap',
                is_delete: true,
            });
        }
        if (showDelta) {
            slideArr.push(
                {
                    name: 'individual_gap_delta',
                    value: gapDeltaInd,
                    style: getDeltaColor({ delta: gapDeltaInd, type: 'gap' }) + 15,
                },
                {
                    name: 'enterprise_gap_delta',
                    value: gapDeltaEnt,
                    style: getDeltaColor({ delta: gapDeltaEnt, type: 'gap' }) + 15,
                },
                {
                    name: 'individual_gap_delta2',
                    is_delete: true,
                },
                {
                    name: 'enterprise_gap_delta2',
                    is_delete: true,
                }
            );
            slideArr.push(
                {
                    name: 'demo_gap_delta',
                    value: inclusionGapDelta,
                    style: getDeltaColor({ delta: inclusionGapDelta, type: 'gap' }) + 15,
                },
                {
                    name: 'demo_gap_delta2',
                    is_delete: true,
                }
            );
        }
        Object.keys(gap).forEach(key => {
            const { gap: gapC = '', children = {}, avg_gap: avgGapC } = gap[key];
            const { gap: resGapC = '', children: res_children = {}, avg_gap: resGapCAvg } = resurveyGap[key] || {};
            const { score = '', children: childrenScoreObj = {} } = records[key];
            const gapVal = gapToDisplay(showGapToggle ? avgGapC : gapC, score, isPulse);
            const resGapVal = gapToDisplay(showGapToggle ? resGapCAvg : resGapC, score, isPulse);
            const gapDeltaVal = deltaToDisplay(gapVal, resGapVal, gapVal, true);
            slideArr.push({
                name: `${key}_gap`,
                value: gapVal,
                style: getGapColor(gapVal, gapStyles, true) + 12,
            });
            if (showDelta) {
                slideArr.push({
                    name: `${key}_gap_delta`,
                    value: gapDeltaVal,
                    style: getDeltaColor({ delta: gapDeltaVal, type: 'gap' }) + 15,
                });
            }
            Object.keys(children).forEach(subKey => {
                const { gap: childGap = '', avg_gap: avgGapChild = '' } = children[subKey];
                const { gap: resChildGap = '', avg_gap: resChildGapAvg = '' } = res_children[subKey] || {};
                const { score: childScore = '' } = childrenScoreObj[subKey];
                const gapValue = gapToDisplay(showGapToggle ? avgGapChild : childGap, childScore, isPulse);
                const resGapValue = gapToDisplay(showGapToggle ? resChildGapAvg : resChildGap, childScore, isPulse);
                const gapDeltaValue = deltaToDisplay(gapValue, resGapValue, gapValue, true);
                slideArr.push({
                    name: `${subKey}_gap`,
                    value: gapValue,
                    style: getGapColor(gapValue, gapStyles, true) + 12,
                });
                if (showDelta) {
                    slideArr.push({
                        name: `${subKey}_gap_delta`,
                        value: gapDeltaValue,
                        style: getDeltaColor({ delta: gapDeltaValue, type: 'gap' }) + 15,
                    });
                }
            });
        });
        return slideArr;
    };

    const getDataForPpt = () => {
        let slideArr = [];
        const {
            score: indScore = '',
            display_score: indDisScore = '',
            quartile: indQuart = -1,
            inclusion_quartile: incQuartInd = -1,
        } = individual;
        const {
            score: entScore = '',
            display_score: entDisScore = '',
            quartile: entQuart = -1,
            inclusion_quartile: incQuartEnt = -1,
        } = enterprise;
        const { score: ovScore = '', display_score: ovIncDisVal = '', quartile: ovQuartile = -1 } = overall;
        const {
            display_score: resIndScore = '',
            quartile: resIndQuart = -1,
            inclusion_quartile: resIndIncQuart = -1,
        } = res_individual;
        const {
            display_score: resEntScore = '',
            quartile: resEntQuart = -1,
            inclusion_quartile: resEntIncQuart = -1,
        } = res_enterprise;
        const { display_score: allScorePrev = '', quartile: resOvQuartile = -1 } = res_overall || {};
        // const ovIncDisVal = scoreToDisplay(ovScore, [], isPulse);
        const ovIncIndex = withBenchmark
            ? getBenchmarkColor(ovQuartile, ovIncDisVal)
            : getColor(ovIncDisVal, pptStyle, false, true);
        slideArr.push({ name: showDelta ? 'overall_score' : 'inclusion_score', value: ovIncDisVal, style: ovIncIndex });
        const inclusionScoreDelta = deltaToDisplay(ovIncDisVal, allScorePrev, ovScore);
        if (showDelta) {
            const indDelta = deltaToDisplay(indDisScore, resIndScore, indScore);
            const entDelta = deltaToDisplay(entDisScore, resEntScore, entScore);
            slideArr.push({
                name: 'individual_score_delta',
                value: indDelta,
                style:
                    getDeltaColor({
                        delta: indDelta,
                        quartMovement: isQuartileMovement
                            ? quartileDelta(incQuartInd, indQuart, resIndIncQuart, resIndQuart)
                            : '',
                    }) + 15,
            });
            slideArr.push({
                name: 'enterprise_score_delta',
                value: entDelta,
                style:
                    getDeltaColor({
                        delta: entDelta,
                        quartMovement: isQuartileMovement
                            ? quartileDelta(incQuartEnt, entQuart, resEntIncQuart, resEntQuart)
                            : '',
                    }) + 15,
            });
            slideArr.push({
                name: 'overall_score_delta',
                value: inclusionScoreDelta,
                style:
                    getDeltaColor({
                        delta: inclusionScoreDelta,
                        quartMovement: isQuartileMovement ? quartileDelta(-1, ovQuartile, -1, resOvQuartile) : '',
                    }) + 15,
            });
            slideArr.push({
                name: 'individual_score_delta2',
                is_delete: true,
            });
            slideArr.push({
                name: 'enterprise_score_delta2',
                is_delete: true,
            });
            slideArr.push({
                name: 'overall_score_delta2',
                is_delete: true,
            });
        }
        const paramForLegend = getPptParamsForLegend(benchmarks);
        const { text_color: indText = 'black' } =
            getInclusionStyle(incQuartInd, indQuart, benchmarkStyles, styleColors) || {};
        const { text_color: entText = 'black' } =
            getInclusionStyle(incQuartEnt, entQuart, benchmarkStyles, styleColors) || {};
        slideArr.push(
            {
                name: 'Source',
                value: `${currentOhid} (${NUMBER_TEXT} = ${size})${
                    showDelta ? ` | ${prevSurvey}  (${NUMBER_TEXT} = ${prev_size})` : ''
                }`,
            },
            {
                name: 'individual_score',
                value: indDisScore,
                text_color: indText,
            },
            {
                name: 'individual_score_back',
                style: withBenchmark ? getBenchmarkColor(indQuart) : getColor(indScore, pptStyle, false, true),
                value: '',
            },
            {
                name: 'enterprise_score',
                value: entDisScore,
                text_color: entText,
            },
            {
                name: 'enterprise_score_back',
                style: withBenchmark ? getBenchmarkColor(entQuart) : getColor(entScore, pptStyle, false, true),
                value: '',
            },
            ...paramForLegend
        );
        Object.keys(records).forEach(key => {
            const { display_score: scoreVal = '', children = '', quartile = -1 } = records[key];
            const { display_score: res_score = '', children: res_children = [], quartile: resQuart = -1 } =
                prevRecords[key] || {};
            const resScoreVal = deltaToDisplay(scoreVal, res_score, scoreVal);
            slideArr.push({
                name: `${key}_score`,
                value: scoreVal,
                style: withBenchmark
                    ? getBenchmarkColor(quartile, scoreVal)
                    : getColor(scoreVal, pptStyle, false, true),
            });
            if (showDelta) {
                slideArr.push({
                    name: `${key}_score_delta`,
                    value: resScoreVal,
                    style:
                        getDeltaColor({
                            delta: resScoreVal,
                            quartMovement: isQuartileMovement ? quartileDelta(-1, quartile, -1, resQuart) : '',
                        }) + 15,
                });
            }
            Object.keys(children).forEach(subKey => {
                const { display_score: childScoreVal = '', quartile: incQuartile = -1 } = children[subKey];
                const { display_score: resChildScore = '', quartile: resQuartile = -1 } = res_children[subKey] || {};
                const resChildScoreVal = deltaToDisplay(childScoreVal, resChildScore, childScoreVal);
                slideArr.push({
                    name: `${subKey}_score`,
                    value: childScoreVal,
                    style: withBenchmark
                        ? getBenchmarkColor(incQuartile, childScoreVal)
                        : getColor(childScoreVal, pptStyle, false, true),
                });
                if (showDelta) {
                    slideArr.push({
                        name: `${subKey}_score_delta`,
                        value: resChildScoreVal,
                        style:
                            getDeltaColor({
                                delta: resChildScoreVal,
                                quartMovement: isQuartileMovement
                                    ? quartileDelta(-1, incQuartile, -1, resQuartile)
                                    : '',
                            }) + 15,
                    });
                }
            });
        });
        slideArr = [...slideArr, ...getGapDataPpt()];
        const footnote = `Footnote_${
            inclusion_resurvey_settings === QUARTILE_MOVE ? 'quart' : 'value'
        }_${surveyV?.split('.').join('_')}`;
        const footnoteI = RES_FOOT_ARR.findIndex(({ name }) => name === footnote);
        const resFootNote = showDelta
            ? [...RES_FOOT_ARR.slice(0, footnoteI), ...RES_FOOT_ARR.slice(footnoteI + 1, RES_FOOT_ARR.length)]
            : [];
        const group = [
            {
                name: `${inclusion_resurvey_settings === QUARTILE_MOVE ? 'value' : 'quart'}_group`,
                is_delete: true,
            },
        ];
        const resGroupToShow = showDelta ? group : [];
        slideArr.push(
            {
                name: 'checkme',
                is_delete: true,
                value: -1,
            },
            {
                name: 'benchmark_detail',
                value: `${benchmarkName} (${benchmarkVersions[0]})`,
            },
            ...resFootNote,
            ...resGroupToShow
        );
        getPpt([slidesArrayObject(slideArr)]);
    };
    return (
        <div className="scorecardBoard clearfix">
            <Helmet>
                <title>{INCLUSION_SCORECARD}</title>
            </Helmet>
            <div className="underline">
                <div className="optionWrapper">
                    <div>
                        <h2>{INCLUSION_SCORECARD}</h2>
                    </div>
                    <div>
                        <ul className="rightNav">
                            {resurveyInactive ? (
                                <Tooltip title={RESURVEY_INACTIVE_TEXT}>
                                    <li className="resurvey inActive">
                                        <img src={CompareView} alt="" />
                                        {RESURVEY_TEXT}
                                    </li>
                                </Tooltip>
                            ) : (
                                <li
                                    className={c({ selected: resurveyDataFetched }, 'resurvey')}
                                    onClick={() => setResurvey(!showResurvey)}
                                >
                                    <img src={CompareView} alt="" />
                                    {resurveyDataFetched && (
                                        <div
                                            onClick={e => {
                                                e.stopPropagation();
                                                setResurvey(true);
                                                setModalType(2);
                                            }}
                                        >
                                            <img src={Close} alt="" />
                                        </div>
                                    )}
                                    {RESURVEY_TEXT}
                                </li>
                            )}
                            {resurveyAllGapDataFetched && checkLength && showDelta ? (
                                <>
                                    <li>
                                        <div className="respondents">{`Current survey (n) = ${size} (${currentOhid} - ${year})`}</div>
                                    </li>
                                    <li>
                                        <div className="respondents">{`Comparison survey (n) = ${prev_size} (${project} - ${prevYear})`}</div>
                                    </li>
                                </>
                            ) : null}
                            {checkLength && selectedTab !== 1 ? (
                                <li>
                                    <div className="respondents">{`Total Respondents (n) = ${size}`}</div>
                                </li>
                            ) : null}
                            {true && (
                                <li
                                    data-testid="getPptBtn"
                                    className={c({ pptLoading: !checkGapLength || !allGapDataFetched }, 'separator')}
                                    onClick={getDataForPpt}
                                >
                                    <img src={DownloadIcon} alt="" /> <span>{DOWNLOAD}</span>
                                </li>
                            )}
                            <Tooltip arrow title={FILTER_HOVER_TXT}>
                                <li
                                    className="separator noBorder"
                                    data-testid="filterButton"
                                    onClick={() => toggleFilterView(true)}
                                >
                                    <img src={activeFilter ? ActiveFilter : FilterSvg} alt="" /> <span>{FILTERS}</span>
                                </li>
                            </Tooltip>
                        </ul>
                    </div>
                </div>
            </div>
            {resurveyDataFetched && (
                <ul className="leftNav">
                    {SCORECARD_TABS.map((tabVal, tabIndex) => (
                        <li
                            key={tabVal}
                            data-testid={tabVal}
                            className={c({ activeLink: tabIndex === selectedTab })}
                            onClick={() => setSelectedTab(tabIndex)}
                        >
                            {tabVal}
                        </li>
                    ))}
                </ul>
            )}
            {message ? <ErrorToast message={message} /> : null}
            <div className="allContent">
                <div className="overallToggle">
                    <ul>
                        <li className="toggleBtn">
                            <div className="tagName">{SHOW_OVERALL}</div>
                            <div
                                className={c({ overallView })}
                                onClick={() => setOverallView(!overallView)}
                                data-testid="indModalBtn"
                            >
                                <div data-testid="hideGapBtn" className={c({ isActive: !overallView })} />
                                <div data-testid="showGapBtn" className={c({ isActive: overallView })} />
                            </div>
                        </li>
                        <li className="leftToggleList">
                            <div className="toggleTagName">{VIEW_BY}</div>
                            <div>
                                <div className={c({ isActive: showGapToggle })} onClick={() => setShowGapToggle(true)}>
                                    {AVG_GAP}
                                </div>
                                <div
                                    className={c({ isActive: !showGapToggle })}
                                    onClick={() => setShowGapToggle(false)}
                                >
                                    {LARGEST_GAP}
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            {overallView ? (
                <div className="content">
                    {Object.keys(qbyqData).length ? (
                        <div className="box">
                            {['Individual'].map((key, index) => {
                                const qbyqUpperArr = qbyqData[key];
                                let resQbyqUpperArr = {};
                                if (showDelta) {
                                    resQbyqUpperArr = resQbyqData[key];
                                }
                                return (
                                    <div key={index} className="upperHalf">
                                        <div className="allContent">
                                            <div>{renderOverall(levels_gap, overall)}</div>
                                            <div />
                                        </div>

                                        <div className="upperHeading">
                                            <div className="outcomeScoreGaps">{OUTCOME_SCORE_GAPS}</div>
                                            <div className="outcomeScoreGaps">{PRACTICE_SCORE_GAPS}</div>
                                        </div>

                                        <div className="upperContent">
                                            <div className="leftSec">
                                                <div />
                                                <div className="endSection">
                                                    <div className="secHeader invisible-white" />
                                                    {qbyqUpperArr.map((data, i) => {
                                                        const { count = '' } = data;
                                                        const resData = resQbyqUpperArr[i] || {};
                                                        const minHeight =
                                                            i === qbyqUpperArr.length - 1
                                                                ? count * 6.2 + 'vh'
                                                                : count * 6.5 + 'vh';
                                                        return !showDelta
                                                            ? renderSection(data, i, minHeight, 'middle')
                                                            : renderSection(data, i, minHeight, 'middle', resData);
                                                    })}
                                                </div>
                                                <div className="endSection borderSection">
                                                    <div className="secHeader">
                                                        <img src={Peers} alt="peers" />
                                                        <span>{PEERS}</span>
                                                    </div>
                                                    {qbyqUpperArr.map(({ children = {}, count = '', key: mKey }, i) => {
                                                        const { children: resChildren = {} } = resQbyqUpperArr[i] || [];
                                                        const peerArr = Object.keys(children);
                                                        return (
                                                            <div
                                                                style={{
                                                                    minHeight:
                                                                        i === 0 ? 14.8 + 'vh' : count * 6.5 + 'vh',
                                                                }}
                                                                key={i}
                                                                className="secBody"
                                                            >
                                                                {renderQbyqUpperSection(
                                                                    peerArr,
                                                                    children,
                                                                    resChildren,
                                                                    i,
                                                                    mKey
                                                                )}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                            <div className="rightSec">
                                                <div className="endSection">
                                                    <div className="secHeader">
                                                        <img src={Leaders} alt="leaders" />
                                                        <span>{LEADERS}</span>
                                                    </div>
                                                    {qbyqUpperArr.map(
                                                        ({ children, count, key: mainKey }, qbyqIndex) => {
                                                            const { children: resData = {} } =
                                                                resQbyqUpperArr[qbyqIndex] || {};
                                                            const leaderArray = Object.keys(children).filter(
                                                                myVal => children[myVal].level === 'Leader'
                                                            );
                                                            const minHeight =
                                                                qbyqIndex === qbyqUpperArr.length - 1
                                                                    ? '13vh'
                                                                    : count * 3 + 'vh';
                                                            return leaderArray.length ? (
                                                                <div
                                                                    key={qbyqIndex}
                                                                    style={{ minHeight }}
                                                                    className="secBody noLeftBorder"
                                                                >
                                                                    {leaderArray.map(val => {
                                                                        return renderInnerSection(
                                                                            children[val],
                                                                            '',
                                                                            mainKey,
                                                                            showDelta ? resData[val] : ''
                                                                        );
                                                                    })}
                                                                </div>
                                                            ) : null;
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}

                            {['Enterprise'].map((key, index) => {
                                const qbyqUpperArr = qbyqData[key];
                                let resQbyqUpperArr = [];
                                if (showDelta) {
                                    resQbyqUpperArr = resQbyqData[key];
                                }
                                return (
                                    <div key={index} className="lowerHalf">
                                        <div>
                                            <div className="leftSec">
                                                <div />
                                                <div className="endSection">
                                                    <div className="secHeader invisible-white" />
                                                    {qbyqUpperArr.map((data, qbyqUpperIndex) => {
                                                        const { count } = data;
                                                        const resData = resQbyqUpperArr[qbyqUpperIndex] || {};
                                                        const minHeight =
                                                            qbyqUpperIndex === qbyqUpperArr.length - 1
                                                                ? 17.5 + 'vh'
                                                                : count * 5.6 + 'vh';
                                                        return renderSection(
                                                            data,
                                                            qbyqUpperIndex,
                                                            minHeight,
                                                            'middle',
                                                            resData
                                                        );
                                                    })}
                                                </div>

                                                <div className="endSection borderSection">
                                                    <div className="secHeader">
                                                        <img src={Systems} alt="systems" />
                                                        <span>{SYSTEMS}</span>
                                                    </div>
                                                    {qbyqUpperArr.map(({ children, count, key: mainKey }, i) => {
                                                        const { children: resData = {} } = resQbyqUpperArr[i] || {};
                                                        return (
                                                            <div
                                                                key={i}
                                                                style={{ minHeight: count * 6.1 + 'vh' }}
                                                                className="secBody"
                                                            >
                                                                {renderSectionBody(children, mainKey, resData)}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                            <div className="rightSec noBorder marginContent">
                                                <div className="endSection border">
                                                    <div className="secHeader invisible" />
                                                    {qbyqUpperArr.map(
                                                        ({ children, count, key: mainKey }, qbyqIndex) => {
                                                            const { children: resData = {} } =
                                                                resQbyqUpperArr[qbyqIndex] || {};
                                                            const leaderArray = Object.keys(children).filter(
                                                                myVal => children[myVal].level === 'Leader'
                                                            );
                                                            const minHeight =
                                                                index === qbyqUpperArr.length - 1
                                                                    ? '4vh'
                                                                    : count * 6.1 + 'vh';
                                                            return leaderArray.length ? (
                                                                <div
                                                                    key={qbyqIndex}
                                                                    style={{ minHeight }}
                                                                    className={c('secBody noLeftBorder', {
                                                                        noTopBorder: qbyqIndex === 0,
                                                                    })}
                                                                >
                                                                    {leaderArray.map(val => {
                                                                        return renderInnerSection(
                                                                            children[val],
                                                                            '',
                                                                            mainKey,
                                                                            showDelta ? resData[val] : ''
                                                                        );
                                                                    })}
                                                                </div>
                                                            ) : null;
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : null}

                    <div className="tile">
                        {checkLength ? (
                            <div>
                                <div>
                                    <ul className="classification">
                                        <li>{SCORE_CLASSIFICATION_TEXT}</li>
                                        {styleToUse
                                            .filter(
                                                ({ quartile, style_for }) =>
                                                    quartile !== '0' && quartile !== '999' && style_for !== 'blank_data'
                                            )
                                            .map(({ title, bgcolor: background = '' }, index) => {
                                                const { styleFade } = classification;
                                                const styles = index === listStyle ? styleFade : {};
                                                return (
                                                    <li
                                                        data-testid={`score${index}`}
                                                        key={title}
                                                        style={styles}
                                                        onClick={() => {
                                                            if (withBenchmark) {
                                                                if (benchmarkColor === background) {
                                                                    setBenchmarkColor('');
                                                                    setClassification(initialValue);
                                                                } else {
                                                                    setBenchmarkColor(background);
                                                                    setClassification({
                                                                        ...initialValue,
                                                                        styleFade: {
                                                                            backgroundColor: 'rgb(235, 235, 235)',
                                                                        },
                                                                    });
                                                                    setListStyle(index);
                                                                }
                                                            } else {
                                                                setViewOption(title, 'score');
                                                                setListStyle(index);
                                                            }
                                                        }}
                                                    >
                                                        <span className="circle" style={{ background }} />
                                                        {title}
                                                    </li>
                                                );
                                            })}
                                    </ul>
                                </div>
                                <div>
                                    <ul className="classification">
                                        <li>{GAP_CLASSIFICATION_TEXT} </li>
                                        {gapStyles.map(({ title, bgcolor: background }, index) => {
                                            const { gapHighlight } = classification;
                                            const styles = index === listStyle ? gapHighlight : {};
                                            return (
                                                <li
                                                    data-testid={`gap${index}`}
                                                    key={title}
                                                    style={styles}
                                                    onClick={() => {
                                                        setViewOption(title, 'gap');
                                                        setListStyle(index);
                                                    }}
                                                >
                                                    <span style={{ background }} />
                                                    {title}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                                {showDelta ? (
                                    <div>
                                        <ul className="classification clearfix">
                                            <li>{isQuartileMovement ? QUARTILE_DIFFERENCE : VALUE_BASED_DIFFERENCE}</li>
                                            {deltaStyles.map(({ bgcolor: color, label }, index) => {
                                                const labelToUse = !isQuartileMovement
                                                    ? label
                                                    : QUARTILE_MOVE_LEGEND_TXT_IA[index];
                                                return (
                                                    <li data-testid={`change${index}`} key={label}>
                                                        <span style={{ background: color }} />
                                                        {labelToUse}
                                                    </li>
                                                );
                                            })}
                                            <li data-testid="change3">
                                                <span style={{ background: '#ffffff', border: '1px solid #000000' }} />
                                                No score comparison
                                            </li>
                                        </ul>
                                        <ul className="classification clearfix">
                                            <li>{GAP_BASED_DIFFERENCE}</li>
                                            {gapDeltaStyles.map(({ text_color: color }, index) => {
                                                const changeLabel = GAP_LEGEND_TEXT[index];
                                                return (
                                                    <li data-testid={`gapchange${index}`} key={changeLabel}>
                                                        <span style={{ background: color }} />
                                                        {changeLabel}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                    </div>

                    {resurveyAllGapDataFetched && showDelta ? (
                        <div className="filterInfoSection newVersion">
                            <div>
                                <b>{FILTERS_APPLIED + ' (current survey) : '}</b>
                                {getFiltersAppliedText(filters, demographics)
                                    ? getFiltersAppliedText(filters, demographics)
                                    : NONE}{' '}
                                | n = {size}
                            </div>
                            <div>
                                <b>{FILTERS_APPLIED + ' (comparison survey) : '}</b>
                                {getFiltersAppliedText(resurveyFilters, demographics)
                                    ? getFiltersAppliedText(resurveyFilters, resurveyDemographic)
                                    : NONE}{' '}
                                | n = {prev_size}
                            </div>
                            <div>
                                <b>{BENCHMARKS_APPLIED + ' : '}</b> {`${benchmarkName} | ${benchmarkVersions[0]}`}
                            </div>
                        </div>
                    ) : (
                        <div className="filterInfoSection">
                            <div>
                                <b>{FILTERS_APPLIED + ' : '}</b>
                                {getFiltersAppliedText(filters, demographics)
                                    ? getFiltersAppliedText(filters, demographics)
                                    : NONE}{' '}
                                | n = {size}
                            </div>
                            <div>
                                <b>{BENCHMARKS_APPLIED + ' : '}</b> {`${benchmarkName} | ${benchmarkVersions[0]}`}
                            </div>
                        </div>
                    )}
                    {checkLength && checkGapLength ? (
                        <>
                            <div className="disclaimer">
                                <div>{DISCLAIMER} : </div>
                                {!showDelta &&
                                    SCORECARD_DISCLAIMER.map((text, i) => (
                                        <div key={`res${i}`} className="demoDisclaimerNote">
                                            <div>{i + 1}.</div>
                                            <div>{text}</div>
                                        </div>
                                    ))}

                                {showDelta &&
                                    isValueBased &&
                                    isDiffSurveyV &&
                                    SCORECARD_RESURVEY_VALUE_BASED.map((text, i) => (
                                        <div key={`res${i}`} className="demoDisclaimerNote">
                                            <div>{i + 1}.</div>
                                            <div>{text}</div>
                                        </div>
                                    ))}

                                {showDelta &&
                                    isQuartileMovement &&
                                    isDiffSurveyV &&
                                    SCORECARD_RESURVEY_QUARTILE_BASED.map((text, i) => (
                                        <div key={`res${i}`} className="demoDisclaimerNote">
                                            <div>{i + 1}.</div>
                                            <div>{text}</div>
                                        </div>
                                    ))}

                                {showDelta &&
                                    isValueBased &&
                                    isDiffSurveyV2 &&
                                    SCORECARD_RESURVEY_VALUE_BASED_V2.map((text, i) => (
                                        <div key={`res${i}`} className="demoDisclaimerNote">
                                            <div>{i + 1}.</div>
                                            <div>{text}</div>
                                        </div>
                                    ))}

                                {showDelta &&
                                    isQuartileMovement &&
                                    isDiffSurveyV2 &&
                                    SCORECARD_RESURVEY_QUARTILE_BASED_V2.map((text, i) => (
                                        <div key={`res${i}`} className="demoDisclaimerNote">
                                            <div>{i + 1}.</div>
                                            <div>{text}</div>
                                        </div>
                                    ))}

                                {selectedTab === 1 && resurveyNote && (
                                    <div>
                                        Note : To compare past scores with current scores, adjustments should be applied
                                        to past practice scores for Connection opportunities (-3 points) and Resource
                                        Accessibility (+1 points)
                                    </div>
                                )}
                                {inclusion_old_overall_calc && overallView ? <div>{IA_OLD_DISCLAIMER}</div> : null}
                            </div>
                        </>
                    ) : null}
                </div>
            ) : (
                <ScorecardAlt
                    inclusionqbyq={inclusionqbyq}
                    getPptData={getPptData}
                    allGapData={allGapData}
                    demographics={demographics}
                    filters={filters}
                    scoreColor={scoreColor}
                    iaApiParams={iaApiParams}
                    resurveyData={resurveyData}
                    resurveyFilters={resurveyFilters}
                    resurveyDemographic={resurveyDemographic}
                    resurveyAllGapDataFetched={resurveyAllGapDataFetched}
                    resurveyAllGapData={resurveyAllGapData}
                    isPulse={isPulse}
                    benchmarkName={benchmarkName}
                    allGapDataFetched={allGapDataFetched}
                    bmVersionInUse={bmVersionInUse}
                    benchmarkVersions={benchmarkVersions}
                    showGapToggle={showGapToggle}
                    setShowGapToggle={setShowGapToggle}
                    showDelta={showDelta}
                    updateModal={updateModal}
                    setParentGap={setParentGap}
                    isQuartileMovement={isQuartileMovement}
                    defaultSettings={defaultSettings}
                />
            )}

            {clickThrusMeta && (
                <ClickThrusModal
                    clickThrusMeta={clickThrusMeta}
                    demographics={demographics}
                    closeModal={updateModal}
                    apiParams={{
                        ohid: currentOhid,
                        group_on: '',
                        filters,
                        lang,
                        inclusion_threshold,
                    }}
                    parentGap={parentGap}
                    inclusion_threshold={inclusion_threshold}
                    scoreColor={scoreColor}
                    withBenchmark={withBenchmark}
                />
            )}
        </div>
    );
}

Scorecard.defaultProps = {
    isPulse: false,
    benchmarkName: '',
    resurveyAllGapData: {},
    resurveyAllGapDataFetched: false,
    resurveyFilters: [],
    resurveyDemographic: [],
    project: '',
    resurveyDataFetched: false,
    prevYear: '',
    showGapToggle: false,
    setShowGapToggle: () => {},
    prevSurvey: '',
    showResurvey: false,
    setResurvey: () => {},
    deleteResurveyMapping: () => {},
    getInclusionDataResurvey: () => {},
    getResurveyAllGapData: () => {},
    resurveyDemoFetched: false,
    getDemographics: () => {},
    resurveyInactive: false,
};

Scorecard.propTypes = {
    currentOhid: PropTypes.string.isRequired,
    lang: PropTypes.number.isRequired,
    demographics: PropTypes.array.isRequired,
    filters: PropTypes.array.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    inclusionqbyq: PropTypes.object.isRequired,
    allGapData: PropTypes.object.isRequired,
    activeFilter: PropTypes.bool.isRequired,
    inclusion_threshold: PropTypes.number.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    getPptData: PropTypes.func.isRequired,
    scoreColor: PropTypes.object.isRequired,
    iaApiParams: PropTypes.object.isRequired,
    reportApiParams: PropTypes.object.isRequired,
    resurveyData: PropTypes.object.isRequired,
    resurveyAllGapDataFetched: PropTypes.bool,
    resurveyAllGapData: PropTypes.object,
    project: PropTypes.string,
    resurveyFilters: PropTypes.array,
    resurveyDemographic: PropTypes.array,
    benchmarkName: PropTypes.string,
    isPulse: PropTypes.bool,
    resurveyDataFetched: PropTypes.bool,
    allGapDataFetched: PropTypes.bool.isRequired,
    benchmarkVersions: PropTypes.array.isRequired,
    bmVersionInUse: PropTypes.number.isRequired,
    prevYear: PropTypes.string,
    showGapToggle: PropTypes.bool,
    setShowGapToggle: PropTypes.func,
    prevSurvey: PropTypes.string,
    showResurvey: PropTypes.bool,
    setResurvey: PropTypes.func,
    setModalType: PropTypes.func.isRequired,
    deleteResurveyMapping: PropTypes.func,
    getInclusionDataResurvey: PropTypes.func,
    getResurveyAllGapData: PropTypes.func,
    resurveyDemoFetched: PropTypes.bool,
    getDemographics: PropTypes.func,
    resurveyInactive: PropTypes.bool,
    isDiffSurveyV: PropTypes.bool.isRequired,
    isDiffSurveyV2: PropTypes.bool.isRequired,
};

export default Scorecard;
