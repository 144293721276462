import React, { Fragment, useEffect } from 'react';
import XLSX from 'xlsx-color';
import PropTypes from 'prop-types';
import { getStaticText } from '../../../utils/constants';
import colors from '../../../sass/colors';
import {
    getGapColor,
    getIncBenchmarkStyles,
    getRandomValues,
    getStyleForExcel,
    getStyleForGapExcel,
} from '../../../utils/functions';

function Excel({
    header,
    items,
    selectedDemographic,
    demographics,
    apiParams,
    benchmarks,
    defaultSettings,
    isGapHeatmap,
    inclusionStyle,
    overallArr,
    setSelectedDownload,
    benchmarkName,
    threshold,
    respondents,
    overallView,
}) {
    const { lang, quartileColors = [] } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { styles = [], gap: gapStyle } = inclusionStyle;
    const benchmarkStyles = getIncBenchmarkStyles(quartileColors);
    const { N_SIZE } = SITE_TEXT || {};
    const subHeader = arr => {
        const outcomes = arr.map(item => item.split('_')[0]);
        const outcomeObj = {};
        outcomes.forEach(item => {
            if (outcomeObj[item]) {
                outcomeObj[item] += 1;
            } else {
                outcomeObj[item] = 1;
            }
        });
        return outcomeObj;
    };
    const withBenchmark = benchmarks !== '-1:-1';
    const outcomeObj = (isGapHeatmap ? subHeader(header.slice(2)) : subHeader(header.slice(3))) || [];
    const getExport = () => {
        const table = document.getElementById('mytable');
        if (table) {
            const wb = XLSX.utils.table_to_book(table, { sheet: 'Report' });
            Object.keys(wb.Sheets.Report).forEach(val => {
                if (typeof wb.Sheets.Report[val] === 'object') {
                    const merge = wb.Sheets.Report[val].length ? wb.Sheets.Report[val] : [];
                    let split = [];
                    if (!merge.length) {
                        if (wb.Sheets.Report[val].v.length) {
                            split = wb.Sheets.Report[val].v.split('#');
                        } else {
                            split = ['', ''];
                        }
                    }
                    if (split.length > 0) {
                        const splitFirst = split[0];
                        const styleObject = withBenchmark
                            ? getStyleForExcel(splitFirst, benchmarkStyles)
                            : getStyleForGapExcel(splitFirst, styles);
                        const { bgcolor: background = colors.$white, text_color: color = colors.$black } =
                            styleObject || {};
                        // eslint-disable-next-line prefer-destructuring
                        wb.Sheets.Report[val].v = split[1];
                        let align = 'left';
                        if (split.length === 3 || split[3]) {
                            align = split[3] === 'left' ? 'left' : split[2];
                        } else {
                            align = splitFirst === 'bold' ? 'left' : 'center';
                        }
                        if (wb.Sheets.Report[val].v || splitFirst) {
                            wb.Sheets.Report[val].s = {
                                font: {
                                    color: { rgb: color.replace('#', '') },
                                    bold: splitFirst === 'bold' || splitFirst === 'header',
                                    sz: splitFirst === 'bold' || splitFirst === 'header' ? 14 : 12,
                                },
                                alignment: {
                                    vertical: align,
                                    horizontal: align,
                                    textRotation: split[3] === 'rotate' ? 90 : null,
                                },
                                fill: {
                                    patternType: 'solid',
                                    fgColor: {
                                        rgb: background.replace('#', ''),
                                    },
                                },
                                border: {
                                    bottom: {
                                        style: 'thin',
                                        color: {
                                            rgb: splitFirst === 'bold' ? '000000' : 'FFFFFF',
                                        },
                                    },
                                    top: {
                                        style: 'thin',
                                        color: {
                                            rgb: splitFirst === 'bold' ? '000000' : 'FFFFFF',
                                        },
                                    },
                                    left: {
                                        style: 'thin',
                                        color: {
                                            rgb: splitFirst === 'bold' ? '000000' : 'FFFFFF',
                                        },
                                    },
                                    right: {
                                        style: 'thin',
                                        color: {
                                            rgb: splitFirst === 'bold' ? '000000' : 'FFFFFF',
                                        },
                                    },
                                },
                            };
                        }
                        if (wb.Sheets.Report[val].v && split[3]) {
                            wb.Sheets.Report[val].s = {
                                font: {
                                    color: { rgb: color.replace('#', '') },
                                    bold: splitFirst === 'bold' || splitFirst === 'header',
                                    sz: splitFirst === 'bold' || splitFirst === 'header' ? 14 : 12,
                                },
                                fill: {
                                    patternType: 'solid',
                                    fgColor: {
                                        rgb: split[3] === 'rotate' ? background.replace('#', '') : split[3],
                                    },
                                },
                                alignment: {
                                    vertical: split[3] === 'rotate' ? 'center' : align,
                                    horizontal: split[3] === 'rotate' ? 'center' : align,
                                    textRotation: split[3] === 'rotate' ? 90 : null,
                                },
                                border: {
                                    bottom: {
                                        style: 'thin',
                                        color: {
                                            rgb: split[3],
                                        },
                                    },
                                    top: {
                                        style: 'thin',
                                        color: {
                                            rgb: split[3],
                                        },
                                    },
                                    left: {
                                        style: 'thin',
                                        color: {
                                            rgb: splitFirst === 'bold' ? '000000' : '',
                                        },
                                    },
                                    right: {
                                        style: 'thin',
                                        color: {
                                            rgb: splitFirst === 'bold' ? '000000' : '',
                                        },
                                    },
                                },
                            };
                        }
                    }
                    if (val === 'A2') {
                        wb.Sheets.Report[val].s = {
                            font: {
                                sz: 20,
                                bold: true,
                            },
                            fill: {
                                patternType: 'solid',
                                fgColor: {
                                    rgb: 'ffffff',
                                },
                            },
                        };
                    }
                }
            });
            let today = new Date();
            let dd = today.getDate();
            let mm = today.getMonth() + 1;
            const yyyy = today.getFullYear();
            if (dd < 10) {
                dd = `0${dd}`;
            }
            if (mm < 10) {
                mm = `0${mm}`;
            }
            today = `${mm}_${dd}_${yyyy}`;
            return XLSX.writeFile(wb, `demographics_${today}.xlsx`);
        }
        return null;
    };

    const getGapExport = () => {
        const table = document.getElementById('mytable');
        if (table) {
            const wb = XLSX.utils.table_to_book(table, { sheet: 'Report' });
            Object.keys(wb.Sheets.Report).forEach(val => {
                if (typeof wb.Sheets.Report[val] === 'object') {
                    const merge = wb.Sheets.Report[val].length ? wb.Sheets.Report[val] : [];
                    let split = [];
                    if (!merge.length) {
                        if (wb.Sheets.Report[val].v.length) {
                            split = wb.Sheets.Report[val].v.split('#');
                        } else {
                            split = ['', ''];
                        }
                    }
                    if (split.length > 0) {
                        const splitFirst = split[0];
                        const quartile = splitFirst.split('_')[1];
                        const {
                            bgcolor: background = colors.$white,
                            text_color: color = colors.$darkBlue100,
                        } = getStyleForGapExcel(quartile, gapStyle) || { text_color: '#000000', bgcolor: '#ffffff' };
                        // eslint-disable-next-line prefer-destructuring
                        wb.Sheets.Report[val].v = split[1];
                        let align = 'left';
                        if (split.length === 3 || split[3]) {
                            align = split[3] === 'left' ? 'left' : split[2];
                        } else {
                            align = splitFirst === 'bold' ? 'left' : 'center';
                        }
                        if (wb.Sheets.Report[val].v || splitFirst) {
                            wb.Sheets.Report[val].s = {
                                font: {
                                    color: { rgb: color.replace('#', '') },
                                    bold: splitFirst === 'bold' || splitFirst === 'header',
                                    sz: splitFirst === 'bold' || splitFirst === 'header' ? 14 : 12,
                                },
                                alignment: {
                                    vertical: align,
                                    horizontal: align,
                                    textRotation: split[3] === 'rotate' ? 90 : null,
                                },
                                fill: {
                                    patternType: 'solid',
                                    fgColor: {
                                        rgb: background.replace('#', ''),
                                    },
                                },
                                border: {
                                    bottom: {
                                        style: 'thin',
                                        color: {
                                            rgb: splitFirst === 'bold' ? '000000' : 'FFFFFF',
                                        },
                                    },
                                    top: {
                                        style: 'thin',
                                        color: {
                                            rgb: splitFirst === 'bold' ? '000000' : 'FFFFFF',
                                        },
                                    },
                                    left: {
                                        style: 'thin',
                                        color: {
                                            rgb: splitFirst === 'bold' ? '000000' : 'FFFFFF',
                                        },
                                    },
                                    right: {
                                        style: 'thin',
                                        color: {
                                            rgb: splitFirst === 'bold' ? '000000' : 'FFFFFF',
                                        },
                                    },
                                },
                            };
                        }
                        if (wb.Sheets.Report[val].v && split[3]) {
                            wb.Sheets.Report[val].s = {
                                font: {
                                    color: { rgb: color.replace('#', '') },
                                    bold: splitFirst === 'bold' || splitFirst === 'header',
                                    sz: splitFirst === 'bold' || splitFirst === 'header' ? 14 : 12,
                                },
                                fill: {
                                    patternType: 'solid',
                                    fgColor: {
                                        rgb: split[3] === 'rotate' ? background.replace('#', '') : split[3],
                                    },
                                },
                                alignment: {
                                    vertical: split[3] === 'rotate' ? 'center' : align,
                                    horizontal: split[3] === 'rotate' ? 'center' : align,
                                    textRotation: split[3] === 'rotate' ? 90 : null,
                                },
                                border: {
                                    bottom: {
                                        style: 'thin',
                                        color: {
                                            rgb: split[3],
                                        },
                                    },
                                    top: {
                                        style: 'thin',
                                        color: {
                                            rgb: split[3],
                                        },
                                    },
                                    left: {
                                        style: 'thin',
                                        color: {
                                            rgb: splitFirst === 'bold' ? '000000' : '',
                                        },
                                    },
                                    right: {
                                        style: 'thin',
                                        color: {
                                            rgb: splitFirst === 'bold' ? '000000' : '',
                                        },
                                    },
                                },
                            };
                        }
                        if (val === 'A2') {
                            wb.Sheets.Report[val].s = {
                                font: {
                                    sz: 20,
                                    bold: true,
                                },
                                fill: {
                                    patternType: 'solid',
                                    fgColor: {
                                        rgb: 'ffffff',
                                    },
                                },
                            };
                        }
                    }
                }
            });
            let today = new Date();
            let dd = today.getDate();
            let mm = today.getMonth() + 1;
            const yyyy = today.getFullYear();
            if (dd < 10) {
                dd = `0${dd}`;
            }
            if (mm < 10) {
                mm = `0${mm}`;
            }
            today = `${mm}_${dd}_${yyyy}`;
            return XLSX.writeFile(wb, `demographics_${today}.xlsx`);
        }
        return null;
    };

    const styleForLegend = () => {
        const scorelegendToUse = withBenchmark
            ? benchmarkStyles
            : styles.filter(({ style_for }) => style_for === 'score');
        const legendToShow = isGapHeatmap ? gapStyle : scorelegendToUse;
        return legendToShow;
    };

    const legendStyle = styleForLegend();

    useEffect(() => {
        if (isGapHeatmap) {
            getGapExport();
        } else {
            getExport();
        }
        setSelectedDownload('');
        // eslint-disable-next-line
    }, []);

    const renderLabel = () => {
        const index = demographics.map(item => item.code).indexOf(selectedDemographic);
        const { label = '' } = demographics[index] || -1;
        return index > -1 ? <th key={label}>5#{label}#left</th> : null;
    };

    const renderLabelName = demoIndex => {
        const demoCode = selectedDemographic[demoIndex];
        if (demoCode) {
            const index = demographics.map(item => item.code).indexOf(demoCode);
            const { title: label = '' } = demographics[index] || -1;
            return index > -1 ? `bold#${label}` : '';
        }
        return '#';
    };

    const renderDemographicFilter = () => {
        const { filters = [] } = apiParams;
        const labelArr = filters.map(demographicFilter => {
            let concatLabel = '';
            if (demographicFilter.includes(':')) {
                const splitDemographic = demographicFilter.split(':');
                const firstPart = splitDemographic[0];
                const index = demographics.map(item => item.code).indexOf(firstPart);
                const { label: demographicLabel = '', options = [] } = demographics[index] || -1;
                if (index > -1) {
                    const subLabels = options.filter(({ isSelected }) => isSelected).map(({ label }) => label);
                    concatLabel = demographicLabel + ' - ' + subLabels.join(',');
                }
            }
            return concatLabel;
        });
        if (!labelArr.length) {
            return <td>bold#NULL</td>;
        }
        return labelArr.map((label, i) => {
            return <td key={`${label}${i}`}>#{label}</td>;
        });
    };

    const overallColumn = (subItem, index) => {
        if (!overallView) {
            return subItem.slice(2, 4).map((item, i) => {
                const { quartile = -1, inclusion_quartile = -1, score = '' } = item;
                return (
                    <td key={`${score}${i}${getRandomValues()}`}>
                        {`${parseInt(quartile, 10) > -1 ? quartile : inclusion_quartile}#${score}`}
                    </td>
                );
            });
        }
        const { quartile = -1, inclusion_quartile = -1, score = '' } = overallArr.slice(3)[index] || {};
        const quart = quartile > -1 ? quartile : inclusion_quartile;
        return <td key={`${score}${index}${getRandomValues()}`}>{quart + '#' + score}</td>;
    };

    const getHeapMapValues = () => {
        return items.map((subItem, index) => {
            const { displayValue = '' } = subItem[0];
            return (
                <Fragment>
                    <tr key={`${displayValue}${index}}`}>
                        <td key={displayValue}>bold#{displayValue}</td>
                        {subItem &&
                            subItem
                                .filter((_, i) => ![0, 2, 3].includes(i))
                                .map(
                                    (
                                        { quartile = -1, inclusion_quartile = -1, displayValue: score = '' },
                                        subItemKey
                                    ) => {
                                        const quart = quartile > -1 ? quartile : inclusion_quartile;
                                        return (
                                            <>
                                                {subItemKey === 1 ? overallColumn(subItem, index) : null}
                                                <td key={`${score}${subItemKey}${getRandomValues()}`}>
                                                    {quart + '#' + score}
                                                </td>
                                                ;
                                            </>
                                        );
                                    }
                                )}
                    </tr>
                </Fragment>
            );
        });
    };

    const getGapHeapMapValues = () => {
        return items.map((subItem, index) => {
            const displayValue = renderLabelName(index + 1);
            const { gap: overallGap = '' } = overallArr.slice(2)[index] || {};
            const overallIndex = getGapColor(overallGap, gapStyle, true);
            return (
                <tr key={`${displayValue}${index}`}>
                    <td key={`${displayValue}bold${getRandomValues()}`}>{displayValue}</td>
                    {subItem &&
                        subItem
                            .filter((_, i) => ![0, 1].includes(i))
                            .map((score, subItemKey) => {
                                let quart = 0;
                                if (score >= 10 && score <= 15) {
                                    quart = 1;
                                } else if (score > 15) {
                                    quart = 2;
                                }
                                return (
                                    <Fragment key={subItemKey + 'fragment'}>
                                        {subItemKey === 0 ? (
                                            <td>
                                                quart_{overallIndex}#{overallGap}
                                            </td>
                                        ) : null}
                                        <td key={`sub${subItemKey}`}>{'quart_' + quart + '#' + score}</td>;
                                    </Fragment>
                                );
                            })}
                </tr>
            );
        });
    };
    return (
        <div>
            <div>
                <table id="mytable" className="hideTable">
                    <tbody>
                        <tr>
                            <th>bold#Inclusion Assessment</th>
                        </tr>
                    </tbody>
                    {!isGapHeatmap ? (
                        <tbody>
                            <tr>
                                <th>bold#Score Heatmap</th>
                            </tr>
                        </tbody>
                    ) : (
                        <tbody>
                            <tr>
                                <th>bold#Gap Heatmap</th>
                            </tr>
                        </tbody>
                    )}
                    {!isGapHeatmap ? (
                        <tbody>
                            <tr>
                                <th>bold#Demographic Selected Option</th>
                                {renderLabel()}
                            </tr>
                        </tbody>
                    ) : null}
                    <tbody>
                        <tr>
                            <td>bold#Demographic-filters</td>
                            {renderDemographicFilter()}
                        </tr>
                        <tr>
                            <td>bold#Benchmark-filters</td>
                            <td>5#{benchmarkName}#left</td>
                            {/* {renderBenchMarkFilters()} */}
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>bold#Threshold</td>
                            <td>5#{threshold}#left</td>
                        </tr>
                        <tr>
                            <td>bold#Respondents</td>
                            <td>5#{respondents}#left</td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr />
                        <tr />
                        <tr key="blank">
                            <th />
                            {!isGapHeatmap ? <th /> : null}
                            {overallView ? (
                                <th rowSpan={3}>bold#Overall Inclusion#center#rotate</th>
                            ) : (
                                <Fragment>
                                    <th rowSpan={3}>bold#Individual Experience#center#rotate</th>
                                    <th rowSpan={3}>bold#Enterprise Perception#center#rotate</th>
                                </Fragment>
                            )}
                            {['Outcomes', 'Practices'].map((val = '', i) => {
                                return (
                                    <th key={i} colSpan={i === 0 ? 6 : 17}>
                                        header#{val}#center#{i % 2 === 0 ? 'b0b0b0' : 'f0f0f0'}
                                    </th>
                                );
                            })}
                        </tr>
                        <tr key="blank">
                            <th />
                            {!isGapHeatmap ? <th /> : null}
                            {Object.keys(outcomeObj).map((val = '', i) => {
                                return (
                                    <th key={i} colSpan={outcomeObj[val]}>
                                        header#{val}#center#ffffff
                                    </th>
                                );
                            })}
                        </tr>
                        {!isGapHeatmap ? (
                            <tr key="blank">
                                <th>{N_SIZE}</th>
                                {header
                                    .filter((_, i) => ![1, 2].includes(i))
                                    .map((val = '', i) => {
                                        const split = val.includes('_') ? val.split('_')[1] : val;
                                        const headText = split.replace(/<sup>[\d]?<\/sup>/g, '');
                                        return (
                                            <>
                                                <th key={i}>bold#{headText}#center#rotate</th>
                                            </>
                                        );
                                    })}
                            </tr>
                        ) : (
                            <tr key="blank">
                                <th>Demographic groups</th>
                                {header
                                    .filter((_, i) => ![0, 1].includes(i))
                                    .map((val = '', i) => {
                                        const split = val.includes('_') ? val.split('_')[1] : val;
                                        const headText = split.replace(/<sup>[\d]?<\/sup>/g, '');
                                        return (
                                            <>
                                                <th key={`val${i}`}>bold#{headText}#center#rotate</th>
                                            </>
                                        );
                                    })}
                            </tr>
                        )}
                        {isGapHeatmap ? getGapHeapMapValues() : getHeapMapValues()}
                        <tr key="blank" />
                        <tr key="blank" />
                        <tr key="blank">
                            <td />
                            <td />
                            <td>bold#{isGapHeatmap ? 'Gap Classification' : 'Score Classification'}</td>
                        </tr>
                        {legendStyle.length &&
                            legendStyle.map(({ quartile = '', title }, i) => (
                                <tr key="blank">
                                    <td />
                                    <td />
                                    <td key={'legendquart' + i}>
                                        {!isGapHeatmap ? quartile || i : `quart_${i}`}##center
                                    </td>
                                    <td key={'legendtitle' + i}>bold#{title}</td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

Excel.propTypes = {
    header: PropTypes.array.isRequired,
    items: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    selectedDemographic: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    demographics: PropTypes.array.isRequired,
    benchmarks: PropTypes.array.isRequired,
    apiParams: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    isGapHeatmap: PropTypes.bool.isRequired,
    inclusionStyle: PropTypes.object.isRequired,
    overallArr: PropTypes.array.isRequired,
    setSelectedDownload: PropTypes.func.isRequired,
    benchmarkName: PropTypes.string.isRequired,
    threshold: PropTypes.number.isRequired,
    respondents: PropTypes.number.isRequired,
    overallView: PropTypes.bool.isRequired,
};

export default Excel;
