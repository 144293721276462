import React, { useEffect, useState, useMemo, Fragment } from 'react';
import c from 'classnames';
import PropTypes from 'prop-types';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import { MenuItem, Select, Tooltip } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

import { getStaticText } from '../../../../utils/constants';
import getStaticTextDiagnose from '../../constants';
// import ArrowLeft from '../../assets/ArrowLeft.svg';
// import ArrowRight from '../../assets/ArrowRight.svg';
// import ArrowUp from '../../assets/ArrowUp.svg';
// import ArrowDown from '../../assets/ArrowDown.svg';
import {
    getBenchmarkString,
    getColor,
    getFilterArray,
    getNegColor,
    getRandomValues,
    manipulateHeatmapTitle,
} from '../../../../utils/functions';
import FilterSvg from '../../assets/Filter.svg';
import ActiveFilters1 from '../../assets/ActiveFilters1.svg';
import colors from '../../../../sass/colors';
import Down from '../../assets/Down.svg';

function ResurveyHeatmap({
    filters,
    // ieHeatmap,
    // ieHeatmapFetched,
    sortIeHeatmap,
    // callHeatMap,
    defaultSettings,
    empExpResurveyHeatmapData,
    apiLoadingCount,
    errorInfo,
    // projects,
    addOnTab,
    setAddOnTabs,
    appliedFiltersInfo,
    toggleFilterView,
    pptDownload,
    activeResetFilter,
    // selectedDemographics,
    // setSelectedDemographic,
    // resurveyModal,
    // setReSurveyModal,
    setReport,
    empExpResurveyHeatmapDataFetched,
    getEmpExpResurveyHeatmapData,
    heatMapResurveyInfo,
    projectsInUse,
    apiParams,
    selectedIds,
    updateHeatMapInfo,
    getResIesCrosstabData,
    ieResCrossTabData,
    getHeatMapResurvey,
    resurveyOutcomeHeatMap,
}) {
    const {
        lang = '1033',
        report_type: surveyType = '',
        year: oldYear,
        ohid,
        demographic,
        emp_exp_resurvey_settings = '',
        threshold,
        emp_exp_resurvey_custom_delta = '',
        quartileColors = [],
    } = defaultSettings;
    const addOns = {
        // fill_threshold_with: demographic === '1' ? '-' : '',
        others: demographic === '3' ? 1 : 0,
    };
    const staticText = getStaticText(lang);
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const {
        // MAX_LENGTH_SUBSTRING,
        SORT_ASCENDING,
        SORT_DESCENDING,
        QUESTIONS_HEATMAP,
        // SLICE_IE_MAX_VALUE,
        // SLICE_IE_MIN_VALUE,
    } = staticTextDiagnose;
    const { SITE_TEXT, IND_EXP_SCORE_CLASSIFICATION, QUES_LEGEND_TYPE_HEATMAP } = staticText;
    const {
        N_SIZE,
        OHI_SCORE_TEXT,
        NUMBER_CAP_TEXT,
        NEGATIVE_PRACTICES,
        EE_PERCENT_FAVORABLE,
        EE_PERCENTILE,
        NOTE,
        IND_EXP_QUESTION_NOTE,
        // NUMBER_OF_RESPONDENTS_N,
        EMPLYEE_EXPEIENCE,
        // RESURVEY_TEXT,
        IND_EXP_LINKS,
        DOWNLOAD,
        FILTERS,
        STATISTICAL_DIFFERENCE,
        SCORECARD_RESURVEY_SIGNIFICANCE,
        COMPARISON_BUBBLES,
        EMPLOYEE_FACTORS_POSITIVE,
        EMPLOYEE_FACTORS_NEGATIVE,
        CUSTOM_DIFFERENCE,
        CUSTOM_DIFF_COLOR,
        customDiffLegends,
        EE_ENPS_FOOTER,
        EE_SS_FOOTNOTE,
    } = SITE_TEXT;
    const { demographics = [] } = filters[0] || {};
    const { header = [], items = [] } = empExpResurveyHeatmapData || {};
    const { selectedProjectIndex = '', heatMapDemo = '' } = heatMapResurveyInfo;
    const { mappedDemos = [] } = projectsInUse[selectedProjectIndex] || {};
    const demographicsInUse = demographics.filter(({ code }) => mappedDemos.indexOf(code) !== -1);
    // const [minSliceValue, setMinSliceValue] = useState(SLICE_IE_MIN_VALUE);
    // const [maxSliceValue, setMaxSliceValue] = useState(SLICE_IE_MAX_VALUE);
    // const [showLeftArrow, setShowLeftArrow] = useState(false);
    // const [showRightArrow, setShowRightArrow] = useState(false);
    const [sortOrder, setSortOrder] = useState({});

    const sigValToUse = SCORECARD_RESURVEY_SIGNIFICANCE;

    const headerToUse = useMemo(() => {
        if (!items.length) return [];
        return [...items.map(({ title = '' }) => title)];
        // eslint-disable-next-line
    }, [empExpResurveyHeatmapData]);

    const dataToUse = useMemo(() => {
        if (!items.length) return [];
        const newArray = [];
        const innerArray = [];
        items.forEach(({ items: subItem = [] }) => {
            innerArray.push(subItem);
        });
        // eslint-disable-next-line no-unused-vars
        header.forEach(i => {
            newArray.push([]);
        });
        for (let i = 0; i < innerArray.length; i += 1) {
            // const { items: subItems } = items
            for (let j = 0; j < header.length; j += 1) {
                newArray[j].push(innerArray[i][j]);
            }
        }

        return newArray;
        // eslint-disable-next-line
    }, [empExpResurveyHeatmapData]);

    const enpsData = useMemo(() => {
        const { items: enpsItems = [], header: headerObj = {} } = ieResCrossTabData || {};
        const { items: ohiItems = [] } = resurveyOutcomeHeatMap || {};
        const ohiScoreItem = ohiItems.map(({ items: subItem }) => subItem[1]);
        const headers = Object.keys(headerObj);
        if (!enpsItems.length) return [];
        const newArray = [];
        const innerArray = [];
        enpsItems.forEach(({ items: subItem = [] }) => {
            innerArray.push(subItem);
        });
        // eslint-disable-next-line no-unused-vars
        headers.forEach(i => {
            newArray.push([]);
        });
        for (let i = 0; i < innerArray.length; i += 1) {
            // const { items: subItems } = items
            for (let j = 0; j < headers.length; j += 1) {
                newArray[j].push(innerArray[i][j]);
            }
        }

        return {
            enpsHeatmap: [newArray[0], ohiScoreItem, newArray[3], newArray[2], newArray[1], newArray[4]],
            enpsHeader: [...enpsItems.map(({ title = '' }) => title)],
        };
        // eslint-disable-next-line
    }, [ieResCrossTabData]);

    const { enpsHeatmap = [], enpsHeader = [] } = enpsData || {};

    // const headerToUse = headerInclusion;

    const getHeatMapValues = arrItems => {
        return (
            <Fragment>
                <div>
                    {QUESTIONS_HEATMAP.map(({ children: child = [] }, dataI) => {
                        const itemsToIterate = [];
                        const titleArr = [];
                        child.forEach(elem => {
                            const index = header.indexOf(elem);
                            itemsToIterate.push(arrItems[index]);
                            titleArr.push(header[index]);
                        });
                        // const showCategoryI = dataI === 0 ? 1 : 0;
                        return itemsToIterate.map((subItems, parentI) => {
                            const title = manipulateHeatmapTitle(titleArr[parentI]);
                            // const isN = titleArr[parentI] === NUMBER_CAP_TEXT;
                            // const isNegativeTrait = NEGATIVE_PRACTICES.includes(title);
                            const sup = QUES_LEGEND_TYPE_HEATMAP[title] || '';
                            return (
                                <Fragment key={`${title}${parentI}0`}>
                                    {!dataI && !parentI && (
                                        <div className="sectionDiv headerWrapper">
                                            <ul>
                                                <li className="sectionContent headerList" />
                                                {/* <li className="contentList firstCol bold headerList">{title}</li> */}
                                            </ul>
                                        </div>
                                    )}
                                    <div
                                        className={c(
                                            {
                                                topPadding: parentI === 0 || (!dataI && parentI === child.length - 1),
                                                bottomPadding: parentI === child.length - 1,
                                                borderTop: parentI === 0,
                                                n: title === 'n',
                                            },
                                            'sectionDiv'
                                        )}
                                        key={title}
                                    >
                                        <ul>
                                            <Tooltip placement="top" arrow title={title} aria-label={title}>
                                                <li className="sectionContent">
                                                    {title}
                                                    <sup>{sup}</sup>
                                                </li>
                                            </Tooltip>
                                            {/* {subItems
                                                .slice(minSliceValue, maxSliceValue)
                                                .map(({ score: innerScore }, index) => {
                                                    const i = getColor(innerScore, [], false, true);
                                                    const iNeg = getNegColor(innerScore, [], false, true);
                                                    const {
                                                        positiveBg = '#ffffff',
                                                        negativeBg = '#ffffff',
                                                        color: textColor = '#000000',
                                                    } = IND_EXP_SCORE_CLASSIFICATION[isNegativeTrait ? iNeg : i] || {};
                                                    const bg = isNegativeTrait ? negativeBg : positiveBg;
                                                    const background = !isN ? bg : '#ffffff';
                                                    const color = !isN ? textColor : colors.$darkBlue200;
                                                    return (
                                                        <li
                                                            className={c({ nSize: !i }, 'contentList')}
                                                            key={`${innerScore}${index}${getRandomValues()}`}
                                                            style={{
                                                                border: `1px solid white`,
                                                                background,
                                                                color,
                                                            }}
                                                        >
                                                            {!isNaN(parseInt(innerScore, 10))
                                                                ? innerScore.toLocaleString('en-US')
                                                                : '-'}
                                                        </li>
                                                    );
                                                })} */}
                                        </ul>
                                    </div>
                                </Fragment>
                            );
                        });
                    })}
                </div>
                <div className="scrollSection">
                    {QUESTIONS_HEATMAP.map(({ children: child = [] }, dataI) => {
                        const itemsToIterate = [];
                        const titleArr = [];
                        child.forEach(elem => {
                            const index = header.indexOf(elem);
                            itemsToIterate.push(arrItems[index]);
                            titleArr.push(header[index]);
                        });
                        // console.log(headerToUse);
                        // const showCategoryI = dataI === 0 ? 1 : 0;
                        return itemsToIterate.map((subItems, parentI) => {
                            const title = manipulateHeatmapTitle(titleArr[parentI]);
                            const isN = titleArr[parentI] === NUMBER_CAP_TEXT;
                            const isNegativeTrait = NEGATIVE_PRACTICES.includes(title);
                            return (
                                <Fragment key={title + parentI + 1}>
                                    {!dataI && !parentI && (
                                        <div className="sectionDiv headerWrapper">
                                            <ul>
                                                {subItems.map((item, index) => {
                                                    const demoColName = headerToUse[index];
                                                    // titleArr[index].length > 25
                                                    //     ? titleArr[index].slice(0, 20) + '...'
                                                    //     : titleArr[index];
                                                    // console.log(demoColName)
                                                    return (
                                                        <Tooltip title={demoColName + index} key={`header_${index}`}>
                                                            <Fragment>
                                                                <li className="contentList headerList">
                                                                    {demoColName}
                                                                </li>
                                                                <li className="diffList">
                                                                    <div className="diff" />
                                                                </li>
                                                            </Fragment>
                                                        </Tooltip>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    )}
                                    <div
                                        className={c(
                                            {
                                                topPadding: parentI === 0 || (!dataI && parentI === child.length - 1),
                                                bottomPadding: parentI === child.length - 1,
                                                borderTop: parentI === 0,
                                                n: title === 'n',
                                            },
                                            'sectionDiv'
                                        )}
                                        key={title}
                                    >
                                        {/* {showCategoryI === parentI ? (
                                                <ul>
                                                    <li className="sectionContent headerContent">{categoryN}</li>
                                                </ul>
                                            ) : null} */}
                                        <ul>
                                            {/* <Tooltip placement="top" arrow title={title} aria-label={title}>
                                                <li className="sectionContent">{title}</li>
                                            </Tooltip> */}
                                            {subItems.map(
                                                ({ score = [], differences = [], significance = [] }, index) => {
                                                    const innerScore = score[0];
                                                    const i = getColor(innerScore, [], false, true);
                                                    const iNeg = getNegColor(innerScore, [], false, true);
                                                    const {
                                                        positiveBg = '#ffffff',
                                                        negativeBg = '#ffffff',
                                                        color: textColor = '#000000',
                                                    } = IND_EXP_SCORE_CLASSIFICATION[isNegativeTrait ? iNeg : i] || {};
                                                    const bg = isNegativeTrait ? negativeBg : positiveBg;
                                                    const background = !isN ? bg : '#ffffff';
                                                    const color = !isN ? textColor : colors.$darkBlue200;
                                                    const diffClass = ['neg', 'pos', 'neutral'];
                                                    const diffBg = diffClass[significance[0]];
                                                    return (
                                                        <Fragment>
                                                            <li
                                                                className={c(
                                                                    { bold: color === colors.$black100 },
                                                                    'contentList'
                                                                )}
                                                                key={`${innerScore}${index}${getRandomValues()}`}
                                                                style={{
                                                                    border: `1px solid white`,
                                                                    background,
                                                                    color,
                                                                }}
                                                            >
                                                                {!isNaN(parseInt(innerScore, 10))
                                                                    ? innerScore.toLocaleString('en-US')
                                                                    : '-'}
                                                            </li>
                                                            <li className="diffList">
                                                                <div className={c({ noBg: isN }, 'diff', diffBg)}>
                                                                    {!isN ? differences[0] : ''}
                                                                </div>
                                                            </li>
                                                        </Fragment>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    </div>
                                </Fragment>
                            );
                        });
                    })}
                </div>
            </Fragment>
        );
    };

    const getEnpsHeatMapValues = itemsToIterate => {
        const titleArr = [
            'n',
            'OHI Score',
            'Promoters (9 - 10)',
            'Passively Satisfied (7 - 8)',
            'Detractors (0 - 6)',
            'eNPS',
        ];
        return (
            <Fragment>
                <div>
                    {itemsToIterate.map((subItems, parentI) => {
                        const title = manipulateHeatmapTitle(titleArr[parentI]);
                        return (
                            <Fragment key={`${title}${parentI}1`}>
                                {!parentI && (
                                    <div className="sectionDiv headerWrapper">
                                        <ul>
                                            <li className="sectionContent headerList" />
                                        </ul>
                                    </div>
                                )}
                                <div
                                    className={c(
                                        {
                                            topPadding: parentI === 0 || parentI === 1,
                                            bottomPadding: subItems.length,
                                            borderTop: parentI === 0,
                                            n: title === 'n',
                                        },
                                        'sectionDiv'
                                    )}
                                    key={title}
                                >
                                    <ul>
                                        <Tooltip placement="top" arrow title={title} aria-label={title}>
                                            <li className="sectionContent enps">
                                                {title}
                                                {title === 'eNPS' ? <sup>4</sup> : null}
                                            </li>
                                        </Tooltip>
                                    </ul>
                                </div>
                            </Fragment>
                        );
                    })}
                </div>
                <div className="scrollSection">
                    {itemsToIterate.map((subItems, parentI) => {
                        const title = titleArr[parentI];
                        const isN = titleArr[parentI] === 'n';
                        const isEnps = [1, 5].includes(parentI);
                        return (
                            <Fragment key={title + parentI + 1}>
                                {!parentI && (
                                    <div className="sectionDiv headerWrapper">
                                        <ul>
                                            {subItems.map((item, index) => {
                                                const demoColName = enpsHeader[index];
                                                return (
                                                    <Tooltip title={demoColName + index} key={`header_${index}`}>
                                                        <Fragment>
                                                            <li className="contentList headerList">{demoColName}</li>
                                                            <li className="diffList">
                                                                <div className="diff" />
                                                            </li>
                                                        </Fragment>
                                                    </Tooltip>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                )}
                                <div
                                    className={c(
                                        {
                                            topPadding: parentI === 0 || parentI === 1,
                                            bottomPadding: subItems.length,
                                            borderTop: parentI === 0,
                                            n: title === 'n',
                                        },
                                        'sectionDiv'
                                    )}
                                    key={title}
                                >
                                    {/* {showCategoryI === parentI ? (
                                                <ul>
                                                    <li className="sectionContent headerContent">{categoryN}</li>
                                                </ul>
                                            ) : null} */}
                                    <ul>
                                        {/* <Tooltip placement="top" arrow title={title} aria-label={title}>
                                                <li className="sectionContent">{title}</li>
                                            </Tooltip> */}
                                        {subItems.map(
                                            (
                                                {
                                                    score = [],
                                                    differences = [],
                                                    significance = [],
                                                    difference = [],
                                                    significance: sig = '',
                                                    quartile = [],
                                                },
                                                index
                                            ) => {
                                                const innerScore = score[0];
                                                const quart = title === 'OHI Score' ? quartile[0] : 999;
                                                const {
                                                    background = '#ffffff',
                                                    color = colors.$darkBlue200,
                                                    border: borderColor = '#ffffff',
                                                } = quartileColors[quart] || {};
                                                const diffClass = ['neg', 'pos', 'neutral'];
                                                const diffBg = diffClass[significance[0] || sig];
                                                const border =
                                                    title === 'n' ? '1px solid #ffffff' : '1px solid #e6e6e6';
                                                const diff =
                                                    typeof difference === 'number' ? difference : differences[0];
                                                return (
                                                    <Fragment>
                                                        <li
                                                            className={c(
                                                                { bold: color === colors.$black100 },
                                                                'contentList'
                                                            )}
                                                            key={`${innerScore}${index}${getRandomValues()}`}
                                                            style={{
                                                                border,
                                                                background: `linear-gradient(135deg, ${borderColor} 25%, ${background} 25%)`,
                                                                color,
                                                            }}
                                                        >
                                                            {!isNaN(parseInt(innerScore, 10))
                                                                ? innerScore.toLocaleString('en-US')
                                                                : '-'}
                                                        </li>
                                                        <li className="diffList">
                                                            <div
                                                                className={c({ noBg: isN || !isEnps }, 'diff', diffBg)}
                                                                style={{
                                                                    color: isEnps ? '1px solid #000000' : 'inherit',
                                                                }}
                                                            >
                                                                {!isN ? diff : ''}
                                                            </div>
                                                        </li>
                                                    </Fragment>
                                                );
                                            }
                                        )}
                                    </ul>
                                </div>
                            </Fragment>
                        );
                    })}
                </div>
            </Fragment>
        );
    };

    const renderRightOptions = () => {
        // const { demographics = [] } = filters[0] || {};
        return (
            <div className="rightOptions">
                <span>
                    <Select
                        IconComponent={KeyboardArrowDownSharpIcon}
                        value={heatMapDemo}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                        }}
                        onChange={({ target: { value: code } }) => {
                            updateHeatMapInfo({ heatMapDemo: code });
                        }}
                    >
                        {demographicsInUse.map(({ label, code }) => {
                            return (
                                <MenuItem classes={{ root: 'demographicOptions' }} key={code} value={code}>
                                    {label}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </span>
            </div>
        );
    };

    const sortData = type => {
        if (type === N_SIZE || type === OHI_SCORE_TEXT) {
            setSortOrder({ [type]: sortOrder[type] === SORT_ASCENDING ? SORT_DESCENDING : SORT_ASCENDING });
            const arr = [];
            items.map(({ items: subItems = [] }, i) => {
                const { score = '' } = type === N_SIZE ? subItems[0] || {} : subItems[1] || {};
                arr.push(score + '#' + i);
                return items;
            });
            const sortArr =
                sortOrder[type] === 1
                    ? arr.slice(1, arr.length).sort((a, b) => a.split('#')[0] - b.split('#')[0])
                    : arr.slice(1, arr.length).sort((a, b) => b.split('#')[0] - a.split('#')[0]);
            sortArr.unshift(arr[0]);
            const indexArr = [];
            sortArr.map(val => {
                return indexArr.push(arr.indexOf(val));
            });
            const output = indexArr.map(i => items[i]);
            sortIeHeatmap(output);
        }
    };

    // useEffect(() => {
    //     if (!ieHeatmapFetched) {
    //         callHeatMap(demographics[0].code);
    //     }
    //     // eslint-disable-next-line
    // }, [ieHeatmapFetched]);

    // useEffect(() => {
    //     if (items.length - 1 >= maxSliceValue) {
    //         setShowRightArrow(true);
    //     } else {
    //         setShowRightArrow(false);
    //     }
    //     // eslint-disable-next-line
    // }, [maxSliceValue, items]);
    useEffect(() => {
        if (!empExpResurveyHeatmapDataFetched && !apiLoadingCount) {
            const { year: newYear, ohid: newOhid = '' } = projectsInUse[selectedIds[0]] || {};
            const { heatMapDemo: newDemo } = heatMapResurveyInfo;
            const filtersArray = [];
            const benchmarksArray = [];
            filters.forEach(({ benchmarks: newB = [], demographics: newD = [] }, indexToUse) => {
                const benchMarkYear = indexToUse ? newYear : oldYear;
                const preString = indexToUse ? '||' : '';
                const endString = indexToUse ? '' : '||';
                const filtersObtained = getFilterArray(newD, preString, endString);
                filtersObtained.forEach(result => {
                    filtersArray.push(result);
                });
                if (newB.length) {
                    benchmarksArray.push(`${getBenchmarkString(newB)}@${benchMarkYear}`);
                }
            });
            const { lang: lng, year } = apiParams;
            const { key: comparison_type = '1' } =
                COMPARISON_BUBBLES.find(
                    ({ list_key }) => list_key.toLowerCase() === emp_exp_resurvey_settings.toLowerCase()
                ) || {};
            getEmpExpResurveyHeatmapData({
                ...addOns,
                filters: filtersArray,
                benchmark_str: benchmarksArray.join(','),
                // benchmark_str: '1001:1@2024,1001:1@2023',
                ohid: `${ohid},${newOhid}`,
                pid: '',
                type: 'practice',
                demographic: newDemo,
                page: 1,
                comparison_type,
                columns: 50,
                lang: lng,
                year,
            });
            getResIesCrosstabData({
                pid: '',
                ohid: `${ohid},${newOhid}`,
                x_variable: newDemo,
                y_variable: 'demo_IENPS1',
                filters: filtersArray,
                lang: lng,
                page: 1,
                columns: 99999,
                ...addOns,
                threshold,
                exclude_x_variable: [],
                comparison_type,
            });
            getHeatMapResurvey({
                ...apiParams,
                ...addOns,
                filters: filtersArray,
                benchmark_str: benchmarksArray.join(','),
                ohid: `${ohid},${newOhid}`,
                pid: '',
                type: 'outcome',
                demographic: newDemo,
                page: 1,
                comparison_type: '1',
            });
        }
    }, [empExpResurveyHeatmapDataFetched, apiLoadingCount]);

    return (
        <Fragment>
            <div className="scoreBoard addOns clearfix">
                <div className="indExpBoard">
                    <div className="breadCrumb">
                        <span data-testid="setScoreFromBuBtn" onClick={() => setReport(0)}>
                            Heatmap
                        </span>{' '}
                        {' > '}
                        {'Resurvey'}
                    </div>
                    <h2 className="clearfix">
                        <div className="rightScorecardHeader">
                            <span data-testid="setBackFromBuBtn" onClick={() => setReport(0)}>
                                <ArrowBack />
                                Resurvey {EMPLYEE_EXPEIENCE} <sup>1</sup>
                            </span>
                            <ul className="navScoreList">
                                <li data-testid="getPptBtn" onClick={pptDownload} className="resurveyImg">
                                    <img src={Down} alt="" />
                                    <span className="lineHead upperHead">{DOWNLOAD}</span>
                                </li>
                                <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                                    <img src={activeResetFilter ? ActiveFilters1 : FilterSvg} alt="" />
                                    <span className="filter">{FILTERS}</span>
                                </li>
                            </ul>
                        </div>
                    </h2>
                    {appliedFiltersInfo()}

                    <div className={`optionWrapper ${addOnTab === 1 ? 'stickyTop' : ''}`}>
                        <div>
                            <ul className="leftNav">
                                {IND_EXP_LINKS.map((tabName, index) => {
                                    return (
                                        <li
                                            data-testid={tabName}
                                            key={tabName}
                                            className={c({ activeLink: addOnTab === index })}
                                            onClick={() => setAddOnTabs(index)}
                                        >
                                            {tabName}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        {renderRightOptions()}
                    </div>
                    <Fragment>
                        <div className="heatmapBoard clearfix clearPadding">
                            <div className="optionWrapper">
                                <section className={c({ wrapperShadow: headerToUse.length <= 6 }, 'sectionWrapper')}>
                                    <div className="outcomeNames">
                                        {Object.keys(QUESTIONS_HEATMAP).map(elem => {
                                            const { title: name = '', children = [] } = QUESTIONS_HEATMAP[elem];
                                            const len = children.includes('N') ? 1 : children.length;
                                            return (
                                                <div style={{ height: `${len * 7 + 2}vh` }}>
                                                    <span>{name}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className="contentWrapper resurveyWrapper clearfix">
                                        <div
                                            className={c({ noPadding: headerToUse.length <= 6 }, 'mainContent')}
                                            id="myHeader"
                                        >
                                            {dataToUse.length ? getHeatMapValues(dataToUse) : null}
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                        {enpsHeatmap.length ? (
                            <h2 className="enpsHeader">Employee Net Promoter Score(eNPS) Heat map</h2>
                        ) : null}
                        <div className="heatmapBoard clearfix clearPadding">
                            <div className="optionWrapper">
                                <section className={c({ wrapperShadow: enpsHeader[0]?.length <= 6 }, 'sectionWrapper')}>
                                    <div className="outcomeNames" />
                                    <div className="contentWrapper resurveyWrapper clearfix">
                                        <div
                                            className={c({ noPadding: enpsHeader[0]?.length <= 6 }, 'mainContent')}
                                            id="myHeader"
                                        >
                                            {enpsHeatmap.length ? getEnpsHeatMapValues(enpsHeatmap) : null}
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                        {!apiLoadingCount && !errorInfo && (
                            <div className="note">
                                <b>{NOTE}:</b>
                                {surveyType === 'percent favorable' ? <div>1. {EE_PERCENT_FAVORABLE}</div> : ''}
                                {surveyType === 'percentile' ? <div>1. {EE_PERCENTILE}</div> : ''}
                                {IND_EXP_QUESTION_NOTE[0]}
                                <br />
                                {IND_EXP_QUESTION_NOTE[1]}
                                <div>4. {EE_ENPS_FOOTER}</div>
                                {emp_exp_resurvey_settings.toLowerCase() === 'statistical significance' ? (
                                    <div>5. {EE_SS_FOOTNOTE} </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        )}
                    </Fragment>
                </div>
            </div>
            <ul className="fixedScoreCardSurvey">
                <ul className="benchmark clearfix">
                    <li>{EMPLOYEE_FACTORS_POSITIVE} : </li>
                    {IND_EXP_SCORE_CLASSIFICATION.map(({ title, positiveBg: background }) => (
                        <li key={title}>
                            <span style={{ background, border: 'none' }} />
                            {title}
                        </li>
                    ))}
                </ul>
                <ul className="benchmark clearfix">
                    <li>{EMPLOYEE_FACTORS_NEGATIVE} : </li>
                    {IND_EXP_SCORE_CLASSIFICATION.map(({ titleNeg: title, negativeBg: background }) => (
                        <li key={title}>
                            <span style={{ background, border: 'none' }} />
                            {title}
                        </li>
                    ))}
                </ul>
                {emp_exp_resurvey_settings.toLowerCase() === 'statistical significance' ? (
                    <ul className="benchmark clearfix change">
                        <li>{STATISTICAL_DIFFERENCE} : </li>
                        {sigValToUse.map(({ title, imgSrc, sup = '' }) => (
                            <li key={title}>
                                <span>
                                    <img src={imgSrc} alt="" />
                                </span>
                                {title} <sup>{sup}</sup>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <ul className="benchmark clearfix change">
                        <li>{CUSTOM_DIFFERENCE} : </li>
                        {customDiffLegends(emp_exp_resurvey_custom_delta).map((title, i) => (
                            <li key={title}>
                                <span
                                    style={{
                                        background: CUSTOM_DIFF_COLOR[i],
                                        height: '12px',
                                        width: '12px',
                                        borderRadius: '50%',
                                    }}
                                />
                                {title}
                            </li>
                        ))}
                    </ul>
                )}
            </ul>
        </Fragment>
    );
}

ResurveyHeatmap.propTypes = {
    filters: PropTypes.array.isRequired,
    apiParams: PropTypes.object.isRequired,
    empExpResurveyHeatmapDataFetched: PropTypes.bool.isRequired,
    // callHeatMap: PropTypes.func.isRequired,
    sortIeHeatmap: PropTypes.func.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    errorInfo: PropTypes.number.isRequired,
    empExpResurveyHeatmapData: PropTypes.object.isRequired,
    addOnTab: PropTypes.number.isRequired,
    setAddOnTabs: PropTypes.func.isRequired,
    getEmpExpResurveyHeatmapData: PropTypes.func.isRequired,
    pptDownload: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    activeResetFilter: PropTypes.bool.isRequired,
    appliedFiltersInfo: PropTypes.func.isRequired,
    // selectedDemographics: PropTypes.bool.isRequired,
    // setSelectedDemographic: PropTypes.func.isRequired,
    setReport: PropTypes.func.isRequired,
    heatMapResurveyInfo: PropTypes.object.isRequired,
    projectsInUse: PropTypes.array.isRequired,
    selectedIds: PropTypes.array.isRequired,
    updateHeatMapInfo: PropTypes.func.isRequired,
    getResIesCrosstabData: PropTypes.func.isRequired,
    ieResCrossTabData: PropTypes.object.isRequired,
    getHeatMapResurvey: PropTypes.func.isRequired,
    resurveyOutcomeHeatMap: PropTypes.object.isRequired,
};

export default ResurveyHeatmap;
