import React, { Fragment, useState, useEffect } from 'react';
import moment from 'moment';
import c from 'classnames';
import { Accordion, AccordionDetails, Tooltip, Select, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
// import * as XLSX from 'xlsx';
import XLSX from 'xlsx-color';
import { KeyboardArrowDownSharp } from '@material-ui/icons';
import DisclaimerWarn from '../assets/DisclaimerWarning1.svg';
import { getStaticText } from '../../../utils/constants';
import './index.scss';
import { USNumFormat } from '../../../utils/functions';
import CloseIcon from '../../../assets/Close.svg';
import ArrowBackward from '../../Diagnose/assets/Small-left.svg';
import ArrowForward from '../../Diagnose/assets/Small-right.svg';
import ArrowDoubleBack from '../../Diagnose/assets/Double-arrow-left.svg';
import ArrowDoubleForward from '../../Diagnose/assets/Double-arrow-right.svg';

const dummyData = [
    { keyName: 'total_survey', value: 'Total surveys' },
    { keyName: 'total_responded', value: 'Total respondents' },
    { keyName: 'approve_survey', value: 'Approved surveys' },
    { keyName: 'approve_responded', value: 'Approved respondents' },
];

const CREATE_CUSTOM_BM = [
    // {
    //     key: 'selectid',
    //     title: '',
    //     width: '4vw',
    // },

    {
        key: 'action',
        title: 'Actionable',
        width: '10vw',
        cursor: 'pointer',
    },
    {
        key: 'benchmark_status',
        title: 'Benchmark Status',
        width: '10vw',
        cursor: 'pointer',
    },
    {
        key: 'uniquesurveyreferencecode',
        title: 'OHI ID',
        width: '8vw',
        cursor: 'pointer',
    },
    {
        key: 'confirmitsurveyid',
        title: 'PID',
        width: '12vw',
        cursor: 'pointer',
    },
    {
        key: 'surveyclientname',
        title: 'Organization Name',
        width: '20vw',
        cursor: 'pointer',
    },
    {
        key: 'resurveyid',
        title: 'Resurvey ID',
        width: '8vw',
        cursor: 'pointer',
    },
    {
        key: 'isgsdbfilled',
        title: 'GSOD Filled',
        width: '6vw',
        cursor: 'pointer',
    },
    {
        key: 'legalcheck',
        title: 'Legal Check',
        width: '6vw',
        cursor: 'pointer',
    },
    {
        key: 'isresurvey',
        title: 'Resurvey',
        width: '7vw',
        cursor: 'pointer',
    },
    {
        key: 'type',
        title: 'Type',
        width: '7vw',
        cursor: 'pointer',
    },
    {
        key: 'benchmarkingallowed',
        title: 'Benchmarking Allowed',
        width: '7vw',
        cursor: 'pointer',
    },
    {
        key: 'totalcomplete',
        title: 'Complete Responses',
        width: '6vw',
        align: 'right',
        cursor: 'pointer',
    },
    {
        key: 'bm_year',
        title: 'Benchmarking Year',
        width: '8vw',
        align: 'center',
        cursor: 'pointer',
    },
    {
        key: 'quartile',
        title: 'Global Quartile',
        width: '10vw',
        cursor: 'pointer',
    },
    {
        key: 'mckinseysectorlevel1',
        title: 'Sector Level 1',
        width: '20vw',
        cursor: 'pointer',
    },
    {
        key: 'mckinseysectorlevel2',
        title: 'Sector Level 2',
        width: '20vw',
        cursor: 'pointer',
    },
    {
        key: 'region',
        title: 'Region',
        width: '10vw',
        cursor: 'pointer',
    },
    {
        key: 'subregion1',
        title: 'Subregion (high level)',
        width: '15vw',
        cursor: 'pointer',
    },
    {
        key: 'subregion2',
        title: 'Subregion (detailed)',
        width: '15vw',
        cursor: 'pointer',
    },
    {
        key: 'country',
        title: 'Country',
        width: '15vw',
        cursor: 'pointer',
    },
    {
        key: 'close_date',
        title: 'Closed Date',
        width: '10vw',
        cursor: 'pointer',
    },
];

const RealtimeDatabase = ({
    defaultSettings,
    defaultRealTimeYear,
    realtimeTotalData,
    realtimeSurveyList,
    getRealtimeApproval,
    getRealtimeReject,
    getRealtimeSurveyList,
    // hideSecondPopup,
    secondPopupVisible,
    getRealtimeAnalytics,
    // new
    realtimeToggleFilterView,
    renderRealtimeFilterApplied,
    // renderFilterApplied,
}) => {
    const { lang } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { SCORECARD_NOTE, DISCLAIMER } = SITE_TEXT || {};
    const [popupContent, setPopupContent] = useState(false);
    const [realtimeId, setRealtimeId] = useState();
    const [showPopupBtn, setShowPopupBtn] = useState('');
    const [page, setPage] = useState(1);
    const [min, setMinSlice] = useState(0);
    const [max, setMaxSlice] = useState(5);
    const [entryCount, setEntryCount] = useState(10);

    useEffect(() => {
        if (secondPopupVisible) {
            if (defaultRealTimeYear) {
                getRealtimeAnalytics(`year:${defaultRealTimeYear}`);
            }
            if (defaultRealTimeYear) {
                getRealtimeSurveyList(`year:${defaultRealTimeYear}`);
            }
        }
        // eslint-disable-next-line
    }, [defaultRealTimeYear, secondPopupVisible]);

    const processSurveyList = list => {
        return list.map(item => {
            let action = '';
            switch (item.benchmark_status) {
                case 'pending':
                    action = 'APPROVE/REJECT';
                    break;
                case 'approved':
                    action = 'REJECT';
                    break;
                case 'rejected':
                    action = 'APPROVE';
                    break;
                default:
                    action = '';
            }
            return { ...item, action };
        });
    };

    const getStatusStyles = status => {
        switch (status) {
            case 'pending':
                return { backgroundColor: '#fff9d7', color: '#d19b3e', padding: '6px', borderRadius: '8px' };
            case 'approved':
                return { backgroundColor: '#defae6', color: '#027f26', padding: '6px', borderRadius: '8px' };
            case 'rejected':
                return { backgroundColor: '#fde7e7', color: '#d72c3b', padding: '6px', borderRadius: '8px' };
            default:
                return { backgroundColor: 'white', color: 'black', padding: '6px', borderRadius: '8px' };
        }
    };

    const processedSurveyList = processSurveyList(realtimeSurveyList);

    const lastPage = Math.ceil(processedSurveyList.length / entryCount);

    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(realtimeSurveyList);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Survey Data');
        XLSX.writeFile(workbook, 'SurveyData.xlsx');
    };

    const handleCellClick = (id, key) => {
        setShowPopupBtn(key);
        setRealtimeId(id);
        setPopupContent(true);
    };

    const closePopup = () => {
        setPopupContent(false);
    };

    const handleApprove = () => {
        getRealtimeApproval({
            year: defaultRealTimeYear,
            id: [realtimeId],
        });
        closePopup();
    };

    const handleReject = () => {
        getRealtimeReject({
            year: defaultRealTimeYear,
            id: [realtimeId],
        });
        closePopup();
    };

    // const handleRefresh = () => {
    //     if (defaultRealTimeYear) {
    //         getRealtimeAnalytics(`year:${defaultRealTimeYear}`);
    //     }
    //     if (defaultRealTimeYear) {
    //         getRealtimeSurveyList(`year:${defaultRealTimeYear}`);
    //     }
    //     hideSecondPopup();
    // };
    return (
        <div className="realTimeData">
            <div className="realtimeMainHead">
                <div className="realTimeHead">
                    <div className="realTimeHeading">Realtime Database</div>
                    <div className="nRes">{`${'WIP Year'} : ${defaultRealTimeYear}`}</div>
                </div>
                <div className="FilterSection">
                    <div type="button" onClick={downloadExcel}>
                        Download
                    </div>
                    <div type="button" onClick={() => realtimeToggleFilterView(true)}>
                        Filter
                    </div>
                </div>
            </div>
            <div className="realtimeFilter">{renderRealtimeFilterApplied}</div>
            <div className="realtimeSection">
                <div className="cardContainer">
                    {Object.keys(realtimeTotalData)
                        .slice(0, 2)
                        .map((key, index) => {
                            const realTimeData = dummyData.slice(0, 2).find(item => item.keyName === key).value;
                            return (
                                <div className="cardContent">
                                    <div className="cardHead">{realTimeData}</div>
                                    <div className="cardSubHead">{realtimeTotalData[key].toLocaleString('en-US')}</div>
                                </div>
                            );
                        })}
                </div>

                <div className="cardContainer">
                    {Object.keys(realtimeTotalData)
                        .slice(2, 4)
                        .map((key, index) => {
                            const realTimeData1 = dummyData.slice(2, 4).find(item => item.keyName === key).value;
                            return (
                                <div className="cardContent">
                                    <div className="cardHead">{realTimeData1}</div>
                                    <div className="cardSubHead">{realtimeTotalData[key].toLocaleString('en-US')}</div>
                                </div>
                            );
                        })}
                </div>
            </div>

            <div className="userReportsContent xScroll">
                <Accordion classes={{ root: 'accordionRoot' }} key="table" expanded>
                    <AccordionDetails classes={{ root: 'clearfix' }}>
                        <table className="userSection">
                            <tbody className="userContent">
                                <thead className="headerSection">
                                    {CREATE_CUSTOM_BM.map(({ key, title, width, cursor }) => (
                                        <th className="headerHeading" key={key} style={{ width }}>
                                            {title}
                                            {/* {key === 'selectid' ? (
                                                <FormControlLabel
                                                    classes={{
                                                        root: c('optionItem', {
                                                            // width80: optionNo !== '',
                                                        }),
                                                    }}
                                                    control={
                                                        <Checkbox
                                                            onChange={({ target }) =>
                                                                // handleCheck(obj.id, target.checked)
                                                                handleCheckAll(target.checked)
                                                            }
                                                            checked={
                                                                selected.length === customBmList.length &&
                                                                selected.length > 0
                                                            }
                                                            // disabled={}
                                                            // inputProps={{
                                                            //     'data-testid': `ChildCheck${childLabel}`,
                                                            // }}
                                                        />
                                                    }
                                                />
                                            ) : null} */}
                                        </th>
                                    ))}
                                </thead>

                                {!!processedSurveyList.length &&
                                    processedSurveyList.slice((page - 1) * entryCount, page * entryCount).map(obj => {
                                        const { id = '', action = '', benchmark_status } = obj || {};
                                        return (
                                            <>
                                                <tr key={id} className="userDataSection">
                                                    {CREATE_CUSTOM_BM.map(({ key, width, align = '', cursor }) => {
                                                        let dataValue =
                                                            key === 'close_date'
                                                                ? moment(obj[key]).format('MMM-YYYY')
                                                                : USNumFormat(obj[key]);
                                                        if (key === 'bm_year') {
                                                            dataValue = obj[key];
                                                        }
                                                        if (key === 'isgsdbfilled') {
                                                            dataValue = dataValue === '1' ? 'true' : 'false';
                                                        }
                                                        if (key === 'legalcheck') {
                                                            dataValue = dataValue === '1' ? 'true' : 'false';
                                                        }
                                                        const styles =
                                                            key === 'benchmark_status'
                                                                ? getStatusStyles(benchmark_status)
                                                                : {};
                                                        return (
                                                            <td
                                                                className="userTableContent ellipsisPro"
                                                                key={key}
                                                                onClick={
                                                                    key === 'action'
                                                                        ? () => handleCellClick(id, action)
                                                                        : null
                                                                }
                                                                style={{
                                                                    width,
                                                                    textAlign: align,
                                                                    cursor,
                                                                }}
                                                            >
                                                                <Tooltip
                                                                    title={dataValue}
                                                                    arrow
                                                                    classes={{ popper: 'cbmTooltip' }}
                                                                >
                                                                    <span style={styles}>{dataValue}</span>
                                                                </Tooltip>
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            </>
                                        );
                                    })}
                                {/* {!customBmList.length ? (
                                    <div className="emptyList">
                                        <div>
                                            <h3>No survey list available</h3>
                                            <img src={CloudImage} alt="" />
                                        </div>
                                    </div>
                                ) : null}
                                {surveyListLoading ? <LoaderToast /> : null} */}
                            </tbody>
                        </table>
                    </AccordionDetails>
                </Accordion>
                {!!processedSurveyList.length && processedSurveyList.length > entryCount ? (
                    <div className="pagination">
                        <span
                            onClick={() => {
                                if (min - 4 <= 0) return null;
                                setPage(min - 4);
                                setMaxSlice(max - 5);
                                setMinSlice(min - 5);
                                return null;
                            }}
                            className={c({ inActive: page === 1 }, 'arrowBtn')}
                        >
                            <img src={ArrowDoubleBack} alt="" />
                        </span>
                        <span
                            onClick={() => {
                                setPage(page - 1);
                                if (page - 1 <= min) {
                                    setMaxSlice(max - 5);
                                    setMinSlice(min - 5);
                                }
                            }}
                            className={c({ inActive: page === 1 }, 'arrowBtn')}
                        >
                            <img src={ArrowBackward} alt="" />
                        </span>
                        {Array(lastPage)
                            .fill(1)
                            .map((_, i) => i + 1)
                            .slice(min, max)
                            .map(index => {
                                return (
                                    <span
                                        key={index + 1}
                                        className={c({ activePage: page === index })}
                                        onClick={() => {
                                            setPage(index);
                                        }}
                                    >
                                        {index}
                                    </span>
                                );
                            })}
                        <span
                            onClick={() => {
                                setPage(page + 1);
                                if (page >= max) {
                                    setMaxSlice(max + 5);
                                    setMinSlice(min + 5);
                                }
                            }}
                            className={c({ inActive: page === lastPage }, 'arrowBtn')}
                        >
                            <img src={ArrowForward} alt="" />
                        </span>
                        <span
                            onClick={() => {
                                if (min + 5 > lastPage) return null;
                                setPage(min + 6);
                                setMinSlice(min + 5);
                                setMaxSlice(max + 5);
                                return null;
                            }}
                            className={c({ inActive: page === lastPage }, 'arrowBtn')}
                        >
                            <img src={ArrowDoubleForward} alt="" />
                        </span>
                        <span className="resultsPerPage">
                            Results Per Page{' '}
                            <span>
                                <Select
                                    IconComponent={KeyboardArrowDownSharp}
                                    value={entryCount}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        },
                                        transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 'left',
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                    onChange={({ target }) => {
                                        setEntryCount(target.value);
                                    }}
                                >
                                    {[10, 11, 12].map(pageItem => {
                                        return (
                                            <MenuItem
                                                classes={{ root: 'demographicOptions' }}
                                                key={pageItem}
                                                value={pageItem}
                                            >
                                                {pageItem}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </span>
                        </span>
                    </div>
                ) : null}

                {popupContent && (
                    <div className="popup">
                        <div className="popupContent">
                            <div className="realtimeCloseIcon">
                                <img src={CloseIcon} alt="" className="closeIcon" onClick={closePopup} />
                            </div>
                            <h2>Approve or reject the survey.</h2>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adiptscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua.
                            </p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            {showPopupBtn === 'APPROVE/REJECT' && (
                                <div className="realtimePopupButton">
                                    <div type="button" onClick={handleApprove} className="approveBtn">
                                        Approve
                                    </div>
                                    <div type="button" onClick={handleReject} className="rejectBtn">
                                        Reject
                                    </div>
                                </div>
                            )}
                            {showPopupBtn !== 'APPROVE/REJECT' && (
                                <div className="realtimePopupButton">
                                    {showPopupBtn === 'APPROVE' && (
                                        <button type="button" onClick={handleApprove} className="approveBtn">
                                            Approve
                                        </button>
                                    )}
                                    {showPopupBtn === 'REJECT' && (
                                        <button type="button" onClick={handleReject} className="approveBtn">
                                            Reject
                                        </button>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {/* {secondPopupVisible && (
                    <div className="popup">
                        <div className="popupContent">
                            <div className="realtimeCloseIcon">
                            </div>
                            <h2>Refresh Database</h2>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adiptscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua.
                            </p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            <div className="realtimePopupButton">
                                <button type="button" onClick={handleRefresh} className="approveBtn">
                                    Refresh
                                </button>
                            </div>
                        </div>
                    </div>
                )} */}
            </div>
        </div>
    );
};

RealtimeDatabase.propTypes = {
    defaultSettings: PropTypes.object.isRequired,
    defaultRealTimeYear: PropTypes.number.isRequired,
    realtimeTotalData: PropTypes.array.isRequired,
    realtimeSurveyList: PropTypes.array.isRequired,
    getRealtimeApproval: PropTypes.func.isRequired,
    getRealtimeReject: PropTypes.func.isRequired,
    // hideSecondPopup: PropTypes.func.isRequired,
    secondPopupVisible: PropTypes.bool.isRequired,
    getRealtimeAnalytics: PropTypes.func.isRequired,
    getRealtimeSurveyList: PropTypes.func.isRequired,
    realtimeToggleFilterView: PropTypes.func.isRequired,
    renderRealtimeFilterApplied: PropTypes.string.isRequired,
    // renderFilterApplied: PropTypes.string.isRequired,
};

export default RealtimeDatabase;
