import React, { Fragment, useEffect, useState } from 'react';
import c from 'classnames';
import PropTypes from 'prop-types';
import { MenuItem, Select, Tooltip } from '@material-ui/core';
import { ArrowDownward, ArrowUpward, ChangeHistory } from '@material-ui/icons';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import { getStaticText, IA_NON_COMPARABLE_PRACTICES } from '../../../utils/constants';
import {
    checkHighLowOverall,
    deltaToDisplay,
    excludeFromGapText,
    getBenchmarkColor,
    getColor,
    getDeltaColor,
    getDemographicName,
    getDemographicPageCount,
    getExcludeGapString,
    getFiltersText,
    getGapColor,
    getIncBenchmarkStyles,
    getInclusionStyle,
    getPptParamsForLegend,
    getPptStyles,
    getRandomValues,
    getRowData,
    getRowDataText,
    quartileDelta,
    // quartileTitle,
    sortChildren,
} from '../../../utils/functions';
import {
    CRH_CLIENT_OHI12922,
    RES_SLICE_VALUE as SLICE_VALUE,
    MAX_LENGTH_SUBSTRING,
    resurveyHeatMapChunk,
    PPT_RES_PARAMS,
    // PPT_PARAMS_CRH_OHI14649,
    DEFAULT_STYLE,
} from '../constants';
import ArrowRight from '../../Diagnose/assets/ArrowRight.svg';
import ArrowLeft from '../../Diagnose/assets/ArrowLeft.svg';
import DownloadIcon from '../../Diagnose/assets/Download.svg';
import './index.scss';
import AuthStore from '../../../common/AuthStore';
import Loader from '../../../assets/Loader_Blue.svg';

const ResurveyDemographicTable = ({
    scoreData,
    demographics,
    dropDownValue,
    scoreColor,
    withBenchmark,
    resDemo,
    isPulse,
    prevYear,
    year,
    selectedSubTab,
    resurveyDropdown,
    resurveyDropdownGroup,
    setResurveyDropDownValue,
    callResurveyGapData,
    excludeOption,
    setAvgGap,
    showAvgGap,
    resurveyGapDataFetched,
    resurveyAllGapDataFetched,
    resurveyGapData,
    resurveyAllGapData,
    previousOhid,
    currentOhid,
    setResDemo,
    iaApiParams,
    defaultSettings,
    checkHighLow,
    getPptData,
    benchmarkName,
    inclusionqbyq,
    allIaGapData,
    resurveyDemographic,
    exGap,
    demoNSize,
    overallView,
    setOverallView,
    allGapDataFetched,
    isDiffSurveyV,
    benchmarkVersions,
}) => {
    const staticText = getStaticText();
    const { SITE_TEXT } = staticText;
    const {
        SCORE_CLASSIFICATION_TEXT,
        GAP_CLASSIFICATION_TEXT,
        // CHANGE_CLASSIFICATION,
        OVERALL_INCLUSION,
        SELECT_DEMO_GROUP,
        INFO_TEXT,
        DOWNLOAD,
        LARGEST_GAP,
        AVG_GAP,
        VIEW_BY,
        N_SIZE_TEXT,
        ALL_PPT_TEXT,
        NUMBER_TEXT,
        SIGNIFICANCE,
        QUARTILE_MOVE,
        SHOW_OVERALL,
        QUARTILE_DIFFERENCE,
        VALUE_BASED_DIFFERENCE,
        GAP_BASED_DIFFERENCE,
        QUARTILE_MOVE_LEGEND_TXT_IA,
        // VALUES_BASED_LEGEND_TEXT,
        GAP_LEGEND_TEXT,
    } = SITE_TEXT;
    const { pptAccessToken = '' } = AuthStore;
    const {
        with_benchmark = true,
        benchmarks = '',
        filters = [],
        resurveyDemoFilters: resurveyFilters = [],
    } = iaApiParams;
    const { exclude_inclusion_gap: defaultExGap = '', inclusion_resurvey_settings = '', quartileColors = [] } =
        defaultSettings || {};
    const {
        headerArr = [],
        finalArr = [],
        gapArr = [],
        allGapArr = [],
        scoreForGap = [],
        overallArr: overallScores = [],
    } = scoreData;
    const { resurveyHeader = [], resurveyOverall = [], resScoreForGap = [], resurveyAllGap = [] } = resDemo;
    const { gap: resGap = {}, qbyq: resQbyqObjMain = {} } = resurveyGapData[previousOhid] || {};
    const { qbyq = {} } = resurveyGapData[currentOhid] || {};
    const { qbyq: resHeatmapQbyq = {} } = demoNSize || {};
    const { size: demoPrevNsize } = resHeatmapQbyq || {};
    const sliceValue = finalArr.length;
    const [page, updatePage] = useState(1);
    const [isSticky, setSticky] = useState(false);
    const [rearrangedArr, setRearrangedArr] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [nsizeArr, setNSizeArr] = useState([]);
    const [resHeaderArr, setResHeaderArr] = useState([]);
    const [resurveyArr, setResurveyArr] = useState([]);
    // const { label = '' } = demographics.filter(({ code }) => code === dropDownValue)[0] || {};
    const {
        score: scoreStyles = [],
        gap: gapStyle = [],
        styles: styleArr = [],
        pptBenchmarkStyles = [],
        deltaStyles = [],
        gapDeltaStyles = [],
    } = scoreColor;
    const benchmarkStyles = getIncBenchmarkStyles(quartileColors);
    const styleToUse = !withBenchmark ? scoreStyles : benchmarkStyles;
    // const pptStyle = withBenchmark ? pptBenchmarkStyles : styleArr;
    const pptStyle = getPptStyles(with_benchmark, styleArr, pptBenchmarkStyles, quartileColors);
    const isQuartileMovement = inclusion_resurvey_settings.toLowerCase() === 'quartile movement';
    const isCRHClientOHI12922 = currentOhid === CRH_CLIENT_OHI12922;
    // const isCRH_OHI14649 = currentOhid === 'OHI14649';
    const pptParams = PPT_RES_PARAMS;

    useEffect(() => {
        if (isCRHClientOHI12922 === true) {
            setOverallView(false);
        }
        // eslint-disable-next-line
    }, [isCRHClientOHI12922]);
    const scoreToDisplay = score => {
        return score === 0 ? '-' : score;
    };

    const showScoreData = (scoreDataArr, arr2) => {
        const arr = [];
        const header = scoreDataArr.map(item => item[0].displayValue);
        const nSizes = scoreDataArr.map(item => item[1].displayValue);
        const gapHeaders = [selectedSubTab === 0 ? 'Dimension' : 'Outcome', 'Gap', ...header];
        const nSizeKeys = ['N-size', '', ...nSizes];
        setNSizeArr(nSizeKeys);
        setHeaders(gapHeaders);
        for (let i = 1; i < headerArr.length; i += 1) {
            const arr1 = [];
            arr1.push({ displayValue: headerArr[i] });
            arr1.push({ displayValue: arr2[i + 1] });
            for (let j = 0; j < scoreDataArr.length; j += 1) {
                arr1.push(scoreDataArr[j][i + 1]);
            }
            arr.push(arr1);
        }
        setRearrangedArr(arr);
    };

    const resurveyScoreData = (scoreDataArr, arr2) => {
        const arr = [];
        const nSizes = scoreDataArr.map(item => item[1].displayValue);
        const nSizeKeys = ['', '', ...nSizes];
        setResHeaderArr(nSizeKeys);
        for (let i = 1; i < resurveyHeader.length; i += 1) {
            const arr1 = [];
            arr1.push({ displayValue: arr2[i + 1] });
            for (let j = 0; j < scoreDataArr.length; j += 1) {
                arr1.push(scoreDataArr[j][i + 1]);
            }
            arr.push(arr1);
        }
        setResurveyArr(arr);
    };

    const getDeltaIndex = val => {
        const i = val > 0 ? 0 : 1;
        const index = val === 0 ? 2 : i;
        return index;
    };

    const getDeltaElement = (value, type, quartileDel, noBg) => {
        const valueToUse = quartileDel !== -100 ? quartileDel : value;
        const styleAr = type === 'gap' ? gapDeltaStyles : deltaStyles;
        const isNoComparison = isQuartileMovement && quartileDel === -100;
        if (value === '-' || noBg || isNoComparison) {
            return (
                <div className="delta quartileBased" style={{ color: '#000000', background: '#ffffff' }}>
                    <div>{value}</div>
                </div>
            );
        }

        if (type === 'gap') {
            const index = getDeltaIndex(value);
            const { text_color } = styleAr[index];
            return (
                <div className="gap delta" style={{ color: text_color }}>
                    <div>
                        {value > 0 ? (
                            <div>
                                <span>{value}</span>
                                <span>
                                    <ArrowUpward />
                                </span>
                            </div>
                        ) : (
                            <div>
                                <span>{value}</span>
                                {value < 0 ? (
                                    <span>
                                        <ArrowDownward />
                                    </span>
                                ) : null}
                            </div>
                        )}
                    </div>
                </div>
            );
        }

        const index = getDeltaIndex(valueToUse);
        const { bgcolor } = styleAr[index];

        return (
            <div className="delta quartileBased" style={{ background: bgcolor, color: '#ffffff' }}>
                <div>
                    <div>
                        <span>{value}</span>
                    </div>
                </div>
            </div>
        );
    };
    const renderHeaders = (headersArr, type, resHeader) => {
        const newResNSize =
            type === 'nsize' ? resHeader.slice((page - 1) * SLICE_VALUE + 2, page * SLICE_VALUE + 2) : [];
        if (type === 'nsize') {
            return headersArr.map((val, i) => {
                const displayValue =
                    val.length > MAX_LENGTH_SUBSTRING ? `${val.substring(0, MAX_LENGTH_SUBSTRING) + '...'}` : val;
                return (
                    <div className="resurveyList headerList">
                        <li className="headerList resurveyHeader" data-testid="arrowup">
                            {displayValue}
                        </li>
                        <li className="headerList resurveyHeader" data-testid="arrowup">
                            {newResNSize[i]}
                        </li>
                        <li className="headerList resurveyHeader" data-testid="arrowup" />
                    </div>
                );
            });
        }
        return headersArr.map((val, i) => {
            const displayValue =
                val.length > MAX_LENGTH_SUBSTRING ? `${val.substring(0, MAX_LENGTH_SUBSTRING) + '...'}` : val;
            return (
                <Tooltip key={`${val}${i}`} placement="top" arrow title={type === 'nsize' ? '' : val} aria-label={val}>
                    <div className="headerList resurveyList" data-testid="arrowup">
                        <li className="headerList">{displayValue}</li>
                        <li className="headerList" />
                    </div>
                </Tooltip>
            );
        });
    };

    const listenToScroll = () => {
        const headerId = document.getElementById('myHeader');
        const sticky = headerId.offsetTop;
        if (window.pageYOffset > sticky && window.pageYOffset > 320) {
            setSticky(true);
        } else {
            setSticky(false);
        }
    };

    useEffect(() => {
        const { resurveyArr: resArr = [], resurveyGapArr = [] } = resDemo;
        if (gapArr.length && resurveyGapArr.length) {
            showScoreData(finalArr.slice(0, sliceValue), gapArr);
            resurveyScoreData(resArr.slice(0, sliceValue), resurveyGapArr);
        }
        window.addEventListener('scroll', listenToScroll);
        return () => {
            window.removeEventListener('scroll', listenToScroll);
        };
        // eslint-disable-next-line
    }, [finalArr, scoreData]);

    const scoresHeaders = (arr, type, resHeader) => {
        const newArr = arr.slice((page - 1) * SLICE_VALUE + 2, page * SLICE_VALUE + 2);
        if (type === 'resHeader') {
            return (
                <Fragment>
                    <li className="headerList headerLeft resHeader" />
                    <div className="resurveyList headerList alignLeft">
                        <li className="headerList resurveyHeader" data-testid="arrowup">
                            {prevYear}
                        </li>
                        <li className="headerList resurveyHeader" data-testid="arrowup">
                            {year}
                        </li>
                        <li className="headerList resurveyHeader delta" data-testid="arrowup">
                            <ChangeHistory />
                        </li>
                    </div>
                    {newArr.map(() => (
                        <div className="resurveyList headerList resHeader">
                            <li className="headerList resurveyHeader" data-testid="arrowup">
                                {prevYear}
                            </li>
                            <li className="headerList resurveyHeader" data-testid="arrowup">
                                {year}
                            </li>
                            <li className="headerList resurveyHeader delta" data-testid="arrowup">
                                <ChangeHistory />
                            </li>
                        </div>
                    ))}
                </Fragment>
            );
        }
        return (
            <Fragment>
                <li className="headerList headerLeft" data-testid="arrowup">
                    <div>{arr[0]}</div>
                    {type === 'header' && <div>{selectedSubTab === 0 ? 'Outcome' : 'Practice'}</div>}
                </li>

                {type === 'nsize' ? (
                    <div className="resurveyList headerList">
                        <li className="headerList resurveyHeader" data-testid="arrowup" />
                        <li className="headerList resurveyHeader" data-testid="arrowup" />
                        <li className="headerList resurveyHeader" data-testid="arrowup" />
                    </div>
                ) : (
                    <div className="headerList resurveyList" data-testid="arrowup">
                        <li className="headerList">{arr[1]}</li>
                        <li className="headerList" />
                    </div>
                )}
                {renderHeaders(newArr, type, resHeader)}
            </Fragment>
        );
    };

    const scoresTable = scoreTableData => {
        const { arr, overallArr, overallGapArr, resOverallArr, resurveyArrObj, resurveyAllGapObj } =
            scoreTableData || {};
        if (!arr.length) return null;
        return arr.map((item, i) => {
            const { displayValue: displayValue0 = '' } = item[0];
            const { displayValue: displayValue1 = '' } = item[1];
            const { displayValue: gapDisplayValue = '' } = resurveyArrObj[i] ? resurveyArrObj[i][0] : {};
            const resPageData = resurveyArrObj[i].slice((page - 1) * SLICE_VALUE + 1, page * SLICE_VALUE + 1) || {};
            const gapVal = displayValue1;
            const resGapVal = gapDisplayValue;
            const gapDelta = deltaToDisplay(displayValue1, gapDisplayValue);
            const gapStyles = gapVal !== '-' ? getGapColor(displayValue1, gapStyle) : DEFAULT_STYLE;
            const resurveyGapStyles = resGapVal !== '-' ? getGapColor(gapDisplayValue, gapStyle) : DEFAULT_STYLE;
            const {
                score: qbyqScore = '',
                quartile: overallQuart = -1,
                inclusion_quartile: incQuart = -1,
                key = '',
            } = overallArr[i];
            const { bgcolor: qbyqScoreBg = '', text_color: qbyqScoreTxtClr = '' } =
                qbyqScore !== '-'
                    ? getInclusionStyle(incQuart, overallQuart, benchmarkStyles, styleArr)
                    : DEFAULT_STYLE;
            const {
                display_score: overallQbyqScore = '',
                quartile: ovQbyqQuart = -1,
                inclusion_quartile: ovQbyqIncQuart = -1,
            } = overallScores[0];
            const { score: resQbyqScore = '', quartile: resQbyqQuart, inclusion_quartile: resIncQbyqQuart } =
                resOverallArr[i] || {};
            const qbyqScoreDelta = deltaToDisplay(qbyqScore, resQbyqScore, qbyqScore);
            const qbyqQuartileDelta = quartileDelta(incQuart, overallQuart, resIncQbyqQuart, resQbyqQuart);
            // getIncStyle(overallQuart, quartileColors)   getIncStyle(overallQuart, quartileColors)
            const { bgcolor: overallQbyqBg = '', text_color: overallQbyqText = '' } =
                overallQbyqScore !== '-'
                    ? getInclusionStyle(ovQbyqIncQuart, ovQbyqQuart, benchmarkStyles, styleArr)
                    : DEFAULT_STYLE;
            const overallAllGap = overallScores[1].score;
            const overallAllGapStyle = getGapColor(overallAllGap, gapStyle);
            const overallAllGapDelta = deltaToDisplay(overallAllGap, resurveyAllGap[1], overallAllGap, isPulse);
            const gapOverall = overallScores[2].score;
            const gapOverallStyle = getGapColor(gapOverall, gapStyle);
            const {
                displayValue: resOverall = '',
                quartile: resOvQuartile = -1,
                inclusion_quartile: resOvIncQuartile = -1,
            } = resurveyOverall[1];
            const resOverallDelta = deltaToDisplay(overallQbyqScore, resOverall, overallQbyqScore, isPulse);
            const resOvQuartileDel = quartileDelta(ovQbyqIncQuart, ovQbyqQuart, resOvIncQuartile, resOvQuartile);
            const resGapOverall = resurveyOverall[0].displayValue;
            const resGapOverallStyle = getGapColor(resGapOverall, gapStyle);
            const gapOverallDelta = deltaToDisplay(gapOverall, resGapOverall, gapOverall, true);

            const { gap: overallGap = '' } = overallGapArr[i] || {};
            const noBg = IA_NON_COMPARABLE_PRACTICES.includes(key) && isDiffSurveyV;
            const { bgcolor: overallGapBg = '', text_color: overallGapTextColor = '' } =
                overallGap !== 0 ? getGapColor(overallGap, gapStyle) : DEFAULT_STYLE;
            const allGapDelta = deltaToDisplay(overallGap, resurveyAllGapObj[i], qbyqScore);
            return (
                <Fragment key={displayValue0}>
                    {i === 0 && overallView && (
                        <div className="sectionDiv" key={`${item}${i}_ov`}>
                            <ul className={c({ tabLeft: true })}>
                                <li
                                    key={`${displayValue0}${i}_0`}
                                    className={c('sectionContent', {
                                        outcomeScorePractice: i < 9 && i > 2,
                                        practiceScorePractice: i >= 9,
                                        levelScorePractice: i <= 2,
                                    })}
                                >
                                    {' '}
                                </li>
                                <li
                                    key={`${displayValue0}${i}_1`}
                                    className={c('sectionContent1', {
                                        outcomeScorePractice: i < 9 && i > 2,
                                        practiceScorePractice: i >= 9,
                                        levelScorePractice: i <= 2,
                                    })}
                                >
                                    <div className="outcomesScore">{OVERALL_INCLUSION}</div>
                                    <div
                                        className="gapScores"
                                        style={{
                                            borderRadius: '50%',
                                            background: overallQbyqBg,
                                            color: overallQbyqText,
                                        }}
                                    >
                                        <div>{overallQbyqScore}</div>
                                    </div>
                                    <div
                                        className="allGap"
                                        style={{
                                            borderRadius: '20%',
                                            background: overallAllGapStyle.bgcolor,
                                            color: overallAllGapStyle.text_color,
                                        }}
                                    >
                                        {/* <div>{overallAllGap}</div> */}
                                        {!allGapDataFetched ? (
                                            <Tooltip title="Gap Scores are Loading. Please wait.">
                                                <div className="spinLoader">
                                                    <img src={Loader} alt="" />
                                                </div>
                                            </Tooltip>
                                        ) : (
                                            <div>{overallAllGap}</div>
                                        )}
                                    </div>
                                    <div className="changeIcon">
                                        {getDeltaElement(
                                            resOverallDelta,
                                            'delta',
                                            isQuartileMovement ? resOvQuartileDel : -100
                                        )}
                                        {/* {!isQuartileMovement
                                            ? getDeltaElement(resOverallDelta, 'delta', resOverallDelta === '-')
                                            : getQuartDeltaElement(resOverallDelta, 'delta', resOvQuartileDel)} */}
                                    </div>
                                    <div className="changeIcon">{getDeltaElement(overallAllGapDelta, 'gap', -100)}</div>
                                </li>
                                <div className="resurveyList">
                                    <li
                                        style={{
                                            background: resGapOverallStyle.bgcolor,
                                            color: resGapOverallStyle.text_color,
                                        }}
                                        className={c(
                                            {
                                                smallText: resGapVal === '-',
                                            },
                                            'contentList'
                                        )}
                                        key={`${gapDisplayValue}${getRandomValues()}`}
                                    >
                                        {resGapOverall}
                                    </li>
                                    <li
                                        className={c({ smallText: gapVal === '-' }, 'contentList  gapHeader')}
                                        style={{
                                            background: gapOverallStyle.bgcolor,
                                            color: gapOverallStyle.text_color,
                                        }}
                                    >
                                        {gapOverall}
                                    </li>

                                    <li
                                        className="contentList deltaList"
                                        key={`${gapDisplayValue}${2}${getRandomValues()}`}
                                    >
                                        {getDeltaElement(gapOverallDelta, 'gap', -100)}
                                    </li>
                                </div>
                                {overallScores
                                    .slice((page - 1) * SLICE_VALUE + 3, page * SLICE_VALUE + 3)
                                    .map((elem, index) => {
                                        const {
                                            displayValue: resDisplayValue = '',
                                            inclusion_quartile: resIaQuart = -1,
                                            quartile: resQuart = -1,
                                        } =
                                            resurveyOverall.slice((page - 1) * SLICE_VALUE + 2, page * SLICE_VALUE + 2)[
                                                index
                                            ] || {};
                                        const resScoreval = resDisplayValue;
                                        const resStyleObj =
                                            resScoreval !== '-'
                                                ? getInclusionStyle(resIaQuart, resQuart, benchmarkStyles, styleArr)
                                                : DEFAULT_STYLE;
                                        const {
                                            bgcolor: resBgColor = '',
                                            text_color: resTextColor = '',
                                        } = resStyleObj || {
                                            bgcolor: '',
                                            text_color: '',
                                        };

                                        const {
                                            score: displayValue = '',
                                            inclusion_quartile = -1,
                                            quartile = -1,
                                        } = elem;
                                        const scoreVal = scoreToDisplay(displayValue);
                                        const styleObj = getInclusionStyle(
                                            inclusion_quartile,
                                            quartile,
                                            benchmarkStyles,
                                            styleArr
                                        );
                                        const { bgcolor: bgColor = '', text_color: textColor = '' } = styleObj || {
                                            bgcolor: '',
                                            text_color: '',
                                        };
                                        const overallDelta = deltaToDisplay(scoreVal, resScoreval, scoreVal);
                                        const overallQuartDelta = quartileDelta(
                                            inclusion_quartile,
                                            quartile,
                                            resIaQuart,
                                            resQuart
                                        );
                                        return (
                                            <div className="resurveyList">
                                                <li
                                                    style={{ background: resBgColor, color: resTextColor }}
                                                    className="contentList"
                                                    key={`${displayValue}${index}${getRandomValues()}`}
                                                >
                                                    {resScoreval}
                                                </li>
                                                <li
                                                    style={{ background: bgColor, color: textColor }}
                                                    className="contentList"
                                                    key={`${displayValue}${index}${getRandomValues()}`}
                                                >
                                                    {scoreVal}
                                                </li>
                                                <li
                                                    className="contentList deltaList"
                                                    key={`${scoreVal}}${index}${getRandomValues()}`}
                                                >
                                                    {/* {isQuartileMovement
                                                        ? getQuartDeltaElement(overallDelta, 'delta', overallQuartDelta)
                                                        : getDeltaElement(overallDelta, 'delta', false)} */}
                                                    {getDeltaElement(
                                                        overallDelta,
                                                        'delta',
                                                        isQuartileMovement ? overallQuartDelta : -100,
                                                        noBg
                                                    )}
                                                </li>
                                            </div>
                                        );
                                    })}
                            </ul>
                        </div>
                    )}
                    <div className="sectionDiv" key={`${item}${i}`}>
                        <ul className={c({ tabLeft: true })}>
                            <li
                                key={`${displayValue0}${i}`}
                                className={c('sectionContent', {
                                    outcomeScorePractice: i < 9 && i > 2,
                                    practiceScorePractice: i >= 9,
                                    levelScorePractice: i <= 2,
                                })}
                            >
                                {displayValue0.includes('_') ? displayValue0.split('_')[0] : displayValue0}
                            </li>
                            <li
                                key={`${displayValue0}${i}_1`}
                                className={c('sectionContent1', {
                                    outcomeScorePractice: i < 9 && i > 2,
                                    practiceScorePractice: i >= 9,
                                    levelScorePractice: i <= 2,
                                })}
                            >
                                <div className="outcomesScore">{displayValue0.split('_')[1]}</div>
                                <div
                                    className="gapScores"
                                    style={{
                                        borderRadius: '50%',
                                        background: qbyqScoreBg,
                                        color: qbyqScoreTxtClr,
                                    }}
                                >
                                    <div>{qbyqScore}</div>
                                </div>
                                <div className="changeIcon">
                                    {/* {!isQuartileMovement
                                        ? getDeltaElement(qbyqScoreDelta, 'delta', qbyqScoreDelta === '-', noBg)
                                        : getQuartDeltaElement(qbyqScoreDelta, 'delta', qbyqQuartileDelta, noBg)} */}
                                    {getDeltaElement(
                                        qbyqScoreDelta,
                                        'delta',
                                        isQuartileMovement ? qbyqQuartileDelta : -100,
                                        noBg
                                    )}
                                </div>
                                <div
                                    className="allGap"
                                    style={{
                                        borderRadius: '20%',
                                        background: overallGapBg,
                                        color: overallGapTextColor,
                                    }}
                                >
                                    {!allGapDataFetched ? (
                                        <Tooltip title="Gap Scores are Loading. Please wait.">
                                            <div className="spinLoader">
                                                <img src={Loader} alt="" />
                                            </div>
                                        </Tooltip>
                                    ) : (
                                        <div>{overallGap}</div>
                                    )}
                                </div>
                                <div className="changeIcon">{getDeltaElement(allGapDelta, 'gap', -100)}</div>
                            </li>
                            <div className="resurveyList">
                                <li
                                    style={{
                                        background: resurveyGapStyles.bgcolor,
                                        color: resurveyGapStyles.text_color,
                                    }}
                                    className="contentList"
                                    key={`${gapDisplayValue}${getRandomValues()}`}
                                >
                                    {resGapVal}
                                </li>
                                <li
                                    className="contentList  gapHeader"
                                    style={{
                                        background: gapStyles.bgcolor,
                                        color: gapStyles.text_color,
                                    }}
                                >
                                    {gapVal}
                                </li>

                                <li
                                    className="contentList deltaList"
                                    key={`${gapDisplayValue}${2}${getRandomValues()}`}
                                >
                                    {getDeltaElement(gapDelta, 'gap', -100)}
                                </li>
                            </div>
                            {item.slice((page - 1) * SLICE_VALUE + 2, page * SLICE_VALUE + 2).map((elem, index) => {
                                const { displayValue: scoreVal = '', inclusion_quartile = -1, quartile = -1 } = elem;
                                const {
                                    displayValue: resScoreVal = '',
                                    inclusion_quartile: resIncQuart = -1,
                                    quartile: resQuart = -1,
                                } = resPageData[index] || {};
                                const { bgcolor: bgColor = '', text_color: textColor = '' } =
                                    scoreVal !== '-'
                                        ? getInclusionStyle(inclusion_quartile, quartile, benchmarkStyles, styleArr)
                                        : DEFAULT_STYLE;
                                const { bgcolor: resBgColor = '', text_color: resTextColor = '' } =
                                    resScoreVal !== '-'
                                        ? getInclusionStyle(resIncQuart, resQuart, benchmarkStyles, styleArr)
                                        : DEFAULT_STYLE;
                                const delta = deltaToDisplay(scoreVal, resScoreVal);
                                const quartDelta = quartileDelta(inclusion_quartile, quartile, resIncQuart, resQuart);

                                return (
                                    <div className="resurveyList">
                                        <li
                                            style={{ background: resBgColor, color: resTextColor }}
                                            className="contentList"
                                            key={`${resScoreVal}${index}${getRandomValues()}`}
                                        >
                                            {resScoreVal}
                                        </li>
                                        <li
                                            style={{ background: bgColor, color: textColor }}
                                            className="contentList"
                                            key={`${scoreVal}${index}${getRandomValues()}`}
                                        >
                                            {scoreVal}
                                        </li>
                                        <li
                                            className="contentList deltaList"
                                            key={`${resScoreVal}${index}${getRandomValues()}`}
                                        >
                                            {/* {!isQuartileMovement
                                                ? getDeltaElement(delta, 'delta', delta === '-', noBg)
                                                : getQuartDeltaElement(delta, 'delta', quartDelta, noBg)} */}
                                            {getDeltaElement(
                                                delta,
                                                'delta',
                                                isQuartileMovement ? quartDelta : -100,
                                                noBg
                                            )}
                                        </li>
                                    </div>
                                );
                            })}
                        </ul>
                    </div>
                </Fragment>
            );
        });
    };

    const renderScoreTable = () => {
        const obj = {
            arr: selectedSubTab === 0 ? rearrangedArr.slice(2, 8) : rearrangedArr.slice(8),
            overallArr: selectedSubTab === 0 ? scoreForGap.slice(2, 8) : scoreForGap.slice(8),
            overallGapArr: selectedSubTab === 0 ? allGapArr.slice(0, 6) : allGapArr.slice(6),
            resOverallArr: selectedSubTab === 0 ? resScoreForGap.slice(2, 8) : resScoreForGap.slice(8),
            resurveyArrObj: selectedSubTab === 0 ? resurveyArr.slice(2, 8) : resurveyArr.slice(8),
            resurveyAllGapObj: selectedSubTab === 0 ? resurveyAllGap.slice(2, 8) : resurveyAllGap.slice(8),
        };

        const levelObj = {
            arr: rearrangedArr.slice(0, 2),
            overallArr: scoreForGap.slice(0, 2),
            overallGapArr: allGapArr.slice(23),
            resOverallArr: resScoreForGap.slice(0, 2),
            resurveyArrObj: resurveyArr.slice(0, 2),
            resurveyAllGapObj: resurveyAllGap[0],
        };
        return (
            <Fragment>
                {!overallView ? scoresTable(levelObj) : null}
                {scoresTable(obj)}
            </Fragment>
        );
    };

    const getFilterSlideObj = (filterText, Nsize) => {
        const oldFilters = { name: 'filtertext_old', value: getFiltersText(resurveyFilters, resurveyDemographic) };
        return {
            identify_shape_name: 'checkme',
            identify_shape_value: 'filters_applied',
            is_copy: true,
            is_delete: true,
            data: [
                {
                    name: 'filtertext',
                    value: filterText,
                },
                {
                    name: 'exclude_inclusion_gap_text',
                    value: `${excludeFromGapText(getExcludeGapString(defaultExGap, exGap), demographics)}`,
                },
                oldFilters,
                {
                    name: 'Source',
                    value: `${currentOhid} (${NUMBER_TEXT} = ${Nsize}) | ${previousOhid}  (${NUMBER_TEXT} = ${demoPrevNsize})`,
                },
                {
                    name: 'checkme',
                    is_delete: true,
                },
            ],
        };
    };

    const getDataForPpt = () => {
        const { qbyq: mainQbyq = {} } = inclusionqbyq || {};
        const { level_scores: mainLevel = {}, size: mainSize = '' } = mainQbyq;
        const benchmarkDetail = {
            name: 'benchmark_detail',
            value: `${benchmarkName} (${benchmarkVersions[0]})`,
        };
        const pptParamsForLegend = getPptParamsForLegend(benchmarks);
        const { overall = {} } = mainLevel;
        const { display_score: ovScore, quartile: ovQuartile = -1 } = overall;

        const { gapArr: qbyqAllGapArr = [], overallArr = [], allGapArr: overallGapArr = [] } = scoreData;
        const { resurveyGapArr = [], resurveyArr: resurveyArray = [] } = resDemo;
        const outComeSlides = [];
        const enterpriseSlides = [];
        const individualSlides = [];
        const resGroupToShow = [
            {
                name: `${inclusion_resurvey_settings === QUARTILE_MOVE ? 'value' : 'quart'}_group`,
                is_delete: true,
            },
        ];
        const commonEle = [
            {
                name: 'Source',
                value: `${currentOhid} (${NUMBER_TEXT} = ${mainSize}) | ${previousOhid}  (${NUMBER_TEXT} = ${demoPrevNsize})`,
            },
            {
                name: 'checkme',
                is_delete: true,
            },
            ...resGroupToShow,
            ...pptParamsForLegend,
            benchmarkDetail,
        ];
        const { levels_gap: allGapLevels = {} } = allIaGapData || {};
        const { overall: allGapOverallObj = '' } = allGapLevels || {};
        const allGapOverall = showAvgGap ? allGapOverallObj.avg_gap : allGapOverallObj.gap;
        const allGapOverallStyle = getGapColor(allGapOverall, gapStyle, true) + 12;
        commonEle.push({
            name: 'demo_gap',
            value: allGapOverall,
            style: allGapOverallStyle,
        });
        if (showAvgGap) {
            commonEle.push({
                name: 'largest_gap',
                is_delete: true,
            });
        } else {
            commonEle.push({
                name: 'average_gap',
                is_delete: true,
            });
        }

        const heatmapKeys = Object.keys(qbyq);
        const heatMapChunk = resurveyHeatMapChunk;
        const lvRow = overallView ? 0 : -2;
        for (let i = 0; i < heatmapKeys.length; i += heatMapChunk) {
            const chunkQbyq = heatmapKeys.slice(i, i + heatMapChunk);
            const dataChunk = finalArr.slice(i, i + heatMapChunk);
            const resDataChunk = resurveyArray.slice(i, i + heatMapChunk);
            const outcomeTable = [];
            const enterpriseTable = [];
            const individualTable = [];
            chunkQbyq.forEach((key, kIndex) => {
                const { display_name = '', size = '' } = qbyq[key];
                const { size: prevSize = '' } = resQbyqObjMain[key] || {};
                const percent = `${Math.round((size * 100) / mainSize)}%`;
                const { display_score: score = '', quartile: quart0 = -1 } =
                    overallArr.slice(i + 3, i + heatMapChunk + 3)[kIndex] || {};
                const { score: overallGapValue = '' } = overallArr[2];
                const resurveyOverallData = resurveyOverall.slice(i + 2, i + heatMapChunk + 2);
                const overallIncVal = score;
                const overallIncStyle = with_benchmark
                    ? getBenchmarkColor(quart0, overallIncVal)
                    : getColor(overallIncVal, pptStyle, false, true);
                const overallGapValueStyle = getGapColor(overallGapValue, gapStyle, true) + 12;
                const overallIncGapDelta = deltaToDisplay(
                    overallGapValue,
                    resurveyOverall[0].displayValue,
                    overallGapValue,
                    isPulse
                );
                const overallIncGapDeltaStyle =
                    getDeltaColor({
                        delta: overallIncGapDelta,
                        type: 'gap',
                    }) + 15;
                const { displayValue: resurveyOverallScores = '', quartile: resurveyOverallQuart = -1 } =
                    resurveyOverallData[kIndex] || {};
                const overallIncDelta = deltaToDisplay(overallIncVal, resurveyOverallScores, score, isPulse);
                const resOvIncQuartile = quartileDelta(-1, quart0, -1, resurveyOverallQuart);
                const overallIncDeltaStyle =
                    getDeltaColor({
                        delta: overallIncDelta,
                        quartMovement: isQuartileMovement ? resOvIncQuartile : '',
                    }) + 15;
                const allGapOverallDelta = deltaToDisplay(allGapOverall, resurveyAllGap[1], allGapOverall, isPulse);
                const allGapOverallDeltaStyle =
                    getDeltaColor({
                        delta: allGapOverallDelta,
                        type: 'gap',
                    }) + 15;
                const highLowColor = checkHighLowOverall(overallIncVal, overallArr, kIndex);
                if (kIndex < chunkQbyq.length) {
                    outcomeTable.push(
                        getRowDataText(display_name, 7, 0, kIndex * 4 + 14),
                        getRowData(overallIncVal, overallIncStyle, 4, kIndex * 4 + 14, highLowColor),
                        getRowData(overallIncDelta, overallIncDeltaStyle, 4, kIndex * 4 + 16),
                        getRowDataText(percent, 7, 2, kIndex * 4 + 14),
                        getRowData(size, 7, 1, kIndex * 4 + 14),
                        getRowData(prevSize, 7, 1, kIndex * 4 + 16)
                    );
                    enterpriseTable.push(
                        getRowDataText(display_name, 7, 0, kIndex * 4 + 13),
                        getRowData(overallIncVal, overallIncStyle, 4, kIndex * 4 + 13, highLowColor),
                        getRowData(overallIncDelta, overallIncDeltaStyle, 4, kIndex * 4 + 15),
                        getRowData(size, 7, 1, kIndex * 4 + 13),
                        getRowData(prevSize, 7, 1, kIndex * 4 + 15),
                        getRowDataText(percent, 7, 2, kIndex * 4 + 13)
                    );
                    individualTable.push(
                        getRowDataText(display_name, 7, 0, kIndex * 4 + 13),
                        getRowData(overallIncVal, overallIncStyle, 4, kIndex * 4 + 13, highLowColor),
                        getRowData(overallIncDelta, overallIncDeltaStyle, 4, kIndex * 4 + 15),
                        getRowData(size, 7, 1, kIndex * 4 + 13),
                        getRowData(prevSize, 7, 1, kIndex * 4 + 15),
                        getRowDataText(percent, 7, 2, kIndex * 4 + 13)
                    );
                }
                if (!kIndex) {
                    outcomeTable.push(
                        getRowData(overallIncDelta, overallIncDeltaStyle, 4, 8),
                        getRowData(allGapOverall, allGapOverallStyle, 4, 6),
                        getRowData(allGapOverallDelta, allGapOverallDeltaStyle, 4, 8),
                        getRowData(overallGapValue, overallGapValueStyle, 4, 10),
                        getRowData(overallIncGapDelta, overallIncGapDeltaStyle, 4, 12),
                        getRowData(mainSize, 7, 1, 2),
                        getRowData(demoPrevNsize, 7, 1, 4)
                    );
                    enterpriseTable.push(
                        getRowData(mainSize, 7, 1, 1),
                        getRowData(demoPrevNsize, 7, 1, 3),
                        getRowData(overallIncDelta, overallIncDeltaStyle, 4, 7),
                        getRowData(allGapOverall, allGapOverallStyle, 4, 5),
                        getRowData(allGapOverallDelta, allGapOverallDeltaStyle, 4, 7),
                        getRowData(overallGapValue, overallGapValueStyle, 4, 9),
                        getRowData(overallIncGapDelta, overallIncGapDeltaStyle, 4, 11)
                    );
                    individualTable.push(
                        getRowData(overallIncDelta, overallIncDeltaStyle, 4, 7),
                        getRowData(allGapOverall, allGapOverallStyle, 4, 5),
                        getRowData(allGapOverallDelta, allGapOverallDeltaStyle, 4, 7),
                        getRowData(overallGapValue, overallGapValueStyle, 4, 9),
                        getRowData(overallIncGapDelta, overallIncGapDeltaStyle, 4, 11),
                        getRowData(mainSize, 7, 1, 1),
                        getRowData(demoPrevNsize, 7, 1, 3)
                    );
                }
            });

            for (let k = chunkQbyq.length; k <= heatMapChunk; k += 1) {
                outcomeTable.push(
                    getRowDataText(' ', 7, 0, k * 4 + 14),
                    getRowDataText(' ', 7, 1, k * 4 + 14),
                    getRowDataText(' ', 7, 2, k * 4 + 14),
                    getRowDataText(' ', 6, 4, k * 4 + 14),
                    getRowDataText(' ', 18, 4, k * 4 + 16),
                    getRowDataText(' ', 7, 2, k * 4 + 16)
                );
                enterpriseTable.push(
                    getRowDataText(' ', 7, 0, k * 4 + 13),
                    getRowDataText(' ', 7, 1, k * 4 + 13),
                    getRowDataText(' ', 7, 2, k * 4 + 13),
                    getRowDataText(' ', 6, 4, k * 4 + 13),
                    getRowDataText(' ', 6, 4, k * 4 + 15),
                    getRowDataText(' ', 7, 2, k * 4 + 15)
                );
                individualTable.push(
                    getRowDataText(' ', 7, 0, k * 4 + 13),
                    getRowDataText(' ', 7, 1, k * 4 + 13),
                    getRowDataText(' ', 7, 2, k * 4 + 13),
                    getRowDataText(' ', 6, 4, k * 4 + 13),
                    getRowDataText(' ', 7, 4, k * 4 + 15),
                    getRowDataText(' ', 7, 2, k * 4 + 15)
                );
            }
            for (let j = 0; j < heatMapChunk; j += 1) {
                const scoreArray = dataChunk[j] || Array(27).fill('a');
                const resArray = resDataChunk[j] || Array(27).fill('a');
                const mainArray = overallView ? scoreArray.slice(4, 10) : scoreArray.slice(2, 10);
                const practiceArr1 = overallView
                    ? scoreArray.slice(10, 17)
                    : [...scoreArray.slice(2, 4), ...scoreArray.slice(10, 17)];
                const practiceArr2 = overallView
                    ? scoreArray.slice(17)
                    : [...scoreArray.slice(2, 4), ...scoreArray.slice(17)];
                let row_index = 1;
                const column_index = j * 4;
                const ovIncDisVal = ovScore;
                const ovIncIndex = with_benchmark
                    ? getBenchmarkColor(ovQuartile, ovIncDisVal)
                    : getColor(ovIncDisVal, pptStyle, false, true);
                const { displayValue: resOverallScore = '', quartile: resOverallQuartile = -1 } =
                    resurveyOverall[1] || {};
                const overallScoreDelta = deltaToDisplay(ovScore, resOverallScore, ovScore, isPulse);
                const overallScoreQuartDel = quartileDelta(-1, ovQuartile, -1, resOverallQuartile);
                const overallScoreDeltaStyle =
                    getDeltaColor({
                        delta: overallScoreDelta,
                        quartMovement: isQuartileMovement ? overallScoreQuartDel : '',
                    }) + 15;

                mainArray.forEach((innerScore, index) => {
                    const { displayValue = '', quartile = -1 } = innerScore;
                    const scoreDisVal = displayValue;
                    const qbyqAllGap = overallView ? qbyqAllGapArr.slice(4, 10) : qbyqAllGapArr.slice(2, 10);
                    const gapDisVal = qbyqAllGap[index];
                    const overallScoreArr = overallView ? scoreForGap.slice(2, 8) : scoreForGap.slice(0, 8);
                    const { score: overallDisVal = '', quartile: quart = -1 } = overallScoreArr[index] || {};
                    const overallIndex = with_benchmark
                        ? getBenchmarkColor(quart, overallDisVal)
                        : getColor(overallDisVal, pptStyle, false, true);
                    const overallGapArrOutcome = overallView
                        ? overallGapArr.slice(0, 6)
                        : [...overallGapArr.slice(23, 25), ...overallGapArr.slice(0, 6)];
                    const ovGapVal = overallGapArrOutcome[index];
                    const { gap: overallGapDisVal = '' } = ovGapVal || {};
                    const styleIndex = with_benchmark
                        ? getBenchmarkColor(quartile, scoreDisVal)
                        : getColor(scoreDisVal, pptStyle, false, true);
                    const highLowValue = checkHighLow(index, j, 2, 8, i);
                    const resArr = overallView
                        ? resArray.slice(4, 10)
                        : [...resArray.slice(2, 4), ...resArray.slice(4, 10)];
                    const resGapArr = overallView
                        ? resurveyGapArr.slice(4, 10)
                        : [...resurveyGapArr.slice(2, 4), ...resurveyGapArr.slice(4, 10)];
                    const resOverallScoreArr = overallView ? resScoreForGap.slice(2, 8) : resScoreForGap.slice(0, 8);
                    const resurveyAllGapArr = overallView
                        ? resurveyAllGap.slice(2, 8)
                        : [...resurveyAllGap[0], ...resurveyAllGap.slice(2, 8)];
                    const { displayValue: resDisplayValue = '', quartile: resQuartile = -1 } = resArr[index] || {};
                    const { score: resOvScore = '', quartile: resOverallQuart = -1 } = resOverallScoreArr[index] || {};
                    const resAllGapScore = resurveyAllGapArr[index] || {};
                    const deltaVal = deltaToDisplay(scoreDisVal, resDisplayValue, displayValue, isPulse);
                    const quartileDel = quartileDelta(-1, quartile, -1, resQuartile);
                    const gapDeltaVal = deltaToDisplay(gapDisVal, resGapArr[index], gapDisVal, isPulse);
                    const resStyleIndex =
                        getDeltaColor({
                            delta: deltaVal,
                            quartMovement: isQuartileMovement ? quartileDel : '',
                        }) + 15;
                    outcomeTable.push(
                        getRowData(scoreDisVal, styleIndex, row_index + 5 + lvRow, column_index + 14, highLowValue)
                    );
                    outcomeTable.push(getRowData(deltaVal, resStyleIndex, row_index + 5 + lvRow, column_index + 16));
                    const overallDeltaVal = deltaToDisplay(overallDisVal, resOvScore, overallDisVal, isPulse);
                    const overallQuartDelta = quartileDelta(-1, quart, -1, resOverallQuart);
                    const overallDeltaIndex =
                        getDeltaColor({
                            delta: overallDeltaVal,
                            quartMovement: isQuartileMovement ? overallQuartDelta : '',
                        }) + 15;
                    const allGapDelta = deltaToDisplay(overallGapDisVal, resAllGapScore, overallGapDisVal, isPulse);
                    if (column_index === 8) {
                        if (row_index === 1) {
                            if (overallView) {
                                outcomeTable.push(getRowData(ovIncDisVal, ovIncIndex, row_index + 3, column_index - 6));
                                outcomeTable.push(getRowData(overallScoreDelta, overallScoreDeltaStyle, 4, 4));
                            }
                            commonEle.push({ name: 'inclusion_score', value: ovIncDisVal, style: ovIncIndex });
                        }

                        outcomeTable.push(
                            getRowData(overallDisVal, overallIndex, row_index + 5 + lvRow, column_index - 6),
                            getRowData(overallDeltaVal, overallDeltaIndex, row_index + 5 + lvRow, column_index - 4),
                            getRowData(
                                gapDisVal,
                                getGapColor(gapDisVal, gapStyle, true) + 12,
                                row_index + 5 + lvRow,
                                column_index + 2
                            ),
                            getRowData(
                                gapDeltaVal,
                                getDeltaColor({
                                    delta: gapDeltaVal,
                                    type: 'gap',
                                }) + 15,
                                row_index + 5 + lvRow,
                                column_index + 4
                            ),
                            getRowData(
                                overallGapDisVal,
                                getGapColor(overallGapDisVal, gapStyle, true) + 12,
                                row_index + 5 + lvRow,
                                column_index - 2
                            ),
                            getRowData(
                                allGapDelta,
                                getDeltaColor({
                                    delta: allGapDelta,
                                    type: 'gap',
                                }) + 15,
                                row_index + 5 + lvRow,
                                column_index
                            )
                        );
                    }

                    row_index += 2;
                });

                practiceArr2.forEach((innerScore, ent_row_index) => {
                    const { displayValue = '', quartile = -1 } = innerScore;
                    const scoreDisVal = displayValue;
                    const qbyqAllGap = overallView
                        ? qbyqAllGapArr.slice(17)
                        : [...qbyqAllGapArr.slice(2, 4), ...qbyqAllGapArr.slice(17)];
                    const overallScoreArr = overallView
                        ? scoreForGap.slice(15)
                        : [...scoreForGap.slice(0, 2), ...scoreForGap.slice(15)];
                    const resOverallScoreArr = overallView
                        ? resScoreForGap.slice(15)
                        : [...resScoreForGap.slice(0, 2), ...resScoreForGap.slice(15)];
                    const gapDisVal = qbyqAllGap[ent_row_index];
                    const { score: overallDisVal = '', quartile: quart = -1 } = overallScoreArr[ent_row_index];
                    const { score: resOvScore = '', quartile: resOverallQuart = -1 } =
                        resOverallScoreArr[ent_row_index] || {};
                    const overallIndex = with_benchmark
                        ? getBenchmarkColor(quart, overallDisVal)
                        : getColor(overallDisVal, pptStyle, false, true);
                    const overallDeltaVal = deltaToDisplay(overallDisVal, resOvScore, overallDisVal, isPulse);
                    const overallQuartDelta = quartileDelta(-1, quart, -1, resOverallQuart);
                    const overallDeltaIndex =
                        getDeltaColor({
                            delta: overallDeltaVal,
                            quartMovement: isQuartileMovement ? overallQuartDelta : '',
                        }) + 15;
                    const overallGapArrPractice = overallView
                        ? overallGapArr.slice(13)
                        : [...overallGapArr.slice(23, 25), ...overallGapArr.slice(13)];
                    const ovGapVal = overallGapArrPractice[ent_row_index];
                    const { gap: overallGapDisVal = '' } = ovGapVal || {};
                    const styleIndex = with_benchmark
                        ? getBenchmarkColor(quartile, scoreDisVal)
                        : getColor(scoreDisVal, pptStyle, false, true);
                    const highLowValue = checkHighLow(ent_row_index, j, 15, '', i);
                    const resArr = overallView ? resArray.slice(17) : [...resArray.slice(2, 4), ...resArray.slice(17)];
                    const resGapArr = overallView
                        ? resurveyGapArr.slice(17)
                        : [...resurveyGapArr.slice(2, 4), ...resurveyGapArr.slice(17)];
                    const resurveyAllGapArr = [...resurveyAllGap[0], ...resurveyAllGap.slice(15)];
                    const resAllGapScore = resurveyAllGapArr[ent_row_index] || {};
                    const { displayValue: resDisplayValue = '', quartile: resQuartile = -1 } =
                        resArr[ent_row_index] || {};
                    const deltaVal = deltaToDisplay(displayValue, resDisplayValue, displayValue, isPulse);
                    const quartileDel = quartileDelta(-1, quartile, -1, resQuartile);
                    const resStyleIndex =
                        getDeltaColor({
                            delta: deltaVal,
                            quartMovement: isQuartileMovement ? quartileDel : '',
                        }) + 15;
                    const gapDeltaVal = deltaToDisplay(gapDisVal, resGapArr[ent_row_index], gapDisVal, isPulse);
                    const allGapDelta = deltaToDisplay(overallGapDisVal, resAllGapScore, overallGapDisVal, isPulse);
                    enterpriseTable.push(
                        getRowData(
                            scoreDisVal,
                            styleIndex,
                            ent_row_index * 2 + 6 + lvRow,
                            column_index + 13,
                            highLowValue
                        )
                    );
                    enterpriseTable.push(
                        getRowData(deltaVal, resStyleIndex, ent_row_index * 2 + 6 + lvRow, column_index + 15)
                    );
                    if (column_index === 8) {
                        if (!ent_row_index && overallView) {
                            enterpriseTable.push(
                                getRowData(ovIncDisVal, ovIncIndex, ent_row_index * 2 + 4 + lvRow, column_index - 7)
                            );
                            enterpriseTable.push(getRowData(overallScoreDelta, overallScoreDeltaStyle, 4 + lvRow, 3));
                        }
                        enterpriseTable.push(
                            getRowData(overallDisVal, overallIndex, ent_row_index * 2 + 6 + lvRow, column_index - 7),
                            getRowData(
                                overallDeltaVal,
                                overallDeltaIndex,
                                ent_row_index * 2 + 6 + lvRow,
                                column_index - 5
                            ),
                            getRowData(
                                gapDisVal,
                                getGapColor(gapDisVal, gapStyle, true) + 12,
                                ent_row_index * 2 + 6 + lvRow,
                                column_index + 1
                            ),
                            getRowData(
                                gapDeltaVal,
                                getDeltaColor({
                                    delta: gapDeltaVal,
                                    type: 'gap',
                                }) + 15,
                                ent_row_index * 2 + 6 + lvRow,
                                column_index + 3
                            ),
                            getRowData(
                                overallGapDisVal,
                                getGapColor(overallGapDisVal, gapStyle, true) + 12,
                                ent_row_index * 2 + 6 + lvRow,
                                column_index - 3
                            ),
                            getRowData(
                                allGapDelta,
                                getDeltaColor({
                                    delta: allGapDelta,
                                    type: 'gap',
                                }) + 15,
                                ent_row_index * 2 + 6 + lvRow,
                                column_index - 1
                            )
                        );
                    }
                });
                practiceArr1.forEach((innerScore, ind_row_index) => {
                    const { displayValue = '', quartile = -1 } = innerScore;
                    const scoreDisVal = displayValue;
                    const qbyqAllGap = overallView
                        ? qbyqAllGapArr.slice(10, 17)
                        : [...qbyqAllGapArr.slice(2, 4), ...qbyqAllGapArr.slice(10, 17)];
                    const resOverallScoreArr = overallView
                        ? resScoreForGap.slice(8, 15)
                        : [...resScoreForGap.slice(0, 2), ...resScoreForGap.slice(8, 15)];
                    const gapDisVal = qbyqAllGap[ind_row_index];
                    const overallScoreArr = overallView
                        ? scoreForGap.slice(8, 15)
                        : [...scoreForGap.slice(0, 2), ...scoreForGap.slice(8, 15)];
                    const { score: overallDisVal = '', quartile: quart = -1 } = overallScoreArr[ind_row_index];
                    const { score: resOvScore = '', quartile: resOverallQuart = -1 } =
                        resOverallScoreArr[ind_row_index] || {};
                    // const overallDisVal = score;
                    const overallIndex = with_benchmark
                        ? getBenchmarkColor(quart, overallDisVal)
                        : getColor(overallDisVal, pptStyle, false, true);
                    const overallGapArrPractice = overallView
                        ? overallGapArr.slice(6, 13)
                        : [...overallGapArr.slice(23, 25), ...overallGapArr.slice(6, 13)];
                    const ovGapVal = overallGapArrPractice[ind_row_index];
                    const { gap: overallGapDisVal = '' } = ovGapVal || {};
                    const styleIndex = with_benchmark
                        ? getBenchmarkColor(quartile)
                        : getColor(scoreDisVal, pptStyle, false, true);
                    const highLowValue = checkHighLow(ind_row_index, j, 8, 15, i);
                    const resArr = overallView
                        ? resArray.slice(10, 17)
                        : [...resArray.slice(2, 4), ...resArray.slice(10, 17)];
                    const resGapArr = overallView
                        ? resurveyGapArr.slice(10, 17)
                        : [...resurveyGapArr.slice(2, 4), ...resurveyGapArr.slice(10, 17)];
                    const resurveyAllGapArr = overallView
                        ? resurveyAllGap.slice(8, 15)
                        : [...resurveyAllGap[0], ...resurveyAllGap.slice(8, 15)];
                    const resAllGapScore = resurveyAllGapArr[ind_row_index] || {};
                    const { displayValue: resDisplayValue = '', quartile: resQuartile = -1 } =
                        resArr[ind_row_index] || {};
                    const deltaVal = deltaToDisplay(displayValue, resDisplayValue, displayValue, isPulse);
                    const quartileDel = quartileDelta(-1, quartile, -1, resQuartile);
                    const gapDeltaVal = deltaToDisplay(gapDisVal, resGapArr[ind_row_index], gapDisVal, isPulse);
                    const allGapDelta = deltaToDisplay(overallGapDisVal, resAllGapScore, overallGapDisVal, isPulse);
                    const resStyleIndex =
                        getDeltaColor({
                            delta: deltaVal,
                            isNaN: displayValue,
                            quartMovement: isQuartileMovement ? quartileDel : '',
                        }) + 15;
                    individualTable.push(
                        getRowData(
                            scoreDisVal,
                            styleIndex,
                            ind_row_index * 2 + 6 + lvRow,
                            column_index + 13,
                            highLowValue
                        )
                    );
                    individualTable.push(
                        getRowData(deltaVal, resStyleIndex, ind_row_index * 2 + 6 + lvRow, column_index + 15)
                    );
                    const overallDeltaVal = deltaToDisplay(overallDisVal, resOvScore, overallDisVal, isPulse);
                    const overallQuartDelta = quartileDelta(-1, quart, -1, resOverallQuart);
                    const overallDeltaIndex =
                        getDeltaColor({
                            delta: overallDeltaVal,
                            quartMovement: isQuartileMovement ? overallQuartDelta : '',
                        }) + 15;
                    if (column_index === 8) {
                        if (!ind_row_index && overallView) {
                            individualTable.push(
                                getRowData(ovIncDisVal, ovIncIndex, ind_row_index * 2 + 4 + lvRow, column_index - 7)
                            );
                            individualTable.push(getRowData(overallScoreDelta, overallScoreDeltaStyle, 4 + lvRow, 3));
                        }
                        individualTable.push(
                            getRowData(overallDisVal, overallIndex, ind_row_index * 2 + 6 + lvRow, column_index - 7),
                            getRowData(
                                overallDeltaVal,
                                overallDeltaIndex,
                                ind_row_index * 2 + 6 + lvRow,
                                column_index - 5
                            ),
                            getRowData(
                                gapDisVal,
                                getGapColor(gapDisVal, gapStyle, true) + 12,
                                ind_row_index * 2 + 6 + lvRow,
                                column_index + 1
                            ),
                            getRowData(
                                gapDeltaVal,
                                getDeltaColor({
                                    delta: gapDeltaVal,
                                    type: 'gap',
                                }) + 15,
                                ind_row_index * 2 + 6 + lvRow,
                                column_index + 3
                            ),
                            getRowData(
                                overallGapDisVal,
                                getGapColor(overallGapDisVal, gapStyle, true) + 12,
                                ind_row_index * 2 + 6 + lvRow,
                                column_index - 3
                            ),
                            getRowData(
                                allGapDelta,
                                getDeltaColor({
                                    delta: allGapDelta,
                                    type: 'gap',
                                }) + 15,
                                ind_row_index * 2 + 6 + lvRow,
                                column_index - 1
                            )
                        );
                    }
                });
            }

            const outcomeSlideArr = [
                ...commonEle,
                {
                    name: 'Title_demo_parent_name',
                    value: `${getDemographicName(demographics, dropDownValue)} ${getDemographicPageCount(
                        demographics,
                        dropDownValue,
                        i,
                        heatMapChunk,
                        heatmapKeys.length
                    )}`,
                },
                {
                    name: 'heatmap_DataTable',
                    table_data: outcomeTable,
                },
            ];

            const enterpriseSlideArr = [
                ...commonEle,
                {
                    name: 'Title_demo_parent_name',
                    value: `${getDemographicName(demographics, dropDownValue)} ${getDemographicPageCount(
                        demographics,
                        dropDownValue,
                        i,
                        heatMapChunk,
                        heatmapKeys.length
                    )}`,
                },
                {
                    name: 'heatmap_DataTable',
                    table_data: enterpriseTable,
                },
            ];

            const individualSlideArr = [
                ...commonEle,
                {
                    name: 'Title_demo_parent_name',
                    value: `${getDemographicName(demographics, dropDownValue)} ${getDemographicPageCount(
                        demographics,
                        dropDownValue,
                        i,
                        heatMapChunk,
                        heatmapKeys.length
                    )}`,
                },
                {
                    name: 'heatmap_DataTable',
                    table_data: individualTable,
                },
            ];

            outcomeSlideArr.push(
                {
                    name: 'chart_title',
                    value: `${ALL_PPT_TEXT[29]} – ${currentOhid} Vs ${previousOhid}`,
                },
                {
                    name: 'slide_title',
                    value: `${getDemographicName(demographics, resurveyDropdownGroup)} ${getDemographicPageCount(
                        demographics,
                        resurveyDropdownGroup,
                        i,
                        heatMapChunk,
                        heatmapKeys.length
                    )}`,
                }
            );
            individualSlideArr.push(
                {
                    name: 'chart_title',
                    value: `${ALL_PPT_TEXT[29]} – ${currentOhid} Vs ${previousOhid}`,
                },
                {
                    name: 'slide_title',
                    value: `${getDemographicName(demographics, resurveyDropdownGroup)} ${getDemographicPageCount(
                        demographics,
                        resurveyDropdownGroup,
                        i,
                        heatMapChunk,
                        heatmapKeys.length
                    )}`,
                }
            );
            enterpriseSlideArr.push(
                {
                    name: 'chart_title',
                    value: `${ALL_PPT_TEXT[29]} – ${currentOhid} Vs ${previousOhid}`,
                },
                {
                    name: 'slide_title',
                    value: `${getDemographicName(demographics, resurveyDropdownGroup)} ${getDemographicPageCount(
                        demographics,
                        resurveyDropdownGroup,
                        i,
                        heatMapChunk,
                        heatmapKeys.length
                    )}`,
                }
            );

            const sigColors = SIGNIFICANCE.map(item => {
                const { bg = '', ...rest } = item;
                return { bgcolor: bg, style_for: 'delta', ...rest };
            });

            outComeSlides.push({
                identify_shape_name: 'checkme',
                identify_shape_value: overallView ? 'heatmap_outcomes' : 'heatmap_outcomes_old',
                is_copy: true,
                is_delete: true,
                styles: isQuartileMovement ? [...pptStyle, ...sigColors] : pptStyle,
                data: outcomeSlideArr,
            });

            enterpriseSlides.push({
                identify_shape_name: 'checkme',
                identify_shape_value: overallView ? 'heatmap_practices2' : 'heatmap_practices2_old',
                is_copy: true,
                is_delete: true,
                styles: isQuartileMovement ? [...pptStyle, ...sigColors] : pptStyle,
                data: enterpriseSlideArr,
            });

            individualSlides.push({
                identify_shape_name: 'checkme',
                identify_shape_value: overallView ? 'heatmap_practices1' : 'heatmap_practices1_old',
                is_copy: true,
                is_delete: true,
                styles: isQuartileMovement ? [...pptStyle, ...sigColors] : pptStyle,
                data: individualSlideArr,
            });
        }

        const filterText = getFiltersText(filters, demographics);
        const filterSlide = getFilterSlideObj(filterText, mainSize);

        const output = {
            ...pptParams,
            slides: [...outComeSlides, ...enterpriseSlides, ...individualSlides, filterSlide],
        };
        getPptData(output, pptAccessToken, 'Inclusion_Demographics');
    };

    useEffect(() => {
        if (resurveyGapDataFetched) {
            const obj = {
                resurveyArr: [],
                resurveyGapArr: [],
                resurveyHeader: [],
                resurveyOverall: [],
                resScoreForGap: [],
                resurveyAllGap: [],
                headerArr,
                finalArr,
                gapArr,
            };
            const { qbyq: resQbyqObj = {}, gap: resurveyGap } = resurveyGapData[previousOhid];
            const { qbyq: currentQbyq = {} } = resurveyGapData[currentOhid] || {};
            const { level_scores: resLevelScore, records: resQbyqRecords = {} } = resHeatmapQbyq || {};
            const { gap: allGap = {}, levels_gap: resAllLevelgap = {} } = resurveyAllGapData || {};
            let index = 0;
            const qbyqArr = Object.keys(qbyq);
            for (let i = 0; i < qbyqArr.length; i += 1) {
                if (resQbyqObj[qbyqArr[i]]) {
                    index = i;
                    break;
                }
            }

            obj.resScoreForGap.push(resLevelScore.individual);
            obj.resScoreForGap.push(resLevelScore.enterprise);
            const { gap: enterpriseGap = '', avg_gap: enterpriseAvgGap = '' } = resAllLevelgap.enterprise || {};
            const { gap: individualGap = '', avg_gap: individualAvgGap = '' } = resAllLevelgap.individual || {};
            const { gap: ovGap = '', avg_gap: overallAvgGap = '' } = resAllLevelgap.overall || {};
            const levels = !showAvgGap ? [individualGap, enterpriseGap] : [individualAvgGap, enterpriseAvgGap];
            obj.resurveyAllGap.push(levels);
            const resFilteredRecords = sortChildren(
                Object.keys(resQbyqRecords)
                    .filter(k => resQbyqRecords[k].level !== '-')
                    .reduce((o, k) => {
                        o[k] = resQbyqRecords[k];
                        return o;
                    }, {})
            );
            Object.keys(allGap).forEach(
                // eslint-disable-next-line no-return-assign
                outcome => {
                    const { level = '', sort_order = '' } = resQbyqRecords[outcome];
                    allGap[outcome].level = level === '-' ? '' : level;
                    allGap[outcome].sort_order = sort_order;
                }
            );
            const resFilteredAllGap = sortChildren(
                Object.keys(allGap)
                    .filter(k => !!allGap[k].level)
                    .reduce((o, k) => {
                        o[k] = allGap[k];
                        return o;
                    }, {})
            );
            obj.resurveyAllGap.push(!showAvgGap ? ovGap : overallAvgGap);
            Object.keys(resFilteredRecords).forEach(val => {
                const {
                    display_score: score = '',
                    key: pKey,
                    quartile = -1,
                    inclusion_quartile = -1,
                } = resFilteredRecords[val];
                const { gap: pGap = '', avg_gap: pAvgGap = '' } = resFilteredAllGap[val] || {};
                obj.resScoreForGap.push({ score, quartile, inclusion_quartile, key: pKey });
                obj.resurveyAllGap.push(!showAvgGap ? pGap : pAvgGap);
            });
            Object.keys(resFilteredRecords).forEach(val => {
                const { children = {} } = resFilteredRecords[val] || {};
                const { children: gapChildren = {} } = resFilteredAllGap[val] || {};
                const sortChildrenObj = sortChildren(children);
                Object.keys(sortChildrenObj).forEach(childVal => {
                    const {
                        display_score: childScore = '',
                        key: cKey,
                        quartile = -1,
                        inclusion_quartile = -1,
                    } = sortChildrenObj[childVal];
                    const { gap: cGap = '', avg_gap: cAvgGap = '' } = gapChildren[cKey] || {};
                    obj.resScoreForGap.push({ score: childScore, quartile, inclusion_quartile, key: cKey });
                    obj.resurveyAllGap.push(!showAvgGap ? cGap : cAvgGap);
                });
            });
            const { levels_gap = {} } = resurveyGap || {};
            const { overall: resOverall = {} } = resLevelScore || {};
            const { individual: indGap = {}, enterprise: entGap = {}, overall: overallGap = {} } = levels_gap || {};
            obj.resurveyGapArr.push('Gaps');
            obj.resurveyGapArr.push('size');
            obj.resurveyGapArr.push(indGap.gap);
            obj.resurveyGapArr.push(entGap.gap);
            obj.resurveyOverall.push({ displayValue: overallGap.gap });
            obj.resurveyOverall.push({ ...resOverall, displayValue: resOverall.display_score || '-' });
            Object.keys(currentQbyq).forEach((key, kIndex) => {
                if (resQbyqObj[key]) {
                    const { display_name = '', size = '', level_scores: levels_score = {}, records = {} } = resQbyqObj[
                        key
                    ];
                    const { enterprise = {}, individual = {}, overall = {} } = levels_score;
                    const { display_score: indScore = '' } = individual;
                    const { display_score: entScore = '' } = enterprise;
                    const newObj = [];
                    const headerArray = [N_SIZE_TEXT, '', ''];
                    newObj.push({ displayValue: display_name });
                    newObj.push({ displayValue: size });
                    newObj.push({ ...individual, displayValue: indScore });
                    newObj.push({ ...enterprise, displayValue: entScore });

                    obj.resurveyOverall.push({ ...overall, displayValue: overall.display_score });

                    const filterWithoutDash = sortChildren(
                        Object.keys(records)
                            .filter(k => records[k].level !== '-')
                            .reduce((o, k) => {
                                o[k] = records[k];
                                return o;
                            }, {})
                    );
                    Object.keys(filterWithoutDash).forEach(val => {
                        const {
                            display_name: record_display_name = '',
                            display_score: score = '',
                            key: pKey,
                            quartile = -1,
                            inclusion_quartile = -1,
                        } = filterWithoutDash[val];
                        if (kIndex === index) {
                            const { gap: pGap = '' } = resGap.records[pKey] || {};
                            obj.resurveyGapArr.push(pGap);
                        }
                        newObj.push({ displayValue: score, quartile, inclusion_quartile });
                        headerArray.push(record_display_name);
                    });
                    Object.keys(filterWithoutDash).forEach(val => {
                        const { children = {}, key: pKey } = filterWithoutDash[val];
                        const sortChildrenObj = sortChildren(children);
                        Object.keys(sortChildrenObj).forEach(childVal => {
                            const {
                                display_score: childScore = '',
                                display_name: sub_display_name = '',
                                key: cKey,
                                quartile = -1,
                                inclusion_quartile = -1,
                            } = sortChildrenObj[childVal];
                            if (kIndex === index) {
                                const { gap: cGap = '' } = resGap.records[pKey].children[cKey] || {};
                                obj.resurveyGapArr.push(cGap);
                            }
                            newObj.push({ displayValue: childScore, quartile, inclusion_quartile });
                            headerArray.push(sub_display_name);
                        });
                    });
                    obj.resurveyArr.push(newObj);
                    obj.resurveyHeader = headerArray;
                } else {
                    obj.resurveyOverall.push({ displayValue: '-' });
                    obj.resurveyArr.push(Array(27).fill({ displayValue: '-' }));
                }
            });
            setResDemo(obj);
        }
        // eslint-disable-next-line
    }, [resurveyGapDataFetched, resurveyAllGapDataFetched, showAvgGap]);

    return (
        <div className="content">
            <div className="upperHeader heatmap">
                <div className="demographicDropdown">
                    <Select
                        data-testid="demographicOptions"
                        IconComponent={KeyboardArrowDownSharpIcon}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={resurveyDropdownGroup}
                        displayEmpty
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                        }}
                        onChange={({ target: { value: code } }) => {
                            if (code) {
                                setResurveyDropDownValue(code);
                                callResurveyGapData(code);
                            }
                        }}
                    >
                        <MenuItem classes={{ root: 'demographicOptions' }} value="">
                            <em>{SELECT_DEMO_GROUP}</em>
                        </MenuItem>
                        {demographics
                            .filter(({ code }) => !excludeOption.includes(code) && resurveyDropdown.includes(code))
                            .map(({ code = '', label: labelName = '' }) => {
                                return (
                                    <MenuItem key={code} classes={{ root: 'demographicOptions' }} value={code}>
                                        {labelName}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                    <Tooltip title={INFO_TEXT}>
                        <div className="infoIcon">i</div>
                    </Tooltip>
                </div>
                <div>
                    <ul>
                        <li className="toggleBtn">
                            <div className="tagName">{SHOW_OVERALL}</div>
                            <div className={c({ overallView })} onClick={() => setOverallView(!overallView)}>
                                <div data-testid="hideGapBtn" className={c({ isActive: !overallView })} />
                                <div data-testid="showGapBtn" className={c({ isActive: overallView })} />
                            </div>
                        </li>
                        <li data-testid="getPptBtn" className="separator" onClick={getDataForPpt}>
                            <div>
                                <img src={DownloadIcon} alt="" />
                            </div>
                            <span>{DOWNLOAD}</span>
                        </li>
                        <li className="leftPadding">
                            <div className="tagName">{VIEW_BY}</div>
                            <div>
                                <div
                                    data-testid="hideGapBtn"
                                    className={c({ isActive: !showAvgGap })}
                                    onClick={() => setAvgGap(false)}
                                >
                                    {LARGEST_GAP}
                                </div>
                                <div
                                    data-testid="showGapBtn"
                                    className={c({ isActive: showAvgGap })}
                                    onClick={() => setAvgGap(true)}
                                >
                                    {AVG_GAP}
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="legendContent tile">
                <div>
                    <div>
                        <ul className="classification">
                            <li>{SCORE_CLASSIFICATION_TEXT} </li>
                            {styleToUse
                                .filter(
                                    ({ quartile, style_for }) =>
                                        quartile !== '0' && quartile !== '999' && style_for !== 'blank_data'
                                )
                                .map(({ title, bgcolor }) => (
                                    <li key={title}>
                                        <span className="circle" style={{ background: bgcolor }} />
                                        {title}
                                    </li>
                                ))}
                        </ul>
                    </div>
                    <div>
                        <ul className="classification">
                            <li>{GAP_CLASSIFICATION_TEXT} </li>
                            {gapStyle.map(({ title, bgcolor }) => (
                                <li key={title}>
                                    <span style={{ background: bgcolor }} />
                                    {title}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div>
                        <ul className="classification clearfix">
                            <li>{isQuartileMovement ? QUARTILE_DIFFERENCE : VALUE_BASED_DIFFERENCE}</li>
                            {deltaStyles.map(({ bgcolor: color, label }, index) => {
                                const labelToUse = !isQuartileMovement ? label : QUARTILE_MOVE_LEGEND_TXT_IA[index];
                                return (
                                    <li data-testid={`change${index}`} key={label}>
                                        <span style={{ background: color }} />
                                        {labelToUse}
                                    </li>
                                );
                            })}
                            <li data-testid="change3">
                                <span style={{ background: '#ffffff', border: '1px solid #000000' }} />
                                No score comparison
                            </li>
                        </ul>
                        <ul className="classification clearfix">
                            <li>{GAP_BASED_DIFFERENCE}</li>
                            {gapDeltaStyles.map(({ text_color: color }, index) => {
                                const changeLabel = GAP_LEGEND_TEXT[index];
                                return (
                                    <li data-testid={`gapchange${index}`} key={changeLabel}>
                                        <span style={{ background: color }} />
                                        {changeLabel}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
            <section className="sectionWrapper">
                <div
                    id="myHeader"
                    className={c('headerWrapper clearfix', {
                        fixedHeader: isSticky,
                        adjustHeader: isSticky,
                    })}
                >
                    {page > 1 ? (
                        <div data-testid="arrowLeft" className="arrow" onClick={() => updatePage(() => page - 1)}>
                            <img className="imgClass" src={ArrowLeft} alt="" />
                        </div>
                    ) : null}
                    <div>
                        {headers && headers.length ? (
                            <Fragment>
                                <ul className="mainHeader">{scoresHeaders(headers, 'header')}</ul>
                                <ul className="nSizeHeader">{scoresHeaders(resHeaderArr, 'nsize', nsizeArr)}</ul>
                                <ul className="resurveyHeader">{scoresHeaders(nsizeArr, 'resHeader', nsizeArr)}</ul>
                            </Fragment>
                        ) : null}
                    </div>
                    {headers.length > page * SLICE_VALUE + 2 ? (
                        <div data-testid="arrowRight" className="arrow arrowRight" onClick={() => updatePage(page + 1)}>
                            <img className="imgClass" src={ArrowRight} alt="" />
                        </div>
                    ) : null}
                </div>
                <div className="contentWrapper clearfix">
                    <div className="mainContent resurveyTable">{allGapArr.length ? renderScoreTable() : null}</div>
                </div>
            </section>
        </div>
    );
};
ResurveyDemographicTable.propTypes = {
    scoreData: PropTypes.object.isRequired,
    demographics: PropTypes.array.isRequired,
    dropDownValue: PropTypes.string.isRequired,
    scoreColor: PropTypes.object.isRequired,
    withBenchmark: PropTypes.bool.isRequired,
    resDemo: PropTypes.object.isRequired,
    year: PropTypes.string.isRequired,
    prevYear: PropTypes.string.isRequired,
    isPulse: PropTypes.bool.isRequired,
    selectedSubTab: PropTypes.number.isRequired,
    showAvgGap: PropTypes.bool.isRequired,
    setAvgGap: PropTypes.func.isRequired,
    resurveyDropdownGroup: PropTypes.string.isRequired,
    setResurveyDropDownValue: PropTypes.func.isRequired,
    resurveyDropdown: PropTypes.array.isRequired,
    getPptData: PropTypes.func.isRequired,
    callResurveyGapData: PropTypes.func.isRequired,
    excludeOption: PropTypes.string.isRequired,
    resurveyGapDataFetched: PropTypes.bool.isRequired,
    resurveyAllGapDataFetched: PropTypes.bool.isRequired,
    resurveyGapData: PropTypes.object.isRequired,
    resurveyAllGapData: PropTypes.object.isRequired,
    previousOhid: PropTypes.string.isRequired,
    currentOhid: PropTypes.string.isRequired,
    demoNSize: PropTypes.object.isRequired,
    setResDemo: PropTypes.func.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    iaApiParams: PropTypes.object.isRequired,
    benchmarkName: PropTypes.string.isRequired,
    benchmarkVersions: PropTypes.array.isRequired,
    inclusionqbyq: PropTypes.object.isRequired,
    allIaGapData: PropTypes.object.isRequired,
    resurveyDemographic: PropTypes.object.isRequired,
    exGap: PropTypes.string.isRequired,
    checkHighLow: PropTypes.func.isRequired,
    overallView: PropTypes.bool.isRequired,
    setOverallView: PropTypes.func.isRequired,
    allGapDataFetched: PropTypes.bool.isRequired,
    isDiffSurveyV: PropTypes.bool.isRequired,
};
export default ResurveyDemographicTable;
